// TODO: Use functions from shared library instead of these

export const extractOrgFromRoomId = (roomId: string | null) => {
  if (roomId?.includes('Room-')) return roomId.split('-')[1];
  return null;
};

export const extractBuildingFloorUnitFromRoomId = (roomId: string | null) => {
  if (roomId?.includes('Room-')) return roomId.split('-').slice(2, 5).join('-');
  return null;
};

export const extractRoomNumberFromRoomId = (roomId: string | null) => {
  if (roomId?.includes('Room-')) return roomId.split('-')[5];
  return null;
};

export const extractFloorNumberFromRoomId = (roomId: string | null) => {
  if (roomId?.includes('Room-')) return roomId.split('-')[3];
  return null;
};

export const extractOrgFromFloorId = (floorId: string) => floorId.split('-')[0];

export const extractBuildingFloorFromFloorId = (floorId: string) =>
  floorId.split('-').slice(1, 3).join('-');

export const makeRoomId = (
  organization: string,
  buildingFloorUnit: string,
  roomNumber: string,
) => {
  if (!organization || !buildingFloorUnit || !roomNumber) return null;
  return `Room-${organization}-${buildingFloorUnit}-${roomNumber}`;
};

export const makeUnit = (organization: string, buildingFloorUnit: string) => {
  if (!organization || !buildingFloorUnit) return null;
  return `Room-${organization}-${buildingFloorUnit}`;
};
