import type { CareLevel } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getCareLevels = async (org: string, building?: string) => {
  const res = await Api.get<CareLevel[]>('/admin/v1/care-levels', {
    params: { org, building },
  });

  return res.data;
};
