import { isObject } from 'lodash';

import {
  isUpstreamError,
  isUpstreamErrorMeta,
} from '@inspiren-monorepo/shared-types';

export const getUpstreamErrorFromQuery = (error: unknown) =>
  isObject(error) &&
  'response' in error &&
  isObject(error.response) &&
  'data' in error.response &&
  isUpstreamError(error.response.data) &&
  isUpstreamErrorMeta(error.response.data.error)
    ? error.response.data.error
    : undefined;
