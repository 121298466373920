import {
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  styled,
} from '@mui/material';

const FallRiskToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: 100,
    height: 36,
  },
}));

export default FallRiskToggleButtonGroup;
