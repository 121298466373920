import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import {
  convertToRelative,
  ZoneUpdate,
} from '@inspiren-monorepo/shared-react/browser';
import type { RegionConfig } from '@inspiren-monorepo/shared-types';

import { getRoomRegions } from '../data-access/getRoomRegions';
import { updateRoomRegions } from '../data-access/updateRoomRegions';

interface ZoneUpdateWrapperProps {
  imagesIsLoading: boolean;
  lastSuccessfulImage?: string;
  roomId: string;
  roomName: string;
  toggleZoneUpdate: () => void;
  width: number;
  height: number;
}

export const ZoneUpdateWrapper = ({
  lastSuccessfulImage,
  imagesIsLoading,
  roomId,
  roomName,
  toggleZoneUpdate,
  width,
  height,
}: ZoneUpdateWrapperProps) => {
  const queryClient = useQueryClient();

  const { mutate, isPending: isSavePending } = useMutation({
    mutationFn: updateRoomRegions,
    onSuccess: () => {
      toast.success(
        'Room Zones Updated. Please wait for the image to refresh.',
      );

      toggleZoneUpdate();
      queryClient.invalidateQueries({ queryKey: ['regionConfig'] });
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const saveRegions = (regionConfigs: RegionConfig[]) => {
    const relativeConfigs = regionConfigs.map((region) => ({
      ...region,
      points: convertToRelative(region.points, width, height),
    }));

    mutate({
      roomId,
      regions: relativeConfigs,
    });
  };

  const regionConfigParams = {
    queryKey: ['regionConfig', roomId],
    queryFn: () => getRoomRegions(roomId),
  };

  return (
    <ZoneUpdate
      direction='column-reverse'
      imagesIsLoading={imagesIsLoading}
      lastSuccessfulImage={lastSuccessfulImage}
      isAdminView
      showControls
      handleCancel={toggleZoneUpdate}
      isSavePending={isSavePending}
      regionConfigParams={regionConfigParams}
      roomName={roomName}
      app='virtual-care'
      saveRegions={saveRegions}
      width={width}
      height={height}
    />
  );
};
