import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import type { MutationFunction } from '@tanstack/react-query';

interface Params {
  onConfirmed: () => void;
  confirmPhoneNumberMutationFn: MutationFunction<void, string>;
}

export const useNotVerifiedStage = ({
  onConfirmed,
  confirmPhoneNumberMutationFn,
}: Params) => {
  const {
    mutate: confirmMobilePhoneNumber,
    isPending,
    isError,
    error: confirmApiError,
  } = useMutation({
    mutationFn: confirmPhoneNumberMutationFn,
    onSuccess: onConfirmed,
  });

  const { handleSubmit, control } = useForm<{ confirmationCode: string }>({
    defaultValues: {
      confirmationCode: '',
    },
  });

  const onConfirmPress = useCallback(
    handleSubmit(({ confirmationCode }) =>
      confirmMobilePhoneNumber(confirmationCode),
    ),
    [handleSubmit, confirmMobilePhoneNumber],
  );

  const onConfirmationCodeChanged = (
    value: string,
    onChange: (value: string) => void,
  ) => {
    if (!value.match(/^\d{0,6}$/)) {
      return;
    }

    onChange(value);

    if (value.length === 6) {
      onConfirmPress();
    }
  };

  return {
    onConfirmationCodeChanged,
    confirmApiError,
    isPending,
    isError,
    control,
    onConfirmPress,
  };
};
