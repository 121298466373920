import { useQuery } from '@tanstack/react-query';

import { getAllUnits } from '../data-access/getAllUnits';

export const useUnits = () => {
  const { data: units, isLoading: unitsIsLoading } = useQuery({
    queryKey: ['getAllUnits'],
    queryFn: () => getAllUnits(),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return { units, unitsIsLoading };
};
