import { includes } from 'lodash';

const ROOM_DESIGNATIONS = ['BEDROOM', 'COMMON_AREA'] as const;

export type RoomDesignation = (typeof ROOM_DESIGNATIONS)[number];

type RoomDesignationOption = {
  value: RoomDesignation;
  label: string;
};

export const ROOM_DESIGNATION_OPTIONS = {
  BEDROOM: {
    value: 'BEDROOM',
    label: 'Bedroom',
  },
  COMMON_AREA: {
    value: 'COMMON_AREA',
    label: 'Common area',
  },
} as const satisfies Record<RoomDesignation, RoomDesignationOption>;

export const isValidRoomDesignation = (
  value: string,
): value is RoomDesignation => includes(ROOM_DESIGNATIONS, value);
