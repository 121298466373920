export const LOG_LEVELS = {
  error: 0,
  warn: 1,
  info: 2,
  http: 3,
  trace: 4,
  debug: 5,
} as const;

export type LogLevel = keyof typeof LOG_LEVELS;
