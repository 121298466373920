import { useCallback, useMemo, useState } from 'react';
import type { GridRowId, GridRowModesModel } from '@mui/x-data-grid';
import { GridRowModes } from '@mui/x-data-grid';
import type { GridRowModesModelProps } from '@mui/x-data-grid/models/api/gridEditingApi';

const useGridRowModes = () => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const updateMode = useCallback(
    (uniqueId: GridRowId, mode: GridRowModesModelProps) => {
      setRowModesModel((old) => ({
        ...old,
        [uniqueId]: mode,
      }));
    },
    [],
  );

  const saveAll = useCallback(() => {
    setRowModesModel((old) =>
      Object.fromEntries(
        Object.entries(old).map(([id, value]) =>
          value.mode === GridRowModes.Edit
            ? [id, { mode: GridRowModes.View }]
            : [id, value],
        ),
      ),
    );
  }, []);

  const canSaveAll = useMemo(
    () =>
      Object.values(rowModesModel).some((e) => e.mode === GridRowModes.Edit),
    [rowModesModel],
  );

  return {
    rowModesModel,
    setRowModesModel,
    updateMode,
    canSaveAll,
    saveAll,
  };
};

export default useGridRowModes;
