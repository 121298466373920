import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { ControlledError } from '@inspiren-monorepo/shared-types';

import { basestationsTableFields } from './constants/basestationsTableFields';
import useBasestationUpsert from './hooks/useBasestationUpsert';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import {
  extractBuildingFloorUnitFromRoomId,
  extractOrgFromRoomId,
  extractRoomNumberFromRoomId,
} from '../../../../utility/helpers/id';
import BulkImportLink from '../../components/BulkImportLink';
import { getOrgs } from '../../data-access/getOrgs';
import { getUnits } from '../../data-access/getUnits';
import TableBase from '../TableBase';

import type { FieldTypes } from './types/BasestationFieldTypes';
import type { OnSubmitFormModal } from '../../modals/FormModalBase';

const BasestationsTable = () => {
  const { isAdmin } = useIsAdmin();

  const { isLoading: orgsLoading, isError: orgsError } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: unitsLoading, isError: unitsError } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const modalLoading = useMemo(
    () => orgsLoading || unitsLoading,
    [orgsLoading, unitsLoading],
  );

  const modalError = useMemo(
    () => orgsError || unitsError,
    [orgsError, unitsError],
  );

  const {
    basestations,
    basestationsError,
    basestationsLoading,
    handleAddSubmit,
    handleEditSubmit,
  } = useBasestationUpsert();

  const onEditSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async (item) => {
      try {
        await handleEditSubmit(item);
        toast.success('Successfully updated basestation');
      } catch (error) {
        const message =
          error instanceof ControlledError
            ? error.message
            : `Error updating basestation${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleEditSubmit],
  );

  const onAddSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async (item) => {
      try {
        await handleAddSubmit(item);
        toast.success('Successfully added new basestation');
      } catch (error) {
        const message =
          error instanceof ControlledError
            ? error.message
            : `Error adding basestation${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleAddSubmit],
  );

  // Empty optional fields will be empty strings for edit form control
  const data = useMemo(
    () =>
      (basestations || []).map(
        ({
          id,
          status = '',
          provisioningStatus = '',
          version = '',
          room = 'unassigned',
          virtual = false,
          demoSession,
        }) => ({
          id,
          organization: extractOrgFromRoomId(room) || '',
          buildingFloorUnit: extractBuildingFloorUnitFromRoomId(room) || '',
          room: extractRoomNumberFromRoomId(room) || '',
          status,
          provisioningStatus,
          version,
          virtual,
          scenario: demoSession?.scenario || '',
        }),
      ),
    [basestations],
  );

  return (
    <TableBase<FieldTypes>
      itemName='Basestation'
      sx={{
        '& .basestations.unassigned': {
          color: (theme) => theme.palette.grey[400],
          fontStyle: 'italic',
        },
      }}
      fields={basestationsTableFields}
      data={data}
      loading={basestationsLoading}
      modalLoading={modalLoading}
      modalError={modalError}
      error={basestationsError}
      onEditSubmit={onEditSubmit}
      onAddSubmit={onAddSubmit}
      extraToolbarButtons={
        isAdmin && <BulkImportLink itemName='basestations' />
      }
      getRowId={(row) => row.id}
    />
  );
};

export default BasestationsTable;
