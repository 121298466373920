import { queryOptions, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { getCustomUnit } from '../data-access/getCustomUnit';

export const getRoomsQueryOptions = (unit: string) =>
  queryOptions({
    queryKey: ['customUnits', unit],
    queryFn: () => getCustomUnit(unit),
    enabled: !isEmpty(unit),
    refetchInterval: 3000,
  });

export const useRooms = (unit: string) => useQuery(getRoomsQueryOptions(unit));
