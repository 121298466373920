import { parseRoomId } from './parseRoomId';

export function getUnitIdWithoutRoomPrefixForRoomId(roomId: string) {
  const { org, building, floor, unit } = parseRoomId(roomId);
  return `${org}-${building}-${floor}-${unit}`;
}

export function getUnitIdForRoomId(roomId: string) {
  const unitId = getUnitIdWithoutRoomPrefixForRoomId(roomId);
  return `Room-${unitId}`;
}
