import { useMemo } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Toolbar } from '@mui/material';
import { isNil, noop } from 'lodash';

import { parseRoomId } from '@inspiren-monorepo/util-rooms';

import { RoomAudibleMessagesModalButton } from './RoomAudibleMessagesModalButton';
import RoomMenu from './RoomCard/RoomMenu';
import { AudibleMessageIndicator } from './components/AudibleMessageIndicator';
import { useUnitForRoom } from './hooks/useUnitForRoom';

import { useOrgSettings } from '../../hooks/useOrgSettings';
import FallRiskPill from '../shared/FallRiskPill';

import type { TeleRoom } from '../../../types';

type Props = {
  room: TeleRoom;
  playingAudibleMessage: boolean;
  onPrivacyModeChange: () => void;
  onToggleZoneUpdate?: () => void;
  disabled?: boolean;
  privacy: boolean;
  offline?: boolean;
  size?: 'small' | 'large';
  sx?: SxProps<Theme>;
  showMenu?: boolean;
  hideAudibleMessageButton?: boolean;
};

const RoomTopBar = ({
  room,
  onPrivacyModeChange,
  onToggleZoneUpdate,
  disabled,
  privacy,
  offline,
  size = 'small',
  sx = {},
  playingAudibleMessage,
  showMenu = false,
  hideAudibleMessageButton = false,
}: Props) => {
  const { name, fallRiskLevel = 'off' } = room;

  const { org } = parseRoomId(room.mainId);
  const { data: orgSettingsData } = useOrgSettings(org);

  const unit = useUnitForRoom(room.mainId);

  const isAudibleMessageButtonHidden = useMemo(
    () =>
      !unit?.audibleMessages ||
      !room?.audible ||
      disabled ||
      privacy ||
      offline ||
      hideAudibleMessageButton,
    [unit, room, disabled, privacy, offline, hideAudibleMessageButton],
  );

  return (
    <Toolbar
      disableGutters
      sx={{
        position: 'absolute',
        zIndex: 100,
        opacity: 0.8,
        '&.MuiToolbar-root': {
          minHeight: 0,
        },
        gap: 1,
        ...sx,
      }}
    >
      <FallRiskPill
        label={`Room ${name}`}
        fallRisk={fallRiskLevel}
        size={size === 'large' ? 'large' : 'medium'}
        bold
      />

      {showMenu && (
        <RoomMenu
          roomId={room.mainId}
          baseId={room.baseId}
          orgId={org}
          roomName={name}
          fallRiskLevel={fallRiskLevel}
          onPrivacyModeChange={onPrivacyModeChange}
          onToggleZoneUpdate={onToggleZoneUpdate || noop}
          offline={Boolean(room.offline)}
          privacy={privacy}
          augiDisabled={room.disable || false}
          unitId={room.unit}
          isDemoSession={room.isDemoSession}
          isDemoAction={room.isDemoAction}
          bathroomSensorEnabled={
            (orgSettingsData?.settings?.bathroomSensorEnabled &&
              !isNil(room.isBathroomOccupied)) ||
            false
          }
          bathroomAlertsEnabled={room.bathroomAlertsEnabled}
          keepAlive={room.keepAlive}
        />
      )}

      {!isAudibleMessageButtonHidden && (
        <RoomAudibleMessagesModalButton
          roomId={room.mainId}
          roomName={room.name}
          baseId={room.baseId}
        />
      )}
      {playingAudibleMessage && <AudibleMessageIndicator size={size} />}
    </Toolbar>
  );
};

export default RoomTopBar;
