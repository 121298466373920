import { useQuery } from '@tanstack/react-query';

import { getOrg } from '../data-access/getOrg';

interface Params {
  orgId?: string;
}

export const useAdminOrg = ({ orgId }: Params) =>
  useQuery({
    queryKey: ['org', orgId],
    queryFn: () => getOrg({ orgId: orgId as string }),
    enabled: orgId !== undefined,
  });
