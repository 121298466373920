import { skipToken, useQuery } from '@tanstack/react-query';

import { getEventNotifications } from '../data-access/getEventNotifs';
import { useEventReviewStore } from '../store/EventReviewStore';

export const useNotifications = () => {
  const { selectedRoom, startDate, endDate } = useEventReviewStore();
  const roomId = selectedRoom?.mainId;

  return useQuery({
    queryKey: ['eventReview', 'notifications', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate
        ? () =>
            getEventNotifications(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
            )
        : skipToken,
  });
};
