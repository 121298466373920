import { useEffect, useState } from 'react';
import { Alert, Box, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  defaultMessage: string;
};

const ErrorScreen = ({ defaultMessage }: Props) => {
  const { logout } = useAuth0();

  const [urlParams, setUrlParams] = useSearchParams();
  const [message, setMessage] = useState(defaultMessage);

  useEffect(() => {
    const error = urlParams.get('error');

    if (error) {
      setMessage(Buffer.from(error, 'base64').toString());
      urlParams.delete('error');
      setUrlParams(urlParams, { replace: true });
    }
  }, [urlParams, setUrlParams]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Alert severity='error' sx={{ mb: 2 }}>
        {message}
      </Alert>
      <Button
        variant='contained'
        color='secondary'
        onClick={() => {
          logout({ returnTo: window.location.origin });
        }}
      >
        Log out
      </Button>
    </Box>
  );
};

export default ErrorScreen;
