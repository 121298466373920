import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getAllUnits = async (): Promise<TelesittingTypes.UnitDto[]> => {
  try {
    const res = await Api.get<TelesittingTypes.UnitDto[]>(
      '/telesitting/v1/units',
    );

    return res && res?.data;
  } catch (error) {
    console.error('error requesting all units');
    throw error;
  }
};
