import { isEmpty, pick } from 'lodash';
import { Button, XStack, YStack } from 'tamagui';
import { useShallow } from 'zustand/react/shallow';

import { Icon } from '../../../components/Icon';
import { Slider } from '../../../components/Slider';
import { useActiveAlertPlaybackStore } from '../hooks/useActiveAlertPlaybackStore';

// TODO: hard-coded styles are temporary. Create a theme when we have more universal components

export const ActiveAlertPlaybackMenu = () => {
  const {
    isActive,
    toggleActive,
    isPlaying,
    togglePlaying,
    progress,
    setProgress,
    images,
    imagesLoading,
  } = useActiveAlertPlaybackStore(
    useShallow((state) =>
      pick(state, [
        'isActive',
        'toggleActive',
        'isPlaying',
        'togglePlaying',
        'progress',
        'setProgress',
        'images',
        'imagesLoading',
      ]),
    ),
  );

  if (!isActive) return;

  const disabled = imagesLoading || isEmpty(images);
  const PlaybackIcon = isPlaying ? Icon.Pause : Icon.Play;

  return (
    <YStack theme='blue' padding='$4' gap='$3'>
      <XStack
        alignItems='center'
        gap='$2'
        $xs={{ flexDirection: 'column-reverse' }}
      >
        <Button
          onPress={togglePlaying}
          disabled={disabled}
          circular
          unstyled
          borderWidth={0}
          cursor='pointer'
          background='transparent'
        >
          <Button.Icon>
            <PlaybackIcon size={36} color={disabled ? '#BDBDBD' : 'black'} />
          </Button.Icon>
        </Button>
        <Slider
          min={0}
          max={images.length - 1}
          step={1}
          value={[progress]}
          onValueChange={([value]) => setProgress(value)}
          disabled={disabled}
          theme='red'
          margin='$4'
          width='100%'
          flex={1}
        />
      </XStack>
      <Button
        onPress={toggleActive}
        maxWidth='$16'
        alignSelf='center'
        backgroundColor='#3A6EC9'
        color='white'
        fontWeight='700'
        borderWidth={0}
        letterSpacing='-0.007rem'
        hoverStyle={{ backgroundColor: '#2A4D8C' }}
        pressStyle={{ backgroundColor: '#2A4D8C' }}
      >
        Return to Live View
      </Button>
    </YStack>
  );
};
