import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  Avatar,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
} from '@mui/material';
import { ContactSupport } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/Logout';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

import { getUsernameFromEmail } from '@inspiren-monorepo/util-users';

import { PhoneNumberVerification } from './PhoneNumberVerification/PhoneNumberVerification';
import { createInitials } from './helpers/createInitials';

import { useCurrentUser } from '../../../HOC/CurrentUserContextProvider';
import { useIsAdmin } from '../../../hooks/useIsAdmin';
import { useOrgSettings } from '../../../hooks/useOrgSettings';
import { sendAmpEvent } from '../../../utility/amplitude';
import { Can } from '../../Can/Can';
import { getDemoState } from '../data-access/getDemoState';
import { startDemoSession } from '../data-access/startDemoSession';
import { stopDemoSession } from '../data-access/stopDemoSession';
import { updateUser } from '../data-access/updateUser';

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { user: auth0User, logout } = useAuth0();
  const { user, setUser } = useCurrentUser();
  const { org, username } = user || {};
  const { isAdmin } = useIsAdmin();

  const loggedInAs =
    username ?? getUsernameFromEmail(auth0User?.email, org) ?? auth0User?.email;

  const queryClient = useQueryClient();
  const location = useLocation();

  const showDemoOptions = useMemo(
    () => isAdmin && location.pathname.startsWith('/rooms'),
    [location.pathname, isAdmin],
  );

  const { data: demoState } = useQuery({
    queryKey: ['demo-state'],
    queryFn: getDemoState,
    enabled: showDemoOptions,
  });

  const initials = useMemo(
    () => createInitials(auth0User),
    [auth0User?.given_name, auth0User?.family_name],
  );

  const muteAlerts = useMemo(() => user?.mute, [user?.mute]);

  const { data: orgSettingsData } = useOrgSettings(org);

  const showSmsVerification = useMemo(
    () => Boolean(orgSettingsData?.settings?.textMessageAlerts),
    [orgSettingsData],
  );

  const startDemoSessionMutation = useMutation({
    mutationFn: startDemoSession,
    onSuccess: () => {
      toast.success('Successfully started demo session.');
      queryClient.invalidateQueries({ queryKey: ['demo-state'] });
    },
    onError: (error) => {
      toast.error(`Error starting demo session${error ? `: ${error}` : ''}`);
    },
  });

  const stopDemoSessionMutation = useMutation({
    mutationFn: stopDemoSession,
    onSuccess: () => {
      toast.success('Successfully stopped demo session.');
      queryClient.invalidateQueries({ queryKey: ['demo-state'] });
    },
    onError: (error) => {
      toast.error(`Error stopping demo session${error ? `: ${error}` : ''}`);
    },
  });

  const handleMuteAlertsToggle = useCallback(async () => {
    if (!user) return;
    setUser({ ...user, mute: !muteAlerts });
    await updateUser(user?.mainId, !muteAlerts);
  }, [user, muteAlerts]);

  return (
    <>
      <Avatar
        component='button'
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(e.currentTarget)
        }
        id='profile-button'
        aria-controls={open ? 'profile-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        sx={{ border: 'none', cursor: 'pointer' }}
      >
        {initials}
      </Avatar>

      <Menu
        id='profile-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'profile-button',
        }}
      >
        {loggedInAs && (
          <MenuItem disabled>
            <ListItemText>Logged in as {loggedInAs}</ListItemText>
          </MenuItem>
        )}

        {loggedInAs && <Divider />}
        <Can
          permission={{
            action: 'view',
            subject: 'global.contact-support',
          }}
        >
          <MenuItem
            onClick={() => {
              window.open(
                'https://inspiren.zendesk.com/hc/en-us/requests/new',
                '_blank',
              );

              sendAmpEvent('Contact Support Clicked');
            }}
          >
            <ListItemIcon>
              <ContactSupport fontSize='small' />
            </ListItemIcon>
            <ListItemText>Contact Support</ListItemText>
          </MenuItem>
        </Can>
        <Can
          permission={{
            action: 'view',
            subject: 'virtual-care.rooms.mute-alerts',
          }}
        >
          <MenuItem>
            <ListItemIcon>
              <Switch
                size='small'
                sx={{ ml: -1.25 }}
                checked={muteAlerts}
                onChange={handleMuteAlertsToggle}
              />
            </ListItemIcon>
            <ListItemText>Mute Alerts</ListItemText>
          </MenuItem>
        </Can>

        {showSmsVerification && <PhoneNumberVerification />}

        {showDemoOptions && !demoState?.started && (
          <MenuItem
            onClick={() => startDemoSessionMutation.mutate()}
            disabled={startDemoSessionMutation.isPending}
          >
            <ListItemIcon>
              {startDemoSessionMutation.isPending && (
                <CircularProgress size={16} />
              )}
              {!startDemoSessionMutation.isPending && (
                <PlayCircleOutlineIcon fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText>Start Demo Session</ListItemText>
          </MenuItem>
        )}

        {showDemoOptions && demoState?.started && (
          <MenuItem
            onClick={() => stopDemoSessionMutation.mutate()}
            disabled={stopDemoSessionMutation.isPending}
          >
            <ListItemIcon>
              {stopDemoSessionMutation.isPending && (
                <CircularProgress size={16} />
              )}
              {!stopDemoSessionMutation.isPending && (
                <PauseCircleOutlineIcon fontSize='small' />
              )}
            </ListItemIcon>
            <ListItemText>Stop Demo Session</ListItemText>
          </MenuItem>
        )}

        <Divider />

        <MenuItem
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
