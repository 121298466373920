import { logger } from '@inspiren-monorepo/util-logger';

type SentryEnv = 'production' | 'stage' | 'this' | 'development';

export const getSentryEnv = (
  stage = process.env.STAGE || process.env.NODE_ENV,
): SentryEnv => {
  let sentryEnv: SentryEnv;

  if (stage === 'prod' || stage === 'production') {
    sentryEnv = 'production';
  } else if (stage === 'stage') {
    sentryEnv = 'stage';
  } else if (stage === 'this') {
    sentryEnv = 'this';
  } else {
    sentryEnv = 'development';
  }

  logger.info('[SENTRY] Sentry environment:', sentryEnv);

  return sentryEnv;
};
