import { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import type { TriggerAction } from '@inspiren-monorepo/virtual-care/api-contracts';

import { triggerVirtualBsAction } from '../data-access/triggerVirtualBsAction';

type Props = {
  baseId: string;
  action: TriggerAction;
  isRunning?: boolean;
};

export const TriggerVirtualBsActionButton = ({
  baseId,
  action,
  isRunning,
}: Props) => {
  const triggerMutation = useMutation({
    mutationFn: () => triggerVirtualBsAction(baseId, action.id),
    onSuccess: () => {
      toast.success('Action triggered successfully.');
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const handleTriggerAction = useCallback(async () => {
    triggerMutation.mutate();
  }, [triggerMutation]);

  return (
    <LoadingButton
      loading={triggerMutation.isPending || isRunning}
      size='medium'
      variant='contained'
      onClick={handleTriggerAction}
      disabled={isRunning}
      fullWidth
    >
      {`Run ${action.name} - [${action.id}]`}
    </LoadingButton>
  );
};
