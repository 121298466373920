import { useEffect } from 'react';

export const useBeforeUnload = (isDirty: boolean) => {
  useEffect(() => {
    const onBeforeUnload = (event: Event) => {
      if (isDirty) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isDirty]);
};
