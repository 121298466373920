import { useTracking } from 'react-tracking';

import { ingestUserEvents } from './data-access/ingestUserEvents';

import type { VirtualCareUserEvent } from '../../../types';

export const useTrackUserEvent = () =>
  useTracking<VirtualCareUserEvent>(
    {
      time: new Date().toISOString(),
      app: 'virtual-care',
    },
    {
      dispatch: (event) => ingestUserEvents([event]),
    },
  );
