import {
  publishToTopic,
  unregisterMessageHandler,
  unsubscribeToTopic,
} from './mqttClient';
import {
  generateAudibleMessagesStatusTopic,
  generateClearImageRequestTopic,
} from './topicHelpers';

const CLEAR_IMAGE_REQUEST_CONFIG = JSON.stringify({
  blur: false,
  regions: true,
  objects: false,
  skeletons: true,
  count: 100,
  delay: 3,
});

export const requestClearImagesFromBasestation = async (
  org: string,
  baseId: string,
) => {
  try {
    const topic = generateClearImageRequestTopic(org, baseId);
    await publishToTopic(topic, CLEAR_IMAGE_REQUEST_CONFIG);
  } catch (error) {
    console.error('Error requesting images from basestation', error);
  }
};

export const unsubscribeToAudibleMessages = async (org: string) => {
  try {
    const topic = generateAudibleMessagesStatusTopic(org);
    unregisterMessageHandler(topic, `audible-messages-status-${org}`);
    await unsubscribeToTopic(topic);
  } catch (error) {
    console.error('Error while unsubscribing to audible messages', error);
  }
};
