import { Api } from '../../../utility/client';

type TokenResp = {
  token: string;
};

export async function getTableauToken() {
  const res = await Api.get<TokenResp>('/telesitting/v1/tableau/token');
  return res.data;
}
