const env =
  import.meta.env.VITE_API_ENV === 'production'
    ? 'prod'
    : import.meta.env.VITE_API_ENV;

export const parseTopic = (topic: string) => {
  const parts = topic.split('/');
  const org = parts[1];
  const baseId = parts[3];
  const type = parts[4];

  return { type, baseId, org };
};

export const generateClearImageRequestTopic = (org: string, baseId: string) =>
  `${env}/${org}/base_stations/${baseId}/request/telesitting`;

export const generateDisableTopic = (org: string) =>
  `${env}/${org}/base_stations/+/disable`;

export const generateAudibleMessagesStatusTopic = (
  org: string,
  baseId?: string,
) => `${env}/${org}/base_stations/${baseId || '+'}/sound_resp`;

export const generateTopic = (type: string, org: string) => {
  if (type === 'disable') return generateDisableTopic(org);
  if (type === 'sound_resp') return generateAudibleMessagesStatusTopic(org);
  return null;
};
