import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getOrg = async ({
  orgId,
}: {
  orgId: AdminTypes.OrganizationDto['id'];
}) => {
  const res = await Api.get<AdminTypes.OrganizationDto>(
    `/admin/v1/orgs/${orgId}`,
  );

  return res.data;
};
