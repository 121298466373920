import { IconButton } from '@mui/material';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

interface Props {
  size?: 'small' | 'large';
}

const animation = '1s ease-out infinite alternate audibleMessage';
const keyframesTitle = '@keyframes audibleMessage';

const keyframes = {
  animation,
  [keyframesTitle]: {
    from: { opacity: 1 },
    to: { opacity: 0.6 },
  },
};

export const AudibleMessageIndicator = ({ size }: Props) => (
  <IconButton
    sx={{
      backgroundColor: 'grey.800',
      color: '#fff',
      height: size === 'large' ? 36 : 32,
      width: size === 'large' ? 36 : 32,
      ':hover': {
        backgroundColor: 'grey.900',
      },
      ...keyframes,
    }}
  >
    <AudiotrackIcon fontSize={size === 'large' ? 'medium' : 'small'} />
  </IconButton>
);
