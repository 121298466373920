import { isEmpty } from 'lodash';

import type { Beacon, Room } from '@inspiren-monorepo/shared-types';

export const getStaffInRoomStatus = (
  room: Pick<Room, 'count' | 'disable' | 'offline' | 'staff'>,
  beaconAssignments: Pick<Beacon, 'id' | 'firstName'>[],
): string | null => {
  const { count = 0, disable = false, offline = false, staff = [] } = room;

  if (offline || disable) return null;

  // The first names of staff members in the room
  const staffNames = staff
    .map((s) => {
      const user = beaconAssignments.find((b) => b.id === s);
      if (!user) return null;
      return user.firstName;
    })
    .filter((n): n is string => Boolean(n));

  // The array of staff names formatted to a single string
  const staffString = !isEmpty(staffNames)
    ? staffNames.reduce((a, n, i) => {
        if (staffNames.length === 1) return `${a}${n} is in room`;
        if (i === staffNames.length - 1) return `${a}and ${n} in room`;
        return `${a}${n} `;
      }, '')
    : null;

  if (staffString) return staffString;

  // if staff is not empty but no names are found just return 'Staff in room'
  if (staff.length > 0) return 'Staff in room';
  // if count is greater than 1 and staff is empty return 'Guest in room'
  if (count > 1) return 'Guest in room';
  return null;
};
