import { Api } from '../../../../../utility/client';

import type { FallRiskLevel, Room } from '../../../../../../types';

export const updateFallRisk = async ({
  roomId,
  fallRiskLevel,
}: {
  roomId: string;
  fallRiskLevel: FallRiskLevel;
}) =>
  Api.put<never, never, { newRoom: Partial<Room> }>(`/v1/rooms/pub/${roomId}`, {
    newRoom: { fallRiskLevel, fallRiskLastSet: 'VIRTUAL-CARE' },
  });
