import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const postUnit = async (unit: AdminTypes.UnitCreateDto) => {
  try {
    const res = await Api.post<AdminTypes.UnitDto>('/admin/v1/units', unit);
    return res?.data;
  } catch (error) {
    console.error('error adding unit');
    throw error;
  }
};
