import { sortBy } from 'lodash';

type Unit = {
  name: string;
  buildingDisplayName?: string;
  displayName?: string;
};

export function sortUnitDropdown<T extends Unit>(units: T[] | undefined): T[] {
  if (!units) {
    return [];
  }

  return sortBy(units, ['buildingDisplayName', 'displayName', 'name']);
}
