import type { SxProps, Theme } from '@mui/material';
import { Stack, Toolbar, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';

import { ActiveAlertPlaybackButton } from '@inspiren-monorepo/shared-react/universal-components';
import type { AlertType } from '@inspiren-monorepo/shared-types';
import { FALL_RISK_OPTIONS } from '@inspiren-monorepo/shared-types';
import {
  getStaffInRoomStatus,
  parseRoomId,
} from '@inspiren-monorepo/util-rooms';

import SendAlertMenu from './components/SendAlertMenu';
import StatusBox from './components/StatusBox';
import { useBeaconAssignments } from './hooks/useBeaconAssignments';

import { useOrgSettings } from '../../../../hooks/useOrgSettings';
import { sendAmpEvent } from '../../../../utility/amplitude';
import FallRiskPill from '../../../shared/FallRiskPill';

import type { TeleRoom } from '../../../../../types';

interface Props {
  room: TeleRoom;
  onMenuClose?: () => void;
  resolve?: () => void;
  resolveNotifLoading: boolean;
  setTooltip: (state: boolean) => void;
  size?: 'small' | 'large';
  sx?: SxProps<Theme>;
  alert: AlertType | null;
  offline?: boolean;
  showFallRisk?: boolean;
  disabled?: boolean;
  privacy?: boolean;
  fallRiskPillTestID?: string;
  showActiveAlertPlayback?: boolean;
  activeAlertPlaybackActive?: boolean;
  timestamp?: Date;
  showTimestamp?: boolean;
}

const RoomBottomBar = ({
  room,
  setTooltip,
  onMenuClose,
  resolve,
  size = 'small',
  sx = {},
  alert,
  offline,
  showFallRisk = true,
  resolveNotifLoading,
  disabled,
  privacy,
  fallRiskPillTestID,
  showActiveAlertPlayback = false,
  activeAlertPlaybackActive = false,
  timestamp,
  showTimestamp = false,
}: Props) => {
  const { fallRiskLevel = 'off' } = room;
  const { org } = parseRoomId(room.mainId);

  const { data: beaconAssignments = [] } = useBeaconAssignments({ org });
  const { data: orgSettingsData } = useOrgSettings(org);

  const staffInRoomStatus = getStaffInRoomStatus(room, beaconAssignments);

  return (
    <Toolbar
      disableGutters
      sx={{
        ...sx,
        position: 'absolute',
        zIndex: 100,
        bottom: 0,
        width: '100%',
        flexWrap: 'wrap-reverse',
        rowGap: 1,
        opacity: 0.8,
        '&.MuiToolbar-root': {
          minHeight: 0,
        },
      }}
    >
      <Stack direction='row' flex='1' spacing={1} alignItems='flex-end'>
        <Stack
          direction='row'
          flex='1'
          spacing={1}
          alignItems='flex-end'
          mr={2}
        >
          <Stack direction='column' spacing={1.5}>
            {showTimestamp && timestamp && (
              <Typography color='white'>
                {format(timestamp, 'h:mm:ss aa')}
              </Typography>
            )}
            {showFallRisk && (
              <FallRiskPill
                label={FALL_RISK_OPTIONS[fallRiskLevel].description}
                fallRisk={fallRiskLevel}
                size='small'
                testID={fallRiskPillTestID}
              />
            )}
            {!offline && staffInRoomStatus && (
              <StatusBox status={staffInRoomStatus} size={size} />
            )}
          </Stack>

          {!offline &&
            orgSettingsData?.settings?.bathroomSensorEnabled &&
            room.isBathroomOccupied && (
              <StatusBox status='Bathroom Occupied' size={size} />
            )}
        </Stack>

        {showActiveAlertPlayback &&
          alert &&
          !offline &&
          !disabled &&
          !privacy && <ActiveAlertPlaybackButton />}

        {!offline && !alert && !disabled && (
          <SendAlertMenu
            roomId={room.mainId}
            size={size}
            onMenuClose={onMenuClose}
          />
        )}

        {alert && !activeAlertPlaybackActive && (
          <LoadingButton
            size={size === 'large' ? 'medium' : 'small'}
            variant='contained'
            color='secondary'
            onClick={() => {
              resolve?.();
              sendAmpEvent('Resolved Notif', { room: room.mainId, alert });
            }}
            onMouseOver={() => setTooltip(false)}
            onMouseLeave={() => setTooltip(true)}
            loading={resolveNotifLoading}
          >
            Resolve
          </LoadingButton>
        )}
      </Stack>
    </Toolbar>
  );
};

export default RoomBottomBar;
