import { useMemo } from 'react';

import { useLastOfflineEventForRoom } from './useLastOfflineEventForRoom';

import { generateLastImagesEventReviewUrl } from '../helpers/generateLastImagesEventReviewUrl';

export const useLastImagesEventReviewUrl = (
  roomId: string,
  roomName: string,
  offline: boolean,
  keepAlive?: string,
) => {
  const { data: lastOfflineEvent, isLoading: isLoadingLastOfflineEvent } =
    useLastOfflineEventForRoom(roomId);

  const url = useMemo(
    () =>
      generateLastImagesEventReviewUrl({
        roomId,
        roomName,
        offline,
        lastKeepAliveTime: keepAlive ? new Date(keepAlive) : undefined,
        lastOfflineEventTime: lastOfflineEvent?.start
          ? new Date(lastOfflineEvent.start)
          : undefined,
      }),
    [roomId, roomName, offline, keepAlive, lastOfflineEvent],
  );

  return {
    eventReviewUrl: url,
    isLoadingEventReviewUrl: isLoadingLastOfflineEvent,
  };
};
