import { useCallback } from 'react';
import { Stack, Switch, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useConfirm } from 'material-ui-confirm';

import {
  extractOrgFromRoom,
  getUnitIdForRoomId,
} from '@inspiren-monorepo/util-rooms';

import { useOrgSettings } from '../../../../../hooks/useOrgSettings';
import { getRoomsQueryOptions } from '../../../hooks/useRooms';
import { useUnitForRoom } from '../../../hooks/useUnitForRoom';
import { toggleBathroomAlerts } from '../data-access/toggleBathroomAlerts';

interface Props {
  roomId: string;
  bathroomAlertsEnabled: boolean;
}

export const BathroomActivitySwitch = ({
  bathroomAlertsEnabled,
  roomId,
}: Props) => {
  const confirm = useConfirm();
  const orgId = extractOrgFromRoom(roomId);
  const unit = useUnitForRoom(roomId);
  const orgSettings = useOrgSettings(orgId);
  const queryClient = useQueryClient();
  const unitId = getUnitIdForRoomId(roomId);
  const { queryKey } = getRoomsQueryOptions(unitId);

  const bathroomToggleMutation = useMutation<void, unknown, boolean>({
    mutationFn: async (enabled) => toggleBathroomAlerts(roomId, enabled),
    onMutate: async (enabled) => {
      await queryClient.cancelQueries({ queryKey });

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, (oldUnit) => {
        if (!oldUnit) return;

        return {
          ...oldUnit,
          rooms: oldUnit.rooms.map((room) =>
            room.mainId === roomId
              ? { ...room, bathroomAlertsEnabled: enabled }
              : room,
          ),
        };
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const handleBathRoomToggleMutation = useCallback(
    (checked: boolean) => {
      confirm({
        title: 'Are you sure?',
        description: !bathroomAlertsEnabled
          ? `Are you sure bathroom alerts should be sent if the ${orgSettings?.data?.settings?.ptype?.toLowerCase() || 'resident'} is in the bathroom for longer than a ${unit?.bathroomAlertThreshold} minutes period?`
          : 'Are you sure you would like to turn off bathroom alerts?',
        confirmationText: 'Confirm',
        confirmationButtonProps: {
          color: 'primary',
        },
      }).then(() => {
        bathroomToggleMutation.mutate(checked);
      });
    },
    [
      bathroomAlertsEnabled,
      unit?.bathroomAlertThreshold,
      orgSettings?.data?.settings?.ptype,
    ],
  );

  return (
    <Stack direction='row' alignItems='center'>
      <Switch
        checked={bathroomAlertsEnabled}
        onChange={(_event, checked) => handleBathRoomToggleMutation(checked)}
      />
      <Typography variant='subtitle2'>Bathroom Activity</Typography>
    </Stack>
  );
};
