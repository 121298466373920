import { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller } from 'react-hook-form';

import type { Control } from 'react-hook-form';

interface Props {
  id: string;
  label: string;
  control: Control;
}

const ControlledPasswordInput = ({ id, label, control }: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => (
        <FormControl variant='outlined' margin='dense' fullWidth>
          <InputLabel htmlFor={name} error={Boolean(error)}>
            {label}
          </InputLabel>
          <OutlinedInput
            id={name}
            margin='dense'
            fullWidth
            value={value}
            onChange={onChange}
            error={Boolean(error)}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge='end'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
          />
          <FormHelperText error={Boolean(error)}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default ControlledPasswordInput;
