import { useMemo } from 'react';

import { ROOM_DESIGNATION_OPTIONS } from '@inspiren-monorepo/shared-types';

import ControlledDropdown from '../ControlledDropdown';

import type { Control } from 'react-hook-form';

type Props = {
  control: Control;
};

const SelectRoomDesignation = ({ control }: Props) => {
  const options = useMemo(() => Object.values(ROOM_DESIGNATION_OPTIONS), []);

  return (
    <ControlledDropdown
      id='designation'
      label='Designation'
      items={options}
      control={control}
    />
  );
};

export default SelectRoomDesignation;
