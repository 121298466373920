import { Slider as TamaguiSlider, type SliderProps } from 'tamagui';

// TODO: hard-coded styles are temporary. Create a theme when we have more universal components

export const Slider = ({
  disabled,
  ...props
}: Omit<SliderProps, 'children'>) => (
  <TamaguiSlider {...props} disabled={disabled}>
    <TamaguiSlider.Track
      size='$2'
      backgroundColor='#FEECEC'
      disabled={disabled}
      disabledStyle={{ backgroundColor: '#E6E6E6' }}
    >
      <TamaguiSlider.TrackActive
        backgroundColor='#F46C64'
        disabled={disabled}
        disabledStyle={{ backgroundColor: '#E6E6E6' }}
      />
    </TamaguiSlider.Track>
    <TamaguiSlider.Thumb
      circular
      index={0}
      size='$1.5'
      borderWidth={0}
      backgroundColor='#F74C4C'
      disabled={disabled}
      disabledStyle={{ backgroundColor: '#BDBDBD' }}
    />
  </TamaguiSlider>
);
