import { useMemo } from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { isEmpty, isNil } from 'lodash';
import { useParams } from 'react-router-dom';

import { TableauEmbeddedView } from './TableauEmbeddedView';
import { TableauViews } from './TableauViews';

import Loading from '../../../screens/Loading';
import { useTableauToken } from '../hooks/useTableauToken';
import { useTableauViews } from '../hooks/useTableauViews';

export const TableauAnalytics = () => {
  const {
    data: tokenData,
    isLoading: isTokenLoading,
    isSuccess: isTokenSuccess,
  } = useTableauToken();

  const { views, isLoading: isViewsLoading } = useTableauViews(
    !isNil(tokenData),
  );

  const { viewId } = useParams<{ viewId: string | undefined }>();

  const view = useMemo(() => {
    if (isNil(viewId)) return null;

    return views?.find((v) => v.id === viewId);
  }, [views, viewId]);

  const breadcrumbs = useMemo(() => {
    if (isNil(view))
      return [
        <Typography
          color='text.primary'
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
          Reports
        </Typography>,
      ];

    return [
      <Link
        underline='hover'
        color='inherit'
        href='/analytics'
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
        Reports
      </Link>,
      <Typography color='text.primary'> {view.workbookName}</Typography>,
      <Typography color='text.primary'> {view.name}</Typography>,
    ];
  }, [view]);

  if (isTokenLoading || isViewsLoading) {
    return <Loading />;
  }

  if (!isTokenSuccess) {
    return <div>We were not able to load analytics page.</div>;
  }

  if (isEmpty(views)) {
    return <div>No reports available.</div>;
  }

  if (viewId && isNil(view)) {
    return <div>Report not found.</div>;
  }

  return (
    <>
      <Box sx={{ px: 1, pt: 1 }}>
        <Breadcrumbs aria-label='breadcrumb' separator='›'>
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      {isNil(view) && <TableauViews views={views || []} />}
      {viewId && view && (
        <TableauEmbeddedView token={tokenData.token} view={view} />
      )}
    </>
  );
};
