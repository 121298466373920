import { isEmpty } from 'lodash';

import { useEventReviewStore } from '../store/EventReviewStore';

export const useTimestamp = () => {
  const { images, position } = useEventReviewStore();

  if (!images || !images[position] || isEmpty(images[position]?.time)) {
    return null;
  }

  return new Date(images[position].time);
};
