import { includes } from 'lodash';

export const SORT_METHODS = ['smart', 'numeric', 'fallRisk'] as const;

export type SortMethod = (typeof SORT_METHODS)[number];

type SortOptions = { label: string; value: SortMethod }[];

export const SORT_OPTIONS: SortOptions = [
  { label: 'Smart', value: 'smart' },
  { label: 'Name', value: 'numeric' },
  { label: 'Fall Risk', value: 'fallRisk' },
];

export const FALLBACK_DEFAULT_SORT_METHOD = 'smart' satisfies SortMethod;

export const isValidSortMethod = (value: string): value is SortMethod =>
  includes(SORT_METHODS, value);
