import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getTableauViews } from '../data-access/getTableauViews';

export const useTableauViews = (enabled: boolean) => {
  const { data, isLoading } = useQuery({
    queryKey: ['tableau-views'],
    queryFn: getTableauViews,
    enabled,
  });

  return useMemo(
    () => ({ views: data?.views, isLoading }),
    [data, isLoading, enabled],
  );
};
