import type { PrefetchImage } from '@inspiren-monorepo/shared-react/universal';

export const prefetchImage: PrefetchImage = (url, onLoad, onError) => {
  const img = new Image();

  img.onload = () => {
    onLoad?.();
  };

  img.onerror = () => {
    onError?.();
  };

  img.onabort = () => {
    onError?.();
  };

  img.src = url;
};
