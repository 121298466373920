import { useQuery } from '@tanstack/react-query';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { getUnitsByOrg } from '../data-access/getUnitsForOrg';

import type { UseQueryResult } from '@tanstack/react-query';

type UseOrganizationRolesResult = UseQueryResult<AdminTypes.UnitDto[], unknown>;

export const useOrganizationUnits = (
  orgId?: string,
): UseOrganizationRolesResult =>
  useQuery({
    queryKey: ['org', orgId, 'units'],
    queryFn: () => getUnitsByOrg(orgId!),
    enabled: !!orgId,
  });
