export const screens = [
  { label: 'Rooms', path: '/rooms', subject: 'virtual-care.rooms' },
  {
    label: 'Event Review',
    path: '/event-review',
    subject: 'virtual-care.event-review',
  },
  {
    label: 'Tableau Analytics',
    path: '/analytics',
    subject: 'virtual-care.analytics',
  },
  { label: 'Admin', path: '/admin', subject: 'virtual-care.admin' },
] as const;
