import { useCallback, useMemo } from 'react';
import { groupBy } from 'lodash';
import { useParams } from 'react-router-dom';

import getRoomsTableFields from './constants/getRoomsTableFields';
import useRoomsUpsert from './hooks/useRoomsUpsert';

import { makeRoomId } from '../../../../utility/helpers/id';
import { useOrgRooms } from '../../hooks/useOrgRooms';
import ImportTable from '../ImportTable';
import { mapCsvRowsToFields } from '../ImportTable/helpers/mapCsvRowsToFields';
import csvCellMappers from '../ImportTable/utils/csvCellMappers';

import type { RoomFieldTypes } from './types/RoomFieldTypes';
import type {
  FieldsConfiguration,
  Importable,
  ImportableDataFields,
} from '../ImportTable/types/importable';
import type { UploadFn } from '../ImportTable/types/uploadFn';

const RoomsImportTable = () => {
  const { org } = useParams<{ org: string }>();
  const { handleAddSubmit, handleEditSubmit } = useRoomsUpsert();

  const { isLoading: roomsLoading, rooms } = useOrgRooms({
    orgId: org,
    includeDeleted: true,
  });

  const fieldsConfiguration: FieldsConfiguration<RoomFieldTypes> = useMemo(
    () => ({
      id: {
        csvHeader: 'id',
        mapper: csvCellMappers.String,
      },
      displayName: {
        csvHeader: 'display name',
        mapper: csvCellMappers.String,
      },
      organization: {
        csvHeader: 'organization',
        mapper: csvCellMappers.StringPredefined(org ? [org] : [], true),
      },
      buildingFloorUnit: {
        csvHeader: 'building-floor-unit',
        mapper: csvCellMappers.String,
      },
      fallRiskLevel: {
        csvHeader: 'fall risk level',
        mapper: csvCellMappers.FallRiskLevel,
      },
      hide: {
        csvHeader: 'hidden',
        mapper: csvCellMappers.Boolean,
      },
      deleted: {
        csvHeader: 'archived',
        mapper: csvCellMappers.Boolean,
      },
    }),
    [org],
  );

  const columns: ImportableDataFields<RoomFieldTypes> = useMemo(
    () =>
      getRoomsTableFields([org!]).filter((e) => !!fieldsConfiguration[e.field]),
    [org],
  );

  const roomsMap = useMemo(() => groupBy(rooms || [], 'mainId'), [rooms]);

  const mapCsvToFields = useCallback(
    async (csvRows: string[][]): Promise<Importable<RoomFieldTypes>[]> =>
      mapCsvRowsToFields(csvRows, fieldsConfiguration, (e) => !!roomsMap[e.id]),
    [roomsMap],
  );

  const createDefaultRow = useCallback(
    (): RoomFieldTypes =>
      ({
        id: '',
        displayName: '',
        organization: org,
        buildingFloorUnit: '',
        fallRiskLevel: 'off',
        hide: false,
        deleted: false,
      }) as RoomFieldTypes,
    [org],
  );

  const upload: UploadFn<RoomFieldTypes> = useCallback(
    async (item) => {
      const {
        $meta: { alreadyExists },
        ...rest
      } = item;

      const fn = alreadyExists ? handleEditSubmit : handleAddSubmit;

      await fn(rest);
    },
    [handleAddSubmit, handleEditSubmit],
  );

  const onRowUpdate = useCallback(
    (row: Importable<RoomFieldTypes>): Importable<RoomFieldTypes> => {
      const roomId = makeRoomId(
        row.organization,
        row.buildingFloorUnit,
        row.id,
      );

      return {
        ...row,
        $meta: {
          ...row.$meta,
          alreadyExists: (!!roomId && !!roomsMap[roomId]) || false,
        },
      };
    },
    [roomsMap],
  );

  return (
    <ImportTable
      fields={columns}
      createDefaultRow={createDefaultRow}
      mapCsvToFields={mapCsvToFields}
      loading={roomsLoading}
      upload={upload}
      itemName='rooms'
      heading={`Import rooms for org ${org}`}
      onRowUpdate={onRowUpdate}
      bulkUpdateSupport
    />
  );
};

export default RoomsImportTable;
