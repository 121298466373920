import { useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';

import type { Room } from '@inspiren-monorepo/shared-types';
import { extractOrgFromRoom } from '@inspiren-monorepo/util-rooms';

import { useUnitForRoom } from './useUnitForRoom';

import { useCurrentUser } from '../../../HOC/CurrentUserContextProvider';
import { useOrgSettings } from '../../../hooks/useOrgSettings';
import { generateNotifSound } from '../helpers/generateNotifSound';

export const useNotifSound = (room: Room) => {
  const [repeat, setCurrentRepeat] = useState<number>(0);
  const repeatRef = useRef<number>(repeat);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const { user } = useCurrentUser();
  const unit = useUnitForRoom(room.unit);
  const orgId = extractOrgFromRoom(room.mainId);
  const settings = useOrgSettings(orgId);
  const muteAlerts = useMemo(() => user?.mute, [user]);

  useEffect(
    () =>
      // this is to stop interval when the component unmounts ex. user leaves to another page
      () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          setCurrentRepeat(0);
        }
      },
    [intervalRef],
  );

  useEffect(() => {
    repeatRef.current = repeat;
  }, [repeat]);

  useEffect(() => {
    if (
      room.alert === 'fall' &&
      !room.offline &&
      !muteAlerts &&
      !room.disable &&
      settings?.data?.settings?.repeats &&
      settings?.data?.settings?.notifDelay &&
      unit?.webFallAlertSoundLoop
    ) {
      const soundPlaySession = sessionStorage.getItem(
        `soundPlaySession-${room.mainId}-${room.notificationId}-fall`,
      );

      if (
        !soundPlaySession &&
        !intervalRef.current &&
        settings?.data?.settings?.notifDelay
      ) {
        if (repeatRef.current === 0) {
          const audio = new Audio(generateNotifSound(room.alert || ''));
          audio.play();
          setCurrentRepeat((prev) => prev + 1);
        }

        const settingsRepeat = settings?.data?.settings?.repeats || 0;
        const settingsDuration = settings?.data?.settings?.notifDelay || 30;

        intervalRef.current = setInterval(() => {
          const audio = new Audio(generateNotifSound(room.alert || ''));
          audio.play();

          sessionStorage.setItem(
            `soundPlaySession-${room.mainId}-${room.notificationId}-fall`,
            'true',
          );

          setCurrentRepeat((prev) => prev + 1);

          if (intervalRef.current && repeatRef.current >= settingsRepeat) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setCurrentRepeat(0);
          }
        }, settingsDuration * 1000);
      }
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        setCurrentRepeat(0);
      }

      sessionStorage.removeItem(
        `soundPlaySession-${room.mainId}-${room.notificationId}-fall`,
      );
    }
  }, [
    room.alert,
    room.offline,
    muteAlerts,
    room.disable,
    settings,
    room.mainId,
    room.notificationId,
    repeat,
    setCurrentRepeat,
    unit?.webFallAlertSoundLoop,
    intervalRef,
  ]);

  useEffect(() => {
    if (
      !isEmpty(room.alert) &&
      !room.offline &&
      !muteAlerts &&
      !room.disable &&
      room.alert !== 'fall'
    ) {
      const soundPlaySession = sessionStorage.getItem(
        `soundPlaySession-${room.mainId}-${room.notificationId}`,
      );

      if (!soundPlaySession) {
        const audio = new Audio(generateNotifSound(room.alert || ''));
        audio.play();

        sessionStorage.setItem(
          `soundPlaySession-${room.mainId}-${room.notificationId}`,
          'true',
        );
      }
    } else {
      sessionStorage.removeItem(
        `soundPlaySession-${room.mainId}-${room.notificationId}`,
      );
    }
  }, [room, muteAlerts]);
};
