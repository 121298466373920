import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import { getSentryEnv } from '@inspiren-monorepo/util-sentry/universal';

import { shouldHandleError } from './shouldHandleError';

import { getApiBaseUrl } from '../../config/apiUrlConfig';

import type { User } from '../../../types';

export const initSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_VC_WEB_SENTRY_DSN,
    environment: getSentryEnv(
      import.meta.env.DEV ? 'development' : import.meta.env.VITE_API_ENV,
    ),
    release: import.meta.env.VITE_SENTRY_RELEASE,
    enabled: import.meta.env.PROD,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.01,
    tracePropagationTargets: ['localhost', getApiBaseUrl()],
    beforeSend: (event, hint) =>
      hint?.originalException && !shouldHandleError(hint.originalException)
        ? null
        : event,
  });
};

export const setSentryUser = (user: User) => {
  Sentry.setUser({
    id: user.mainId,
    email: user.email,
    username: user.username,
    org: user.org,
  });
};

export const clearSentryUser = () => {
  Sentry.setUser(null);
};

export const captureException: typeof Sentry.captureException = (
  exception,
  captureContext,
) => Sentry.captureException(exception, captureContext);
