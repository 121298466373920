import { Outlet } from 'react-router-dom';

import AlertSnackbar from './AlertSnackbar';
import { ContactSupportButton } from './ContactSupportButton';
import NavBar from './NavBar';

const Layout = () => (
  <>
    <NavBar />
    <main>
      <Outlet />
    </main>
    <ContactSupportButton />
    <AlertSnackbar />
  </>
);

export default Layout;
