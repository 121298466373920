import { IconButton } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { Controller } from 'react-hook-form';

import { isValidMobileNumber } from '@inspiren-monorepo/util-formatters';

import PhoneNumberInput from './PhoneNumberInput';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control<{ mobilePhoneNumber: string }>;
  onClear?: () => void;
}

const ControlledPhoneNumberInput = ({ control, onClear }: Props) => (
  <Controller
    control={control}
    name='mobilePhoneNumber'
    rules={{
      validate: (value) =>
        value !== '' && !isValidMobileNumber(value) ? 'Invalid format' : true,
    }}
    render={({
      field: { onChange, value },
      fieldState: { error, isDirty, isTouched },
      formState: { isSubmitting },
    }) => (
      <PhoneNumberInput
        value={value}
        onChange={onChange}
        disabled={isSubmitting}
        error={error && (isDirty || isTouched)}
        helperText={error?.message}
        InputProps={{
          endAdornment: (
            <IconButton onClick={onClear}>
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    )}
  />
);

export default ControlledPhoneNumberInput;
