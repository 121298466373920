import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  History as HistoryIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import type { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import {
  bindDialog,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import { dateTimeFormat } from '../../../../../utility/helpers/time';
import { useBasestationHistory } from '../hooks/useBasestationHistory';

const unassignedClassName = (params: GridCellParams<string>) =>
  params.value === 'unassigned' ? 'basestations unassigned' : '';

const columns: GridColDef[] = [
  {
    field: 'time',
    headerName: 'Date',
    width: 200,
    renderCell: ({ value }) => format(parseISO(value), dateTimeFormat),
  },
  {
    field: 'room',
    headerName: 'Changed To Room',
    width: 360,
    cellClassName: unassignedClassName,
  },
  {
    field: 'user',
    headerName: 'Changed By',
    flex: 1,
  },
];

interface Props {
  basestationId: string;
}

export const AssignmentHistory = ({ basestationId }: Props) => {
  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'basestation-room-assignment-history-modal',
  });

  const {
    data = [],
    isError,
    isLoading,
  } = useBasestationHistory({
    basestationId,
    enabled: popupState.isOpen,
  });

  return (
    <>
      <Tooltip title='Basestation Room Assignment History' placement='top'>
        <IconButton {...bindTrigger(popupState)}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>

      {popupState.isOpen && (
        <Dialog fullWidth maxWidth='md' {...bindDialog(popupState)}>
          <DialogTitle>
            Basestation Room Assignment History
            <IconButton
              aria-label='close'
              onClick={popupState.close}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DataGrid
              columns={columns}
              rows={data}
              loading={isLoading}
              getRowId={(row) => `${row.basestation}-${row.time}`}
              error={isError}
              pageSize={10}
              autoHeight
              disableSelectionOnClick
              sx={{
                '& .basestations.unassigned': {
                  color: (theme) => theme.palette.grey[400],
                  fontStyle: 'italic',
                },
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
