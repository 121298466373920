import { MuiTelInput } from 'mui-tel-input';

import type { MuiTelInputProps } from 'mui-tel-input';

type Props = Omit<
  MuiTelInputProps,
  | 'variant'
  | 'disableDropdown'
  | 'defaultCountry'
  | 'onlyCountries'
  | 'forceCallingCode'
  | 'contentEditable'
>;

const PhoneNumberInput = ({ label, ...props }: Props) => (
  <MuiTelInput
    {...props}
    label={label || 'Phone number'}
    variant='standard'
    disableDropdown
    defaultCountry='US'
    onlyCountries={['US']}
    forceCallingCode
  />
);

export default PhoneNumberInput;
