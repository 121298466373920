import { useEffect, useMemo } from 'react';
import { subSeconds } from 'date-fns';
import { isEmpty, pick } from 'lodash';
import { useShallow } from 'zustand/react/shallow';

import { useActiveAlertPlaybackStore } from './useActiveAlertPlaybackStore';
import { usePlayback } from './usePlayback';
import { usePreloadedRoomImages } from './usePreloadedRoomImages';

import type { RoomImagesQueryFunction } from './useRoomImages';
import type { QueryKey } from '@tanstack/react-query';

const PADDING_SECONDS = 30;

interface Params {
  roomImagesQueryKey: QueryKey;
  roomImagesQueryFn: RoomImagesQueryFunction;
  roomId: string;
  notifTime?: Date;
}

export const useActiveAlertPlayback = ({
  roomId,
  notifTime,
  roomImagesQueryKey,
  roomImagesQueryFn,
}: Params) => {
  const { progress, images, imagesLoading, isActive, reset } =
    useActiveAlertPlaybackStore(
      useShallow((state) =>
        pick(state, [
          'progress',
          'images',
          'imagesLoading',
          'isActive',
          'reset',
        ]),
      ),
    );

  usePreloadedRoomImages({
    roomImagesQueryKey,
    roomImagesQueryFn,
    roomId,
    startTime: notifTime ? subSeconds(notifTime, PADDING_SECONDS) : undefined,
    durationInSeconds: PADDING_SECONDS * 2,
  });

  usePlayback(images.length);

  useEffect(() => {
    reset();
  }, [roomId]);

  useEffect(() => {
    if (!isActive) reset();
  }, [isActive]);

  const noImagesAvailable = useMemo(
    () => !imagesLoading && isEmpty(images),
    [imagesLoading, images],
  );

  const imageUrl = progress in images ? images[progress]?.url : undefined;

  const imageTimestamp =
    progress in images ? new Date(images[progress]?.time) : undefined;

  return {
    isActive,
    imageUrl,
    imageTimestamp,
    imagesLoading,
    noImagesAvailable,
  };
};
