import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

interface Props {
  summaryComponent: JSX.Element;
  detailComponent: JSX.Element;
  summaryState: boolean;
}

export const FeatureAccordion = ({
  summaryComponent,
  detailComponent,
  summaryState,
}: Props) => (
  <Accordion
    expanded={summaryState || false}
    disableGutters
    elevation={0}
    sx={{
      backgroundColor: 'transparent',
      '&:before': {
        display: 'none',
      },
    }}
  >
    <AccordionSummary
      sx={{
        p: 0,
        '& .MuiAccordionSummary-content.Mui-expanded': {
          mb: 0,
        },
      }}
    >
      {summaryComponent}
    </AccordionSummary>
    <AccordionDetails sx={{ p: 0 }}>{detailComponent}</AccordionDetails>
  </Accordion>
);
