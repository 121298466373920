import type { GridRowId } from '@mui/x-data-grid';

import type { Importable } from '../types/importable';

const isUnique = (a: unknown, b: unknown): boolean => {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.toLocaleLowerCase() === b.toLocaleLowerCase();
  }

  return a === b;
};

const importUniqueValidator = <TFieldTypes>(
  uniqueId: GridRowId,
  params: TFieldTypes,
  field: keyof TFieldTypes,
  rows: Importable<TFieldTypes>[],
) => {
  const value = params[field];

  if (!value) {
    return null;
  }

  if (
    rows.some(
      (row) => row.$meta.uniqueId !== uniqueId && isUnique(row[field], value),
    )
  ) {
    return `A row with this ${field.toString()} already exists`;
  }

  return null;
};

export default importUniqueValidator;
