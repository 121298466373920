export function roundToNearestXSeconds(date: Date, interval: number): Date {
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();
  const totalSeconds = seconds + milliseconds / 1000; // Convert to total seconds

  // Calculate the floor rounded date.
  const roundedTotalSeconds = Math.floor(totalSeconds / interval) * interval;

  // Create a new Date object with the rounded second value
  const roundedDate = new Date(date.getTime());
  roundedDate.setSeconds(0);
  roundedDate.setMilliseconds(0);
  roundedDate.setSeconds(roundedTotalSeconds);

  return roundedDate;
}
