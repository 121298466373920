import type { OfflineEvent } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../../../utility/client';

export const getLastOfflineEventForRoom = async (roomId: string) => {
  const response = await Api.get<OfflineEvent>(
    `/v1/rooms/${roomId}/offline/last`,
  );

  return response.data;
};
