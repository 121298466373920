import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getOrgUsers } from '../data-access/getOrgUsers';

import type { User } from '../../../../types';
import type { LastKey } from '../data-access/getOrgUsers';

export const useTelesittingUsers = (selectedOrg: string) => {
  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['users', selectedOrg],
    queryFn: ({ pageParam }) => getOrgUsers(pageParam),
    getNextPageParam: (lastPage) => lastPage.lastKey,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    initialPageParam: undefined as LastKey | undefined,
  });

  const users = useMemo(() => {
    const telesittingUsers: User[] = [];
    if (!data?.pages) return telesittingUsers;

    data.pages.forEach((page) => {
      telesittingUsers.push(...page.users);
    });

    return telesittingUsers;
  }, [data]);

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, data]);

  return { users, isFetching };
};
