export function getURLSearchParams() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlOrg = urlParams.get('org');
  const urlUnit = urlParams.get('unit');
  const urlRoom = urlParams.get('room');
  const urlStart = urlParams.get('start');
  const urlEnd = urlParams.get('end');
  return { urlOrg, urlUnit, urlRoom, urlStart, urlEnd };
}
