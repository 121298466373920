import type { RegionConfig } from '@inspiren-monorepo/shared-types';

import { Api } from '../../../utility/client';

export const getRoomRegions = async (
  roomId: string,
): Promise<RegionConfig[]> => {
  try {
    const res = await Api.get<RegionConfig[]>(
      `/telesitting/v1/rooms/${roomId}/regions`,
    );

    return res?.data;
  } catch (error) {
    console.error('error requesting room regions');
    throw error;
  }
};
