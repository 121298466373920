import type { LinkProps } from '@mui/material';
import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

import { palette } from './palette';

import LinkBehavior from '../components/shared/LinkBehavior';

const letterSpacingHeader = '-0.02em';
const letterSpacingBody = '-0.007rem';

const draftTheme = createTheme({
  shape: {
    borderRadius: 6,
  },
  typography: {
    h1: {
      fontWeight: 800,
      fontSize: '2rem',
      letterSpacing: letterSpacingHeader,
    },
    h2: {
      fontWeight: 800,
      fontSize: '1.8rem',
      letterSpacing: letterSpacingHeader,
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.6rem',
      letterSpacing: letterSpacingHeader,
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.4rem',
      letterSpacing: letterSpacingHeader,
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.2rem',
      letterSpacing: letterSpacingHeader,
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.1rem',
      letterSpacing: letterSpacingHeader,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
      letterSpacing: letterSpacingBody,
    },
    subtitle1: { letterSpacing: letterSpacingBody },
    subtitle2: { letterSpacing: letterSpacingBody },
    body1: { letterSpacing: letterSpacingBody },
    body2: { letterSpacing: letterSpacingBody },
    caption: { letterSpacing: letterSpacingBody },
    overline: { letterSpacing: letterSpacingBody },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: grey[200],
          },
        },
      },
    },
  },
});

const newPalette = Object.assign(
  {},
  ...Object.entries(palette).map(([key, value]) => ({
    [key]: draftTheme.palette.augmentColor({
      color: { main: value },
      name: key,
    }),
  })),
);

const theme = createTheme(draftTheme, {
  palette: {
    mode: 'light',
    ...newPalette,
  },
});

export default theme;
