import { useMemo } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';

import type { BathroomEvent } from '@inspiren-monorepo/virtual-care/api-contracts';

import { getBathroomEvents } from '../data-access/getBathroomEvents';
import { useEventReviewStore } from '../store/EventReviewStore';

export const useBathroomEvents = () => {
  const { selectedRoom, startDate, endDate } = useEventReviewStore();

  const roomId = selectedRoom?.mainId;

  const { data, isLoading, isError } = useQuery({
    queryKey: ['eventReview', 'bathroomEvents', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate
        ? async () =>
            getBathroomEvents(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
            )
        : skipToken,
  });

  const bathroomEvents = useMemo(() => {
    if (!data) return [];

    return data?.map((event: BathroomEvent) => ({
      event: 'Bathroom Event',
      time: new Date(event.start),
      timeIn: new Date(event.start),
      timeOut: event.end ? new Date(event.end) : null,
      duration: event.duration,
      id: `${event.start}-Bathroom`,
    }));
  }, [data]);

  return { data: bathroomEvents, isLoading, isError };
};
