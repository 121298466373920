import { useCallback, useMemo } from 'react';
import { compact, isNil, sortBy } from 'lodash';

import { useOrganizationRoles } from '../../../hooks/useOrganizationRoles';
import { ControlledAutocomplete } from '../../../modals/ControlledAutocomplete';

import type { Control } from 'react-hook-form';

interface Props {
  org: string;
  control: Control;
  id: string;
  label: string;
}

export const SelectRole = ({ org, control, id, label }: Props) => {
  const { data: roles, isLoading } = useOrganizationRoles(org);

  const roleIds = useMemo(() => {
    if (isNil(roles)) return [];
    return compact(
      sortBy(roles, 'displayName').map(({ id: roleId }) => roleId),
    );
  }, [roles]);

  const getOptionLabel = useCallback(
    (option: string) =>
      roles?.find(({ id: roleId }) => roleId === option)?.displayName || '',
    [roles],
  );

  return (
    <ControlledAutocomplete
      loading={isLoading}
      control={control}
      id={id}
      label={label}
      items={roleIds}
      getOptionLabel={getOptionLabel}
    />
  );
};
