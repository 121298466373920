// this function will take a start date and a end date
// and will split the date range into 8 minute windows

import { addMinutes, isBefore } from 'date-fns';

// and return an array of those windows
export function splitDatesIntoMinWindows(start: Date, end: Date) {
  const windows = [];
  let current = new Date(start);

  const endDate = isBefore(end, new Date()) ? end : new Date();

  while (current < end) {
    const next = addMinutes(
      current,
      Number(import.meta.env.VITE_VC_WEB_IMG_REQ_TIMEFRAME),
    );

    windows.push({
      start: current,
      end: next > endDate ? endDate : next,
    });

    current = next;
  }

  return windows;
}
