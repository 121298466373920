import { useQuery } from '@tanstack/react-query';

import { getBeaconAssignments } from '../data-access/getBeaconAssignments';

interface Params {
  org: string;
}

export const useBeaconAssignments = ({ org }: Params) =>
  useQuery({
    queryKey: ['beacons', org],
    queryFn: () => getBeaconAssignments(org).then((res) => res.data),
  });
