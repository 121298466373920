export const getApiBaseUrl = () => {
  if (import.meta.env.VITEST) return 'https://example.com';

  if (import.meta.env.VITE_VC_API_URL) {
    return import.meta.env.VITE_VC_API_URL;
  }

  switch (import.meta.env.VITE_API_ENV) {
    case 'production':
      return import.meta.env.VITE_VC_API_URL_PROD;
    case 'stage':
      return import.meta.env.VITE_VC_API_URL_STAGE;
    case 'next':
      return import.meta.env.VITE_VC_API_URL_NEXT;
    default:
      return import.meta.env.VITE_VC_API_URL_THIS;
  }
};
