import { useMemo } from 'react';
import { colors } from '@mui/material';
import { isWithinInterval, subHours } from 'date-fns';

type Props = {
  lastActivityAt: string;
};

export const StaffActivityStatus = ({ lastActivityAt }: Props) => {
  const color = useMemo(() => {
    const lastActivity = new Date(lastActivityAt);

    if (
      isWithinInterval(lastActivity, {
        start: subHours(new Date(), 1),
        end: new Date(),
      })
    ) {
      return colors.green[400];
    }

    if (
      isWithinInterval(lastActivity, {
        start: subHours(new Date(), 3),
        end: subHours(new Date(), 1),
      })
    ) {
      return colors.yellow[500];
    }

    return colors.red[400];
  }, [lastActivityAt]);

  return (
    <div
      style={{
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: color,
      }}
    />
  );
};
