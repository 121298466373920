import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { CheckCircleOutlined as VerifiedIcon } from '@mui/icons-material';

import PhoneNumberInput from '../PhoneNumberInput';

interface Props {
  confirmedPhoneNumber?: string;
  onChangeTriggered: () => void;
}

const VerifiedStage = ({ confirmedPhoneNumber, onChangeTriggered }: Props) => (
  <>
    <DialogContent>
      <Stack direction='row' alignItems='flex-end'>
        <PhoneNumberInput value={confirmedPhoneNumber} disabled />
        <Stack direction='row' mb={0.5} ml={1}>
          <VerifiedIcon color='success' />
          <Typography
            color={(theme) => theme.palette.success.main}
            sx={{ pl: 0.5 }}
          >
            Verified
          </Typography>
        </Stack>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={onChangeTriggered} variant='contained'>
        Change number
      </Button>
    </DialogActions>
  </>
);

export default VerifiedStage;
