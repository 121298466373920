import {
  isUpstreamError,
  getUpstreamErrorMessage,
} from '@inspiren-monorepo/shared-types';
import { unauthorizedInterceptorFactory } from '@inspiren-monorepo/util-axios';

import type { AxiosResponse } from 'axios';

const unauthorizedInterceptor = unauthorizedInterceptorFactory({
  onUnauthorized: (response: AxiosResponse) => {
    const { data, status } = response;
    let url = `/${status}`;

    if (isUpstreamError(data)) {
      const message = getUpstreamErrorMessage(data);

      if (message) {
        url += `?error=${Buffer.from(message).toString('base64')}`;
      }
    }

    window.location.href = url;
  },
});

export default unauthorizedInterceptor;
