import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isNil } from 'lodash';
import { Navigate } from 'react-router-dom';

import { useRegisteredUser } from './hooks/useRegisteredUser';

import Loading from '../screens/Loading';
import Unauthorized from '../screens/Unauthorized';

const ProtectedComponent = ({
  component,
  otherCheck = true,
}: {
  component: JSX.Element;
  otherCheck?: boolean;
}) => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user: authUser,
  } = useAuth0();

  const { user, loading: isUserLoading } = useRegisteredUser(authUser?.email);

  const [ruleFail, updateRuleFail] = useState(false);

  const ruleFailCheck = useCallback(
    (url: string) => url.includes('?error=unauthorized'),
    [],
  );

  useEffect(() => {
    if (ruleFailCheck(window.location.href)) {
      if (!ruleFail) updateRuleFail(true);
      return;
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  if (isUserLoading || isLoading) return <Loading />;

  /**
   * Any custom Auth0 rules are checked after redirect from the Universal Login page,
   * So, we need to maintain a check of whether one of these rules failed.
   */
  if (
    (!isNil(authUser) &&
      (isNil(user) || !user?.ability?.can('access', 'virtual-care'))) ||
    (!isAuthenticated && ruleFail)
  ) {
    return <Unauthorized />;
  }

  /**
   * This component allows for a custom, client-side check to be passed as well.
   * It will redirect the user if it fails
   */
  if (isAuthenticated && !otherCheck) return <Navigate to='/' />;

  if (isAuthenticated) return component;

  return null;
};

export default ProtectedComponent;
