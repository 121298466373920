import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const postRole = async (role: AdminTypes.AddRoleDto) => {
  const res = await Api.post('/admin/v1/roles', {
    ...role,
    id: `${role.displayName}`,
  });

  return res.data;
};
