import { Stack, Typography } from '@mui/material';
import { CopyAll } from '@mui/icons-material';

interface Props {
  baseId: string;
  isAdmin: boolean;
}

export const BasestationIdDisplay = ({ baseId, isAdmin }: Props) =>
  isAdmin && (
    <Stack spacing={1} direction='row'>
      <Typography variant='subtitle2'>Basestation ID: </Typography>
      <Typography variant='body2' data-testid='room-menu-basestation-id'>
        {baseId}
      </Typography>
      <CopyAll
        fontSize='small'
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          navigator.clipboard.writeText(baseId);
        }}
      />
    </Stack>
  );
