import { useEffect } from 'react';
import { Toolbar } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import ManageRooms from '../components/Periscope/ManageRooms';
import RoomsView from '../components/Periscope/RoomsView';
import { credentialsProvider } from '../utility/mqtt/CredentialsProvider';

const Periscope = () => {
  useEffect(() => {
    credentialsProvider.startPeriodicCredentialRefresh();

    return () => {
      credentialsProvider.stopPeriodicCredentialRefresh();
    };
  }, []);

  return (
    <>
      <Toolbar />
      <Routes>
        <Route index element={<RoomsView />} />
        <Route path='manage' element={<ManageRooms />} />
      </Routes>
    </>
  );
};

export default Periscope;
