import { Api } from '../../../../utility/client';

export const startVideoExport = async (
  room: string,
  start: Date,
  end: Date,
) => {
  const url = '/eventReview/v1/export/videos';

  const res = await Api.post<{ exportId: string }>(url, {
    room,
    start,
    end,
  });

  return res && res.data;
};
