import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { buildRole } from '@inspiren-monorepo/feature-permissions';

import { Api } from '../../utility/client';

import type { User } from '../../../types';

export function useRegisteredUser(email: string | undefined) {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error } = useQuery({
    queryKey: ['auth-user', email],

    queryFn: async () => {
      if (isNil(email)) return null;

      const url = `/telesitting/v1/users/auth/${encodeURIComponent(
        email?.toLowerCase(),
      )}`;

      const token = await getAccessTokenSilently();

      const res = await Api.get<User>(
        url,
        token ? { headers: { Authorization: `Bearer ${token}` } } : undefined,
      );

      const user = res.data;

      const userWithAbility = {
        ...user,
        ability: buildRole(user.permissions || []),
      };

      return userWithAbility;
    },
  });

  return {
    user: data,
    loading: isLoading,
    error,
  };
}
