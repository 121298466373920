import { useMemo } from 'react';
import { groupBy, isNil, keys, sortBy } from 'lodash';

import { useOrganizationUnits } from '../../../hooks/useOrgUnits';
import ControlledDropdown from '../../../modals/ControlledDropdown';

import type { Item } from '../../../modals/ControlledDropdown';
import type { Control } from 'react-hook-form';

interface Props {
  org: string;
  control: Control;
  id: string;
  label: string;
}

export const SelectUnit = ({ org, control, id, label }: Props) => {
  const { data: units } = useOrganizationUnits(org);

  const unitItems = useMemo(() => {
    if (isNil(units)) return [];

    const unitsByBuilding = groupBy(units, 'buildingDisplayName');
    const buildings = keys(unitsByBuilding).sort();

    const items: Item[] = [];

    buildings.forEach((building) => {
      items.push({ groupLabel: building });

      sortBy(unitsByBuilding[building], 'displayName').forEach((unit) => {
        items.push({
          label:
            unit.displayName || (unit.displayName?.split('-').pop() as string),
          value: `Room-${unit.domainId}`,
        });
      });
    });

    return [
      {
        label: 'None',
        value: '',
      },
      ...items,
    ];
  }, [units]);

  return (
    <ControlledDropdown
      id={id}
      label={label}
      items={unitItems}
      control={control}
    />
  );
};
