import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { History } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';

interface Row {
  id: string;
  [key: string]: any;
}

interface Props {
  open: boolean;
  onOpen: (value?: any) => void;
  onClose: () => void;
  rows: Row[];
  loading: boolean;
  columns: GridColDef[];
  error: any;
  title: string;
}

export const BaseHistoryModal = ({
  title,
  open,
  onOpen,
  onClose,
  rows,
  loading,
  columns,
  error,
}: Props) => (
  <>
    <IconButton size='small' onClick={onOpen}>
      <History />
    </IconButton>
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='xl'>
      <DialogTitle>
        {title}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          columns={columns}
          rows={rows}
          loading={loading}
          getRowId={(row) => row.id}
          error={error}
          autoHeight
        />
      </DialogContent>
    </Dialog>
  </>
);
