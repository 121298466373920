import { sortBy } from 'lodash';

import type { SortMethod, Room } from '@inspiren-monorepo/shared-types';

import { checkIfPrivacyMode } from './checkIfPrivacyMode';

type SortableRoom = Pick<
  Room,
  | 'name'
  | 'disable'
  | 'alert'
  | 'priv'
  | 'lastLoc'
  | 'count'
  | 'lastChecked'
  | 'fallRiskLevel'
  | 'offline'
>;

const presort = <T extends SortableRoom>(rooms: T[]): T[] =>
  [...rooms].sort((a, b) =>
    a.name.localeCompare(b.name, 'en', { numeric: true }),
  );

const commonSort: ((room: SortableRoom) => boolean)[] = [
  // All enabled AUGis first
  (room) => Boolean(room.disable),

  // Then online AUGis
  (room) => Boolean(room.offline),

  // Fall alerts first
  (room) => room.alert !== 'fall',

  // Then all active alerts
  (room) => !room.alert,
];

const smartSort = <T extends SortableRoom>(rooms: T[]): T[] =>
  sortBy(presort(rooms), [
    ...commonSort,

    // Sort by privacy mode
    (room) => checkIfPrivacyMode(room.priv),

    // Sort by "in view" status
    (room) => room.lastLoc && ['none', 'outside'].includes(room.lastLoc),

    // Sort by count
    (room) => room.count,

    // Sort by last checked
    (room) => {
      const { lastChecked } = room;
      return lastChecked ? Date.parse(lastChecked) : null;
    },
  ]);

const sortByFallRisk = <T extends SortableRoom>(rooms: T[]): T[] =>
  sortBy(presort(rooms), [
    ...commonSort,
    // Sort by fall risk
    (room) => {
      switch (room.fallRiskLevel) {
        case 'high':
          return 1;
        case 'medium':
          return 2;
        case 'low':
          return 3;
        case 'nightLow':
          return 4;
        default:
          return 5;
      }
    },
  ]);

const sortByAlpha = <T extends SortableRoom>(rooms: T[]): T[] =>
  sortBy(presort(rooms), commonSort);

export const sortRooms = <T extends SortableRoom>(
  rooms: T[],
  sortMethod?: SortMethod,
): T[] => {
  switch (sortMethod) {
    case 'smart':
      return smartSort(rooms);
    case 'numeric':
      return sortByAlpha(rooms);
    case 'fallRisk':
      return sortByFallRisk(rooms);
    default:
      return presort(rooms);
  }
};
