import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getTableauViewPreviewImage } from '../data-access/getTableauViewPreviewImage';

export const useTableauViewPreviewImage = (
  workbookId: string,
  viewId: string,
) =>
  useQuery({
    queryKey: ['tableau-view-preview-image', workbookId, viewId],
    queryFn: () => getTableauViewPreviewImage(workbookId, viewId),
    placeholderData: keepPreviousData,
    refetchOnMount: false,
  });
