/* eslint-disable no-param-reassign */
function toDotNotation(inputObj: any) {
  function walk(into: any, obj: any, prefix: (string | number)[] = []) {
    Object.entries(obj).forEach(([key, val]) => {
      if (val !== null && val !== undefined) {
        if (typeof val === 'object' && !Array.isArray(val))
          walk(into, val, [...prefix, key]);
        else into[[...prefix, key].join('.')] = val;
      }
    });
  }

  const out: any = {};
  walk(out, inputObj);
  return out;
}

export default toDotNotation;
