import { isFuture } from 'date-fns';
import { isEmpty } from 'lodash';

interface PrivOffUnit {
  privOff?: boolean;
}

export const checkIfPrivacyMode = (
  priv?: string | null,
  unit?: PrivOffUnit | null,
) => {
  if (!priv || unit?.privOff) return false;
  return !isEmpty(priv) && isFuture(new Date(priv));
};
