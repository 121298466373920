import { isEmpty } from 'lodash';

import { Api } from '../../../../../utility/client';

export async function createBeacon(
  org: string | undefined,
  id: string,
  assignedTo?: string,
) {
  await Api.post('/admin/v1/beacons', {
    org: isEmpty(org) ? undefined : org,
    id,
    assignedTo: isEmpty(assignedTo) ? undefined : assignedTo,
  });
}
