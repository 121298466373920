import { Box, Grow, Toolbar } from '@mui/material';

import DetailsPanel from './DetailsPanel';
import EventToolbar from './EventToolbar';
import ImagePlayer from './ImagePlayer';

export const EventReview = () => (
  <Box
    sx={{
      bgcolor: 'grey.50',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Toolbar sx={{ flex: '0 0 auto' }} />
    <Box sx={{ flex: '0 0 auto' }}>
      <EventToolbar />
    </Box>
    <Grow in style={{ transformOrigin: 'top center' }}>
      <Box sx={{ flex: '1 1 auto', px: 2, pb: 2 }}>
        <Box
          sx={(theme) => ({
            borderRadius: `${theme.shape.borderRadius}px`,
            border: `1px solid ${theme.palette.divider}`,
            bgcolor: '#fff',
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              flex: '1 1 50%',
              borderRight: {
                xs: 'none',
                md: `1px solid ${theme.palette.divider}`,
              },
              borderBottom: {
                xs: `1px solid ${theme.palette.divider}`,
                md: 'none',
              },
            })}
          >
            <ImagePlayer />
          </Box>
          <Box sx={{ flex: '1 1 50%' }}>
            <DetailsPanel />
          </Box>
        </Box>
      </Box>
    </Grow>
  </Box>
);
