import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useInterval } from 'usehooks-ts';

import type { PrefetchImage } from '../types/PrefetchImage';
import type { WasabiImage } from '../types/WasabiImage';

type Props = {
  src: WasabiImage[];
  prefetchImage: PrefetchImage;
  startIndex?: number;
  onImageLoadFailure?: (image: WasabiImage | undefined) => void;
};

export const useLiveImage = (props: Props) => {
  const { src, onImageLoadFailure, prefetchImage, startIndex } = props;

  const [currentImageIndex, setCurrentImageIndex] = useState(startIndex ?? 0);
  // save the img object instead of the index to prevent the image from breaking on index reset
  const [lastSuccessfulImage, setLastSuccessfulImage] = useState<WasabiImage>();
  // image retries
  const [retries, setRetries] = useState<number>(0);

  useInterval(
    () => {
      const image =
        (currentImageIndex in src && src[currentImageIndex]) || undefined;

      if (image) {
        prefetchImage(
          image.url,
          () => {
            setLastSuccessfulImage(image);
            setCurrentImageIndex((index) => index + 1);
            setRetries(0);
          },
          () => {
            setRetries((prev) => prev + 1);
          },
        );
      }

      if (retries > 3) {
        setCurrentImageIndex((index) => index + 1);
        setRetries(0);

        onImageLoadFailure?.(image);
      }
    },
    isEmpty(src) || currentImageIndex >= src.length - 1 ? null : 1000,
  );

  useEffect(() => {
    setCurrentImageIndex(startIndex ?? 0);
    setRetries(0);
  }, [src]);

  return {
    lastSuccessfulImage: lastSuccessfulImage?.url,
    currentImageIndex,
    timestamp: lastSuccessfulImage?.time,
  };
};
