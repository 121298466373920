import { Api } from '../../../utility/client';

interface Response {
  requestId: string;
}

export async function sendAudibleMessageToBasestation(
  requestId: string,
  userId: string,
  messageId: string,
  roomId: string,
  volume: number,
) {
  return Api.post<Response>('/v1/audible/send', {
    requestId,
    roomId,
    userId,
    sound: messageId,
    volume,
  }).then((resp) => resp.data);
}
