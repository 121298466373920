import { useState, useEffect } from 'react';
import { differenceInSeconds } from 'date-fns';

// Helper function to format seconds into a time string
function secondsToTime(seconds: number) {
  const h = Math.floor(seconds / 3600);

  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0');

  const s = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0');

  if (h === 0) {
    return `${m}:${s}`;
  }

  if (h >= 24) {
    return `${Math.floor(h / 24)}d ${h % 24}h`;
  }

  return `${h.toString().padStart(2, '0')}:${m}:${s}`;
}

// Custom hook to calculate and format time difference
const useFormattedTimeDifference = (
  time: string,
  isActive: boolean,
  useCurrentTime: boolean,
) => {
  const [formattedTime, setFormattedTime] = useState('');

  useEffect(() => {
    if (isActive) {
      const interval = setInterval(() => {
        const seconds = useCurrentTime
          ? differenceInSeconds(new Date(), new Date(time))
          : differenceInSeconds(new Date(time), new Date());

        if (seconds < 0 || Number.isNaN(seconds)) {
          setFormattedTime('');
        } else {
          setFormattedTime(secondsToTime(seconds));
        }
      }, 1000);

      return () => clearInterval(interval);
    }

    // Reset formatted time when not active
    setFormattedTime('');
    return () => true;
  }, [time, isActive, useCurrentTime]);

  return formattedTime;
};

export default useFormattedTimeDifference;
