import { Api } from '../../../../../utility/client';

import type { Room } from '../../../../../../types';

export const toggleBathroomAlerts = async (
  roomId: string,
  bathroomAlertsEnabled: boolean,
) =>
  Api.put<never, never, { newRoom: Partial<Room> }>(`/v1/rooms/pub/${roomId}`, {
    newRoom: { bathroomAlertsEnabled },
  });
