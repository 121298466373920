import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getTemplateRoles = async () => {
  const res = await Api.get<AdminTypes.RoleTemplate[]>(
    '/admin/v1/role-templates',
  );

  return res.data;
};
