import type React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import type { OrgAuthSettings } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useOrgAuthSettings } from './hooks/useOrgAuthSettings';

import {
  getAuth0Audience,
  getAuth0ClientId,
  getAuth0Connection,
  getAuth0Domain,
} from '../config/auth0Config';
import Loading from '../screens/Loading';
import { sendAmpEvent } from '../utility/amplitude';

import type { AppState } from '@auth0/auth0-react';

const AuthSettingsContext = createContext<{
  authSettings?: OrgAuthSettings;
}>({
  authSettings: undefined,
});

export const useCurrentAuthSettings = () => useContext(AuthSettingsContext);

const Auth = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const { authSettings, loading } = useOrgAuthSettings(
    window.location.hostname,
  );

  const onRedirectCallback = async (appState?: AppState) => {
    // Clear dropdown options in Event Review on login
    // so we don't get stale data from a previously logged in user
    sendAmpEvent('Login', {});
    navigate(`${appState?.returnTo || window.location.pathname}`);
  };

  const value = useMemo(() => ({ authSettings }), [authSettings]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AuthSettingsContext.Provider value={value}>
      <Auth0Provider
        domain={getAuth0Domain(authSettings)}
        clientId={getAuth0ClientId(authSettings)}
        audience={getAuth0Audience(authSettings)}
        redirectUri={`${window.location.origin}/rooms`}
        onRedirectCallback={onRedirectCallback}
        connection={getAuth0Connection(authSettings)}
      >
        {children}
      </Auth0Provider>
    </AuthSettingsContext.Provider>
  );
};

export default Auth;
