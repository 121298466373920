import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getOrgs = async () => {
  const res = await Api.get<EventReviewTypes.Organization[]>(
    '/eventReview/v1/orgs',
  );

  return res.data;
};
