import { memo } from 'react';

import { useTrainingVideos } from '../../../hooks/useTrainingVideo';

type Props = {
  value: string | undefined;
};

const TrainingVideoCell = ({ value }: Props) => {
  const { data } = useTrainingVideos();
  return data?.videos?.find((video) => video.url === value)?.path || '';
};

export default memo(TrainingVideoCell);
