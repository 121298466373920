import { Api } from '../../../../utility/client';

export interface ExportProgress {
  exportId: string;
  progress: number;
  url: string | null;
  error: string | null;
}

export const fetchExportProgress = async (exportId: string) => {
  const url = `/eventReview/v1/export/videos/${exportId}`;
  const res = await Api.get<ExportProgress>(url);

  return res && res.data;
};
