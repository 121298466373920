import { Api } from '../../../utility/client';

interface FallRiskUserEvent {
  room: string;
  time: string;
  type: 'FallRiskChanged' | 'FallRiskReset' | 'FallRiskAPI';
  user?: string;
  email?: string;
  fallRiskLevel: string;
}

export async function getRoomFallRiskChanges(roomId: string) {
  return Api.get<FallRiskUserEvent[]>(
    `telesitting/v1/rooms/${roomId}/fall_risk_changes`,
  ).then((res) => res.data);
}
