import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Stack, Switch, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { isEmpty, isNil } from 'lodash';
import toast from 'react-hot-toast';

import {
  getUsernameFromEmail,
  isUsernameOrg,
} from '@inspiren-monorepo/util-users';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import ChangePassword from './components/ChangePassword';
import ControlledSelectUnit from './components/ControlledSelectUnit';
import { SelectLevelAccess } from './components/SelectLevelAccess';
import SelectRole from './components/SelectRole';
import { getUsersTableFields } from './helpers/getUsersTableFields';
import useUsersUpsert from './hooks/useUsersUpsert';

import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import { useOrganizationRolesMap } from '../../../../hooks/useOrganizationRolesMap';
import { getAxiosErrorMessage } from '../../../../utility/error-handler';
import BulkImportLink from '../../components/BulkImportLink';
import OrgHeader from '../../components/OrgHeader';
import OrgSelector from '../../components/OrgSelector';
import { getOrgs } from '../../data-access/getOrgs';
import { useAdminUsers } from '../../hooks/useAdminUsers';
import { useUnits } from '../../hooks/useUnits';
import TableBase from '../TableBase';

import type { RoleOption } from './helpers/getRoleOptions';
import type { FieldTypes } from './helpers/getUsersTableFields';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

const UsersTable = () => {
  const awsOrg = import.meta.env.VITE_ORG_ID;
  const { isAdmin } = useIsAdmin();
  const [staffView, setStaffView] = useState(!isAdmin);

  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(
    awsOrg,
  );

  const {
    isLoading: orgsLoading,
    data: orgs,
    isError: orgsError,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const selectedOrg = useMemo(
    () => (orgs || []).find((org) => org.id === selectedOrgId),
    [selectedOrgId, orgs],
  );

  const orgId = selectedOrg?.id;

  const { users, isFetching } = useAdminUsers(orgId);

  const { data: roleMap = {} } = useOrganizationRolesMap(orgId);

  const { handleAddSubmit, handleEditSubmit } = useUsersUpsert({
    orgId,
    users,
    roleMap,
  });

  const { isLoading: unitsLoading, isError: unitsError } = useUnits();

  const { auth0EnterpriseConnections, textMessageAlerts } = useMemo(() => {
    const currentOrg = (orgs || []).find((org) => org.id === orgId);

    return {
      auth0EnterpriseConnections:
        currentOrg?.auth0EnterpriseConnections?.filter((r) => !isEmpty(r)) ||
        [],
      auth0DbConnection: currentOrg?.auth0DbConnection,
      textMessageAlerts: currentOrg?.textMessageAlerts,
    };
  }, [orgs, orgId]);

  const handleOrgChange = useCallback(
    (
      _e: React.ChangeEvent<object>,
      newValue: AdminTypes.OrganizationDto | null,
    ) => {
      setSelectedOrgId(newValue?.id);
    },
    [],
  );

  const modalLoading = useMemo(
    () => orgsLoading || unitsLoading,
    [orgsLoading, unitsLoading],
  );

  const modalError = useMemo(
    () => orgsError || unitsError,
    [orgsError, unitsError],
  );

  const renderModal: RenderFormModal<FieldTypes> = useCallback(
    ({ control, defaultComponents, fields, type, setValue, initialValues }) => (
      <>
        {isAdmin && (
          <>
            <OrgHeader
              displayName={selectedOrg?.displayName}
              id={selectedOrg?.id}
            />
            {defaultComponents.id}
          </>
        )}
        {defaultComponents.firstName}
        {defaultComponents.lastName}
        {orgId && isUsernameOrg(orgId) && defaultComponents.username}
        {defaultComponents.email}
        {defaultComponents.mobilePhone}
        {type === 'add' && defaultComponents.password}
        {type === 'edit' ? (
          <SelectRole
            control={control}
            items={fields.role.options as RoleOption[]}
          />
        ) : (
          defaultComponents.role
        )}
        <SelectLevelAccess control={control} roleMap={roleMap} />
        <ControlledSelectUnit control={control} org={orgId} />
        {type === 'edit' &&
          !auth0EnterpriseConnections.includes(
            initialValues?.auth0Connection || '',
          ) && <ChangePassword control={control} setValue={setValue} />}
      </>
    ),
    [selectedOrg, orgId, isAdmin, roleMap, auth0EnterpriseConnections],
  );

  const fields = useMemo(
    () =>
      getUsersTableFields(
        isAdmin,
        roleMap,
        orgId,
        textMessageAlerts,
        staffView,
      ),
    [isAdmin, orgId, roleMap, textMessageAlerts, staffView],
  );

  const usersData = useMemo(
    () =>
      users.map((user) => {
        const usernameFromEmail = isUsernameOrg(orgId)
          ? getUsernameFromEmail(user.email, orgId)
          : null;

        return {
          id: user.mainId,
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          role: user.roleId || '',
          unit: user.unit || null,
          email: (!usernameFromEmail && user.email) || '',
          username: user.username || usernameFromEmail || '',
          password: '',
          levelAccess: user.levelAccess || '',
          beacon: user.beacon || '',
          mobilePhone: user.mobilePhone,
          mobilePhoneVerified: user.mobilePhoneVerified,
          auth0Connection: user.auth0Connection,
          lastActivityAt: user.lastActivityAt,
        };
      }),
    [users],
  );

  const onEditSubmit: OnSubmitFormModal<FieldTypes> = async (params) => {
    try {
      await handleEditSubmit(params);
      toast.success('Successfully edited the user');
    } catch (error) {
      const errMsg = getAxiosErrorMessage(error) || 'failed to edit user';
      toast.error(`Error: ${errMsg}`);
    }
  };

  const onAddSubmit: OnSubmitFormModal<FieldTypes> = async (params) => {
    try {
      await handleAddSubmit(params);
      toast.success('Successfully added the user');
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        toast.error('Error: user already exists');
      } else {
        const errMsg = getAxiosErrorMessage(error) || 'failed to add new user';
        toast.error(`Error: ${errMsg}`);
      }
    }
  };

  return (
    <Stack spacing={2}>
      {isAdmin && (
        <OrgSelector
          orgs={orgs ?? []}
          loading={orgsLoading}
          value={selectedOrg || null}
          onChange={handleOrgChange}
        />
      )}
      <TableBase<FieldTypes>
        key={`users-table-${staffView ? 'staff' : 'all'}`}
        itemName='User'
        fields={fields}
        data={usersData || []}
        loading={isFetching}
        modalLoading={modalLoading}
        modalError={modalError}
        error={false}
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        customNoRowsText={
          isAdmin && isNil(selectedOrg) ? 'No organization selected' : undefined
        }
        defaultSort={staffView ? 'status' : undefined}
        extraToolbarButtons={
          isAdmin ? (
            <Stack direction='row' alignItems='center' gap={2} sx={{ flex: 1 }}>
              <BulkImportLink
                itemName='users'
                disabled={!selectedOrg?.id}
                urlSuffix={selectedOrg?.id}
              />
              <Typography variant='subtitle1' hidden={!isFetching} ml={2}>
                Fetching users...
              </Typography>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ flex: 1, justifyContent: 'flex-end' }}
              >
                <Typography variant='body2'>
                  Enable Staff Status View
                </Typography>
                <Switch
                  checked={staffView}
                  onChange={(_e, checked) => setStaffView(checked)}
                />
              </Stack>
            </Stack>
          ) : (
            <Stack direction='row' alignItems='center' gap={2} sx={{ flex: 1 }}>
              <Typography variant='subtitle1' hidden={!isFetching} ml={2}>
                Fetching users...
              </Typography>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='flex-end'
                sx={{ flex: 1 }}
              >
                <Typography variant='body2'>
                  Enable Staff Status View
                </Typography>
                <Switch
                  checked={staffView}
                  onChange={(_e, checked) => setStaffView(checked)}
                />
              </Stack>
            </Stack>
          )
        }
        disableAddButton={!orgId}
        renderModal={renderModal}
        getRowId={(row) => row.id}
      />
    </Stack>
  );
};

export default UsersTable;
