import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import toast from 'react-hot-toast';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { careLevelFields } from './constants/careLevelFields';
import { postCareLevel } from './data-access/postCareLevel';
import { putCareLevel } from './data-access/putCareLevel';
import {
  getCareLevelsQueryOptions,
  useCareLevels,
} from './hooks/useCareLevels';

import OrgHeader from '../../components/OrgHeader';
import OrgSelector from '../../components/OrgSelector';
import { getBuildings } from '../../data-access/getBuildings';
import { getOrgs } from '../../data-access/getOrgs';
import SelectBuilding from '../../modals/special/SelectBuilding';
import TableBase from '../TableBase';

import type { CareLevelFieldTypes } from './types/CareLevelFieldTypes';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

type SaveCareLevelFieldTypes = CareLevelFieldTypes & { buildingId?: string };

const CareLevelsTable = () => {
  const awsOrg = import.meta.env.VITE_ORG_ID;
  const queryClient = useQueryClient();

  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(
    awsOrg,
  );

  const { isLoading: orgsLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const selectedOrg = useMemo(
    () => (orgs || []).find((org) => org.id === selectedOrgId),
    [selectedOrgId, orgs],
  );

  // temporary fix for buildings till we migrate to RDS
  const { isLoading: buildingsLoading, data: buildings } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  const {
    isFetching: careLevelsFetching,
    isError: careLevelsError,
    data: careLevels,
  } = useCareLevels(selectedOrg?.id);

  const handleOrgChange = useCallback(
    (
      _e: React.ChangeEvent<object>,
      newValue: AdminTypes.OrganizationDto | null,
    ) => {
      setSelectedOrgId(newValue?.id);
    },
    [],
  );

  const data = useMemo(
    () =>
      (careLevels || []).map(
        ({ id, displayName, price, building, description }) => ({
          id,
          displayName,
          price: price.toString(),
          building: building || '',
          description: description || '',
        }),
      ),
    [careLevels, selectedOrg],
  );

  const renderModal: RenderFormModal<CareLevelFieldTypes> = useCallback(
    ({ defaultComponents, control, type, fields }) => (
      <>
        <OrgHeader
          displayName={selectedOrg?.displayName}
          id={selectedOrg?.id}
        />
        {defaultComponents.displayName}
        <SelectBuilding
          control={control}
          type={type}
          tooltip={fields.building.tooltip}
        />
        {defaultComponents.price}
        {defaultComponents.description}
      </>
    ),
    [selectedOrg, selectedOrg],
  );

  const onEditSubmit: OnSubmitFormModal<SaveCareLevelFieldTypes> = useCallback(
    async (item) => {
      if (isNil(selectedOrg)) return;

      try {
        const building = buildings?.find((b) => b.id === item.buildingId);

        const careLevel = {
          displayName: item.displayName,
          price: parseInt(item.price),
          description: item.description,
          building: building?.domainId,
        };

        await putCareLevel(item.id, careLevel);

        await queryClient.invalidateQueries({
          queryKey: getCareLevelsQueryOptions(selectedOrg.id).queryKey,
        });

        toast.success(`Successfully updated care level ${item.displayName}`);
      } catch {
        toast.error(`Error updating care level ${item.displayName}`);
      }
    },
    [selectedOrg],
  );

  const onAddSubmit: OnSubmitFormModal<SaveCareLevelFieldTypes> = useCallback(
    async (item) => {
      if (isNil(selectedOrg)) return;

      try {
        const building = buildings?.find((b) => b.id === item.buildingId);

        const careLevel = {
          displayName: item.displayName,
          price: parseInt(item.price),
          description: item.description || undefined,
          org: selectedOrg.id,
          building: building?.domainId,
        };

        await postCareLevel(careLevel);

        await queryClient.invalidateQueries({
          queryKey: getCareLevelsQueryOptions(selectedOrg.id).queryKey,
        });

        toast.success(`Successfully added care level ${item.displayName}`);
      } catch {
        toast.error(`Error adding care level ${item.displayName}`);
      }
    },
    [selectedOrg],
  );

  return (
    <Stack spacing={2}>
      <OrgSelector
        orgs={orgs ?? []}
        loading={orgsLoading}
        value={selectedOrg || null}
        onChange={handleOrgChange}
      />
      <TableBase<CareLevelFieldTypes>
        itemName='Care Level'
        fields={careLevelFields}
        data={data}
        loading={careLevelsFetching || buildingsLoading}
        renderModal={renderModal}
        error={careLevelsError}
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        customNoRowsText={
          isNil(selectedOrg) ? 'No organization selected' : undefined
        }
        disableAddButton={isNil(selectedOrg)}
      />
    </Stack>
  );
};

export default CareLevelsTable;
