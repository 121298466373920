export const doesRangeExceedLimitForImages = (
  start: Date | null,
  end: Date | null,
  limit = 1000 * 60 * 60 * 24, // 24 hours
) => {
  if (!start || !end) return false;

  const startTime = start.getTime();
  const endTime = end.getTime();
  return endTime - startTime > limit;
};
