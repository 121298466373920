import type React from 'react';
import { useEffect, useMemo } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { combinations } from 'combinatorial-generators';
import { isString, keyBy, mapValues } from 'lodash';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getDefaultComponentFromFieldData } from './helpers/getDefaultComponentFromFormFieldData';

import type { DataField, DataFields } from '../types/DataFields';
import type { Control, FieldValues, UseFormSetValue } from 'react-hook-form';

export type OnSubmitFormModal<FieldTypes> = (
  data: FieldTypes,
) => Promise<unknown>;

export type RenderFormModal<FieldTypes> = (formData: {
  control: Control;
  defaultComponents: Record<keyof FieldTypes, JSX.Element | null>;
  fields: Record<keyof FieldTypes, DataField<FieldTypes>>;
  type: 'add' | 'edit';
  setValue: UseFormSetValue<Partial<FieldTypes> & FieldValues>;
  initialValues?: FieldTypes;
}) => React.ReactNode;

interface Props<FieldTypes> {
  type: 'add' | 'edit';
  itemName: string;
  fields: DataFields<FieldTypes>;
  initialValues?: FieldTypes;
  open: boolean;
  loading?: boolean;
  error?: string | null | boolean;
  isSaveError?: boolean;
  handleClose: () => void;
  onSubmit: OnSubmitFormModal<FieldTypes>;
  renderModal?: RenderFormModal<FieldTypes>;
}

const FormModalBase = <FieldTypes,>({
  type,
  itemName,
  fields,
  initialValues,
  open,
  loading = false,
  error = null,
  isSaveError = false,
  handleClose,
  onSubmit,
  renderModal,
}: Props<FieldTypes>) => {
  // Consolidate schema in each field into one object
  const objectSchema = useMemo(
    () =>
      mapValues(keyBy(fields, 'field'), (field) => {
        if (type === 'add' && field.addSchema) return field.addSchema;
        if (type === 'edit' && field.editSchema) return field.editSchema;
        if (field.schema) return field.schema;
        return yup.mixed().nullable().notRequired();
      }),
    [fields, type],
  );

  const excludedFields = useMemo(() => {
    const excluded = fields
      .filter((field) => field.schemaExclude)
      .map((field) => field.field) as string[];

    return [...combinations(excluded, 2)] as Array<[string, string]>;
  }, [fields]);

  const resolver = yupResolver(
    yup.object().shape(objectSchema, [...excludedFields]),
  );

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isSubmitting, isDirty, defaultValues },
  } = useForm({
    // TODO: Fix type here. Related to TS issue with react-hook-form
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: initialValues as any,
    resolver,
  });

  useEffect(() => {
    if (!defaultValues && initialValues && !isDirty) {
      reset(initialValues);
    }
  }, [defaultValues, initialValues, isDirty]);

  const handleFormClose = () => {
    if (!isSubmitting) {
      reset();
      handleClose();
    }
  };

  const onSubmitForm: OnSubmitFormModal<FieldTypes> = async (data) => {
    await onSubmit(data);
    handleFormClose();
  };

  const modalContent = useMemo(() => {
    if (!renderModal) {
      return fields.map((field) =>
        getDefaultComponentFromFieldData(
          field,
          control,
          setValue,
          itemName,
          type,
        ),
      );
    }

    const keyByFields = keyBy(fields, 'field') as Record<
      keyof FieldTypes,
      DataField<FieldTypes>
    >;

    const defaultComponents = mapValues(keyByFields, (mapFields) =>
      getDefaultComponentFromFieldData(
        mapFields,
        control,
        setValue,
        itemName,
        type,
      ),
    );

    return renderModal({
      control,
      defaultComponents,
      fields: keyByFields,
      type,
      setValue,
      initialValues,
    });
  }, [fields, renderModal, control, type, setValue, itemName, initialValues]);

  const content = useMemo(() => {
    if (error) {
      const errorMessage = `Error ${isSaveError ? 'Saving' : 'Fetching'} data${isString(error) ? `: ${error}` : '.'}`;

      return (
        <>
          <DialogContent>
            <Alert severity='error'>{errorMessage}</Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </>
      );
    }

    if (loading) {
      return (
        <DialogContent sx={{ mx: 'auto', my: 6 }}>
          <CircularProgress />
        </DialogContent>
      );
    }

    return (
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>{modalContent}</DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <LoadingButton
            type='submit'
            variant='contained'
            sx={{ ml: 1 }}
            loading={isSubmitting}
          >
            {type === 'add' ? 'Add' : 'Save'}
          </LoadingButton>
        </DialogActions>
      </form>
    );
  }, [
    error,
    handleClose,
    loading,
    handleSubmit,
    modalContent,
    handleFormClose,
    isSubmitting,
    onSubmitForm,
  ]);

  return (
    <Dialog open={open} onClose={handleFormClose} fullWidth>
      <DialogTitle>
        {type === 'add' ? 'Add' : 'Edit'} {itemName}
      </DialogTitle>
      {content}
    </Dialog>
  );
};

export default FormModalBase;
