import type { OrgAuthSettings } from '@inspiren-monorepo/virtual-care/api-contracts';

export const getAuth0Domain = (authSettings?: OrgAuthSettings) => {
  if (authSettings?.auth0TenantUrl) return authSettings.auth0TenantUrl;

  switch (import.meta.env.VITE_API_ENV) {
    case 'production':
      return 'dev-ktu4wg0h.us.auth0.com';
    case 'stage':
      return 'inspiren-stage.us.auth0.com';
    default:
      return 'inspiren-dev.us.auth0.com';
  }
};

export const getAuth0ClientId = (authSettings?: OrgAuthSettings) => {
  if (authSettings?.auth0ClientId) return authSettings.auth0ClientId;

  switch (import.meta.env.VITE_API_ENV) {
    case 'production':
      return import.meta.env.VITE_VC_AUTH0_CLIENT_ID_PROD;
    case 'stage':
      return import.meta.env.VITE_VC_AUTH0_CLIENT_ID_STAGE;
    default:
      return import.meta.env.VITE_VC_AUTH0_CLIENT_ID_DEV;
  }
};

export const getAuth0Audience = (authSettings?: OrgAuthSettings) => {
  if (authSettings?.auth0TenantUrl && authSettings?.auth0ClientId) {
    return `https://${authSettings?.auth0TenantUrl}/api/v2/`;
  }

  return import.meta.env.VITE_API_ENV === 'production'
    ? 'https://dev-ktu4wg0h.us.auth0.com/api/v2/'
    : 'https://inspiren-api/';
};

export const getAuth0Connection = (authSettings?: OrgAuthSettings) => {
  // If tenant url and client id are provided by auth settings we do not need connection id
  if (authSettings?.auth0TenantUrl && authSettings?.auth0ClientId) {
    return undefined;
  }

  if (import.meta.env.VITE_API_ENV === 'stage') {
    switch (window.location.hostname) {
      case 'test-url.augiapp.com':
        return 'Anthem';
      case 'test-url-2.augiapp.com':
        return 'Arrow-OIDC';
      case 'test-url-3.augiapp.com':
        return 'Valley-OIDC';
      default:
        return 'Username-Password-Authentication';
    }
  }

  if (import.meta.env.VITE_API_ENV === 'production') {
    // TODO: Change to one of:
    // 1. Move to common-api service
    // 2. Load from env varible and build different bundle for each org
    // 3. Deprecate separate URLs and use IP address or org code
    switch (window.location.hostname) {
      case 'maplewoodseniorliving.augiapp.com':
        return 'Maplewood';
      case 'massgeneral.inspiren.com':
        return 'Test-DB';
      case 'valleyhealth.inspiren.com':
        return 'Username-Password-Authentication';
      case 'careone.inspiren.com':
        return 'CareOne';
      case 'anthemmemorycare.inspiren.com':
        return 'Anthem';
      case 'valleyhealth-ad.augiapp.com':
        return 'Valley-OIDC';
      case 'arrow-ad.augiapp.com':
        return 'Arrow-OIDC';
      case 'asl.inspiren.com':
        return 'Arrow';
      case 'countrymeadows.inspiren.com':
        return 'COUNTRYMEADOWS';
      case 'momentum.inspiren.com':
        return 'MOMENTUM';
      case 'provisionliving.inspiren.com':
        return 'PROVISION';
      case 'wellpointe.inspiren.com':
        return 'WELLPOINTE';
      case 'brightoncare.inspiren.com':
        return 'BRIGHTON';
      default:
        return 'Username-Password-Authentication';
    }
  }

  if (import.meta.env.VITE_API_ENV === 'this') {
    switch (window.location.hostname) {
      case 'telesitting-this-anthem.augiapp.com':
        return 'Anthem';
      default:
        return 'Username-Password-Authentication';
    }
  }

  return 'Username-Password-Authentication';
};
