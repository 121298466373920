import { Alert } from '@mui/material';
import { isAxiosError } from 'axios';

import {
  getUpstreamErrorMessage,
  isUpstreamError,
} from '@inspiren-monorepo/shared-types';

interface Props {
  error: unknown;
}

const PhoneNumberError = ({ error }: Props) => {
  let innerError = error;
  let message = 'Unknown error';

  if (isAxiosError(innerError)) {
    message = innerError.message ?? message;
    innerError = innerError.toJSON();
  }

  if (isUpstreamError(innerError)) {
    message = getUpstreamErrorMessage(innerError) ?? message;
  }

  return (
    <Alert severity='error' sx={{ mb: 2 }}>
      {message}
    </Alert>
  );
};

export default PhoneNumberError;
