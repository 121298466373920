import { useMemo } from 'react';
import { skipToken, useQuery } from '@tanstack/react-query';
import { intervalToDuration } from 'date-fns';

import { UserEventType } from '@inspiren-monorepo/shared-types';
import type { EventReviewUserEvents } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useOrganizationRolesMap } from '../../../hooks/useOrganizationRolesMap';
import { getUserEvents } from '../data-access/getUserEvents';
import { formatDurationConcise } from '../helpers/formatDurationConcise';
import { useEventReviewStore } from '../store/EventReviewStore';

export const useUserEvents = () => {
  const { selectedRoom, selectedOrg, startDate, endDate } =
    useEventReviewStore();

  const roomId = selectedRoom?.mainId;
  const { data: roleMap = {} } = useOrganizationRolesMap(selectedOrg?.id);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['eventReview', 'anyEvent', roomId, startDate, endDate],
    queryFn:
      roomId && startDate && endDate
        ? async () => {
            const res = await getUserEvents(
              roomId,
              startDate.toISOString(),
              endDate.toISOString(),
            );

            return res;
          }
        : skipToken,
  });

  const userEventsData = useMemo(() => {
    if (!data) return [];

    return data?.map((event: EventReviewUserEvents) => ({
      event: event.type,
      time: new Date(event.time),
      timeIn: event.time ? new Date(event.time) : null,
      timeOut: event.end ? new Date(event.end) : null,
      name:
        (event?.firstName && event?.lastName
          ? `${event.firstName} ${event.lastName}`
          : event?.user || '') +
        (roleMap[event.roleId || '']?.displayName
          ? ` - ${roleMap[event.roleId || ''].displayName}`
          : ''),
      duration:
        event.type === UserEventType.PrivacyModeOn && event.end
          ? formatDurationConcise(
              intervalToDuration({
                start: new Date(event.time),
                end: new Date(event.end),
              }),
            )
          : '',
      id: `${event.time}-${event.user}`,
    }));
  }, [data, roleMap]);

  return { data: userEventsData, isLoading, isError };
};
