import { isNil } from 'lodash';

import { getPositionFromDate } from '@inspiren-monorepo/shared-react/browser';

import type { EventImage } from '../../../types';

export const timeFormat = 'h:mm:ss aa';
export const dateTimeFormat = 'MM/dd/yyyy h:mm:ss aa';
export const dateTimeFormatNoYear = 'M/dd h:mm:ss aa';

export const getTimestampOfNearestImage = (
  images: EventImage[] | null,
  date: Date,
) => {
  if (isNil(images)) return null;

  const position = getPositionFromDate(images, date);
  if (position === null) return null;

  const timestamp = new Date(images[position].time);
  return timestamp;
};
