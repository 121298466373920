import type { RoleTemplateId } from '@inspiren-monorepo/feature-permissions';

import { Api } from '../../../utility/client';

import type { User } from '../../../../types';

export type RoomAssignment = {
  id: string;
  name: string;
};

export type AdminToolUser = Pick<
  User,
  | 'mainId'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'roleId'
  | 'unit'
  | 'levelAccess'
  | 'username'
  | 'beacon'
  | 'mobilePhone'
  | 'mobilePhoneVerified'
  | 'auth0Connection'
  | 'lastActivityAt'
> & {
  roleTemplate?: RoleTemplateId;
};

export interface LastKey {
  mainId: string;
  secondaryId: string;
  org: string;
  email: string;
}

export interface UsersTableQueryResponse {
  users: AdminToolUser[];
  lastKey: LastKey | undefined;
}

export async function getUsersTableQuery(org: string, lastKey?: LastKey) {
  return Api.get<UsersTableQueryResponse>('admin/v1/users/table', {
    params: {
      org,
      lastKey,
    },
  }).then((res) => res.data);
}
