import { config } from '@tamagui/config/v3';
import { createTamagui } from '@tamagui/core';

export const tamaguiConfig = createTamagui({
  ...config,
  settings: {
    ...config.settings,
    allowedStyleValues: 'strict',
  },
});

declare module 'tamagui' {
  type TamaguiCustomConfig = typeof tamaguiConfig;
}

export default tamaguiConfig;
