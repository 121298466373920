import { Box, Typography } from '@mui/material';
import { getYear } from 'date-fns';

const Footer = () => (
  <Box
    sx={{
      display: 'flex',
      minHeight: '70px',
      justifyContent: 'center',
      alignSelf: 'flex-end',
      width: '100%',
    }}
  >
    <Typography
      variant='body2'
      sx={{ textAlign: 'center', p: 4, backgroundColor: 'white' }}
    >
      Copyright Inspiren, Inc. {getYear(new Date())}
    </Typography>
  </Box>
);

export default Footer;
