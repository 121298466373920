import { useCallback } from 'react';
import { Link } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import * as yup from 'yup';

import { getOrgs } from '../../data-access/getOrgs';
import TableBase from '../TableBase';

import type { OnSubmitFormModal } from '../../modals/FormModalBase';
import type { DataFields } from '../../types/DataFields';

export type FieldTypes = {
  id: string;
  displayName: string;
  ipAllowList?: string[];
  roles?: string[];
  openSettingsEditModal: never;
};

const fields: DataFields<FieldTypes> = [
  {
    field: 'id',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    renderCell: (params) => (
      <Link href={`/admin/organizations/${params.value}`}>{params.value}</Link>
    ),
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(/^[A-Z]+$/, 'ID can only contain capital letters'),
  },
  {
    field: 'displayName',
    label: 'Display name',
    width: 250,
    editType: 'text',
    schema: yup.string().required('You must provide an organization name'),
  },
  {
    field: 'ipAllowList',
    label: 'IP allowlist',
    width: 300,
    flex: 1,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
    hideOnEdit: true,
    valueFormatter: (params) =>
      params.value?.length ? params.value.join(', ') : '',
  },
  {
    field: 'roles',
    label: 'Roles',
    width: 200,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
    hideOnEdit: true,
    flex: 2,
    valueFormatter: (params) => {
      const formattedValues = params.value
        ?.map((value: string) => value.charAt(0).toUpperCase() + value.slice(1))
        .sort();

      return formattedValues?.length ? formattedValues.join(', ') : '';
    },
  },
];

const OrganizationsTable = () => {
  const {
    isLoading: orgsLoading,
    data: orgs,
    isError: orgsError,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
    placeholderData: keepPreviousData,
  });

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    () => Promise.resolve({}),
    [],
  );

  return (
    <TableBase<FieldTypes>
      itemName='Organization'
      fields={fields}
      data={orgs}
      loading={orgsLoading}
      error={orgsError}
      onAddSubmit={handleAddSubmit}
      showAddButton={false}
      disableEditing
      getRowId={(row) => row.id}
    />
  );
};

export default OrganizationsTable;
