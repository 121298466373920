import { Box, Typography } from '@mui/material';

interface Props {
  status: string;
  size?: 'small' | 'large';
}

const StatusBox = ({ status, size }: Props) => (
  <Box
    sx={{
      backgroundColor: 'grey.500',
      borderRadius: 1,
      px: size === 'large' ? 3 : 1,
      py: size === 'large' ? 0.75 : 0.25,
      height: 'fit-content',
      textAlign: 'center',
    }}
  >
    <Typography variant='subtitle2'>{status}</Typography>
  </Box>
);

export default StatusBox;
