export type UpstreamErrorMeta = {
  message: string;
  errorCode?: string;
  extras?: Record<string, unknown>;
};

export type UpstreamErrorPayload = {
  error: UpstreamErrorMeta | string;
};

export const isUpstreamErrorMeta = (obj: unknown): obj is UpstreamErrorMeta => {
  if (typeof obj === 'object' && obj !== null) {
    const payload = obj as UpstreamErrorMeta;
    return 'message' in payload && typeof payload.message === 'string';
  }

  return false;
};

export const isUpstreamError = (obj: unknown): obj is UpstreamErrorPayload => {
  if (typeof obj === 'object' && obj !== null) {
    const payload = obj as UpstreamErrorPayload;
    return (
      'error' in payload &&
      (typeof payload.error === 'string' || isUpstreamErrorMeta(payload.error))
    );
  }

  return false;
};

export function getUpstreamErrorMessage(
  payload: UpstreamErrorPayload,
): string | undefined {
  if (typeof payload.error === 'string') {
    return payload.error;
  }

  if (isUpstreamErrorMeta(payload.error)) {
    return payload.error.message;
  }

  return undefined;
}
