import { Api } from '../../../utility/client';

import type { User } from '../../../../types';

export const putUser = async (
  user: { userId: string } & Partial<Omit<User, 'mainId'>>,
) => {
  const res = await Api.put<User>('/admin/v1/users', user);
  return res.data;
};
