import { IconButton } from '@mui/material';

interface Props {
  onClick: () => void;
  icon: React.ReactNode;
}

export const ArrowIconButton = ({ onClick, icon }: Props) => (
  <IconButton
    sx={{
      backgroundColor: 'grey.800',
      opacity: 0.8,
      color: '#fff',
      ':hover': { backgroundColor: 'grey.900' },
    }}
    onClick={onClick}
  >
    {icon}
  </IconButton>
);
