import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';

import { HEADER_HEIGHT } from '../../RoomModal/constants';

const MODAL_PADDING = 36;
const ASPECT_RATIO = 953 / 720;
const MAX_HEIGHT = 900;

export const useRoomImageSize = (bottomMenuHeight = 0) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const maxWidth = useMemo(
    () => windowWidth - MODAL_PADDING * 2,
    [windowWidth],
  );

  const maxHeight = useMemo(
    () =>
      Math.min(
        MAX_HEIGHT,
        windowHeight - HEADER_HEIGHT - bottomMenuHeight - MODAL_PADDING * 2,
      ),
    [windowHeight, bottomMenuHeight],
  );

  const width = useMemo(
    () => Math.min(maxWidth, maxHeight * ASPECT_RATIO),
    [maxWidth, maxHeight],
  );

  const height = useMemo(() => width / ASPECT_RATIO, [width]);

  return { width, height };
};
