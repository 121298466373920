import { Api } from '../../../utility/client';

interface DemoState {
  started: boolean;
  startedAt?: string;
}

export async function getDemoState() {
  return Api.get<DemoState>('/v1/vbase/demo/state').then((res) => res.data);
}
