import { Api } from '../../../utility/client';

export async function sendEarlyWarningAlert(roomId: string) {
  return Api.post<Response>(
    `/telesitting/v1/events/rooms/${roomId}/generalEvents`,
    {
      type: 'early_warning',
    },
  );
}
