import type { ToggleButtonProps } from '@mui/material';
import {
  CircularProgress,
  ToggleButton,
  styled,
  toggleButtonClasses,
} from '@mui/material';

import { FALL_RISK_OPTIONS } from '@inspiren-monorepo/shared-types';

import type { FallRiskLevel } from '../../../../../../types';

const StyledToggleButton = styled(ToggleButton)(({ theme, value }) => {
  const levelColors = theme.palette[`${value as FallRiskLevel}FallRisk`];

  return {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[800],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },

    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: levelColors.main,
      color: levelColors.contrastText,
      '&:hover': {
        backgroundColor: levelColors.dark,
      },
    },

    [`&.${toggleButtonClasses.disabled}`]: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[500],
      borderWidth: 0,
    },
  };
});

interface Props extends Omit<ToggleButtonProps, 'value'> {
  fallRiskLevel: FallRiskLevel;
  loading?: boolean;
}

const FallRiskToggleButton = (props: Props) => {
  const { fallRiskLevel, loading = false, ...toggleButtonProps } = props;

  return (
    <StyledToggleButton
      value={fallRiskLevel}
      disabled={loading || toggleButtonProps.disabled}
      {...toggleButtonProps}
    >
      {loading ? (
        <CircularProgress size={18} color='inherit' />
      ) : (
        FALL_RISK_OPTIONS[fallRiskLevel].label
      )}
    </StyledToggleButton>
  );
};

export default FallRiskToggleButton;
