import { useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import {
  PhoneNumberFormat,
  formatPhoneNumberSafe,
} from '@inspiren-monorepo/util-formatters';

import { comparePhoneNumberSafe } from '../helpers/comparePhoneNumberSafe';

import type { MutationFunction, QueryKey } from '@tanstack/react-query';

interface ChangePhoneNumberForm {
  mobilePhoneNumber: string;
}

interface Params {
  confirmedMobilePhone?: string;
  onConfirmedMobilePhoneChange: (value: string | null) => void;
  updatePhoneNumberMutationFn: MutationFunction<
    { mobilePhone?: string },
    string | null
  >;
  userQueryKey: QueryKey;
  confirmClear: (clearPhoneNumber: () => void) => void;
}

export const useChangeStage = ({
  confirmedMobilePhone,
  onConfirmedMobilePhoneChange,
  updatePhoneNumberMutationFn: mutationFn,
  userQueryKey,
  confirmClear,
}: Params) => {
  const queryClient = useQueryClient();

  const {
    mutate: updateMobilePhone,
    isPending,
    isError,
    error: updateMutationError,
  } = useMutation({
    mutationFn,
    onSuccess: (data) => {
      onConfirmedMobilePhoneChange(data.mobilePhone || null);
      queryClient.invalidateQueries({ queryKey: userQueryKey });
    },
  });

  const { handleSubmit, control, watch, setValue } =
    useForm<ChangePhoneNumberForm>({
      defaultValues: { mobilePhoneNumber: confirmedMobilePhone },
    });

  const mobilePhoneNumber = watch('mobilePhoneNumber');

  const isTypedNumberAlreadyConfirmed =
    confirmedMobilePhone &&
    comparePhoneNumberSafe(mobilePhoneNumber, confirmedMobilePhone);

  const upsertButtonText = useMemo(() => {
    if (!confirmedMobilePhone) return 'Set phone number';
    if (!mobilePhoneNumber) return 'Clear phone number';
    if (isTypedNumberAlreadyConfirmed) return 'Confirm phone number';
    return 'Update phone number';
  }, [confirmedMobilePhone, mobilePhoneNumber, isTypedNumberAlreadyConfirmed]);

  const changeMobilePhone = (mobilePhone: string | null) => {
    if (
      mobilePhone &&
      confirmedMobilePhone &&
      comparePhoneNumberSafe(mobilePhone, confirmedMobilePhone)
    ) {
      onConfirmedMobilePhoneChange(confirmedMobilePhone);
      return;
    }

    updateMobilePhone(
      mobilePhone
        ? formatPhoneNumberSafe(mobilePhone, PhoneNumberFormat.INTERNATIONAL)
        : null,
    );
  };

  const onUpsertMobilePhone = (formFields: ChangePhoneNumberForm) => {
    const mobilePhone = formFields.mobilePhoneNumber;

    if (confirmedMobilePhone && !mobilePhone) {
      confirmClear(() => changeMobilePhone(null));
    } else {
      changeMobilePhone(mobilePhone);
    }
  };

  const clearPhoneNumberField = () => {
    setValue('mobilePhoneNumber', '');
  };

  return {
    clearPhoneNumberField,
    upsertButtonText,
    isPending,
    isError,
    updateMutationError,
    control,
    mobilePhoneNumber,
    handleSetPhoneNumber: handleSubmit(onUpsertMobilePhone),
  };
};
