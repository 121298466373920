import { useQuery } from '@tanstack/react-query';

import type { Beacon } from '@inspiren-monorepo/virtual-care/api-contracts';

import TableBase from '../../../tables/TableBase';
import { formatNoValue } from '../../helpers/formatNoValue';
import { formatUnassigned } from '../../helpers/formatUnassigned';
import { noValueClassName } from '../../helpers/noValueClassName';
import { getBeaconHistory } from '../data-access/getBeaconHistory';

import type { DataFields } from '../../../types/DataFields';

type Props = {
  beacon: Beacon;
};

export type FieldTypes = {
  version: number;
  id: string;
  assignedTo?: string;
  email?: string;
  firstName?: string;
  lastSeen?: string;
  latest: number;
  rm?: string;
  org: string;
};

const fields: DataFields<FieldTypes> = [
  {
    field: 'version',
    label: 'Version',
    editType: 'text',
    width: 80,
    editable: false,
  },
  {
    field: 'assignedTo',
    label: 'Assigned To',
    width: 300,
    editType: 'text',
    valueFormatter: formatUnassigned,
    cellClassName: noValueClassName,
  },
  {
    field: 'firstName',
    label: 'First Name',
    width: 150,
    editType: 'text',
    valueFormatter: formatUnassigned,
    cellClassName: noValueClassName,
  },
  {
    field: 'lastSeen',
    label: 'Last Seen At',
    width: 200,
    editType: 'text',
    valueFormatter: formatNoValue,
    cellClassName: noValueClassName,
  },
  {
    field: 'rm',
    label: 'Last Seen In Room',
    width: 300,
    editType: 'text',
    valueFormatter: formatNoValue,
    cellClassName: noValueClassName,
  },
];

export const BeaconHistoryTable = ({ beacon }: Props) => {
  const { id } = beacon;

  const {
    isLoading: loading,
    data: history,
    isError,
  } = useQuery({
    queryKey: ['beaconHistory', id],
    queryFn: () => getBeaconHistory(id),
  });

  return (
    <TableBase
      itemName='Beacon'
      loading={loading}
      error={isError}
      fields={fields}
      data={history}
      getRowId={(row) => row.version}
      onAddSubmit={() => Promise.resolve()}
      disableEditing
      showAddButton={false}
    />
  );
};
