import type { PropsWithChildren } from 'react';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

export const PageContainer = ({
  children,
  sx,
}: PropsWithChildren<BoxProps>) => (
  <Box
    sx={{
      display: 'flex',
      height: 'calc(100% - 64px)',
      position: 'relative',
      ...sx,
    }}
  >
    {children}
  </Box>
);
