import { useQuery } from '@tanstack/react-query';

import { getTableauToken } from '../data-access/getTableauToken';

export const useTableauToken = () =>
  useQuery({
    queryKey: ['tableau-token'],
    queryFn: getTableauToken,
    refetchInterval: 5 * 60 * 1000,
  });
