import { sortBy, sortedUniqBy } from 'lodash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { WasabiImage } from '@inspiren-monorepo/shared-react/universal';

interface State {
  isActive: boolean;
  isPlaying: boolean;
  progress: number;
  images: WasabiImage[];
  imagesLoading: boolean;
}

interface Actions {
  toggleActive: () => void;
  togglePlaying: () => void;
  setProgress: (progress: number) => void;
  incrementProgress: () => void;
  resetProgress: () => void;
  addImage: (image: WasabiImage) => void;
  setImagesLoading: (loading: boolean) => void;
  reset: () => void;
}

const initialState: State = {
  isActive: false,
  isPlaying: false,
  progress: 0,
  images: [],
  imagesLoading: false,
};

export const useActiveAlertPlaybackStore = create<State & Actions>()(
  devtools((set) => ({
    ...initialState,
    toggleActive: () => set((state) => ({ isActive: !state.isActive })),
    togglePlaying: () => set((state) => ({ isPlaying: !state.isPlaying })),
    setProgress: (progress) => set(() => ({ progress })),
    incrementProgress: () => set((state) => ({ progress: state.progress + 1 })),
    resetProgress: () => set({ progress: 0 }),
    addImage: (image) =>
      set((state) => ({
        images: sortedUniqBy(sortBy([...state.images, image], 'time'), 'time'),
      })),
    setImagesLoading: (loading) => set({ imagesLoading: loading }),
    reset: () => set(initialState),
  })),
);
