import { format } from 'date-fns-tz';
import { camelCase, mapKeys, startCase, uniq } from 'lodash';

import type { MarkData, RoleMap, Room, User, UserRole } from '../../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const keysToCamelCase = <T>(obj: Record<string, any>): T =>
  mapKeys(obj, (_, key) => camelCase(key)) as T;

export const formatRole = (role: UserRole) => {
  if (['RN', 'PCA', 'CNA', 'BA', 'CSS', 'RSA', 'RCA', 'LPN'].includes(role))
    return role;
  return startCase(role);
};

export const formatUserLabelWithRole = (user: User, roleMap?: RoleMap) =>
  `${user.firstName} ${user.lastName} (${
    roleMap?.[user.roleId]?.displayName || formatRole(user.role!)
  })`;

export const formatUserRole = (
  roleId?: string,
  roleMap?: RoleMap,
  role?: UserRole,
) => {
  if (roleId) return roleMap?.[roleId]?.displayName || formatRole(role!);
  return '';
};

export const formatByResolvedByNameAndRoleId = (
  roleMap: RoleMap,
  name?: string,
  roleId?: string,
) => {
  if (roleId && !roleMap[roleId]?.displayName) return 'System';
  if (name === 'expired') return 'Expired';
  return name && roleId ? `${name} - ${roleMap[roleId].displayName}` : name;
};

export const makeEventReviewFilename = (
  type: string,
  room?: Room,
  start?: Date,
  end?: Date,
) => {
  if (!room || !start || !end) return type;

  const timeFormat = 'MM/dd/yyyy_hh:mm:ssaa_zzz';
  return `${type}_${room?.unit?.substring(5)}-Room${room?.name}_${format(
    start,
    timeFormat,
  )}-${format(end, timeFormat)}`;
};

export const collapseCrowdedMarks = (
  marks: MarkData[],
  spacePerMark: number | null,
  spaceTreshold = 10,
) => {
  const sectionedMarks: Array<MarkData | MarkData[]> = [];
  let currentSection: MarkData[] = [];

  marks.forEach((thisMark, idx, arr) => {
    const nextMark = arr[idx + 1];

    const diff = nextMark ? nextMark.value - thisMark.value : 0;

    if (nextMark && spacePerMark && diff * spacePerMark < spaceTreshold) {
      // Not enough space between this mark and next mark
      currentSection.push(thisMark);
    } else if (currentSection.length > 0) {
      // Enough space; this will be the final mark of the current section
      currentSection.push(thisMark);
      sectionedMarks.push(currentSection);
      currentSection = [];
    } else {
      // Enough space and no current section, solitary mark
      sectionedMarks.push(thisMark);
    }
  });

  const finalMarks = sectionedMarks.map<MarkData>((mark) => {
    if (!Array.isArray(mark)) return mark;

    const markTypes = uniq(mark.map((m) => m.type));

    // if more than one mark type in section, type is 'hybrid'
    const type = markTypes.length > 1 ? 'hybrid' : markTypes[0];

    const label =
      mark.length > 4
        ? `${mark.length} entries collapsed`
        : mark.map((m) => m.label as string);

    return {
      label,
      value: mark[0].value,
      span: mark.at(-1)!.value - mark[0].value,
      time: mark[0].time,
      type,
    };
  });

  return finalMarks;
};

export const extractBuildingFloorFromDomainId = (domainId: string) => {
  const [, building, floor] = domainId.split('-');

  return `${building}-${floor}`;
};
