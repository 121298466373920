import * as yup from 'yup';

import type { DataFields } from '../../../types/DataFields';
import type { CareLevelFieldTypes } from '../types/CareLevelFieldTypes';

export const careLevelFields: DataFields<CareLevelFieldTypes> = [
  {
    field: 'id',
    label: 'ID',
    width: 300,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'displayName',
    label: 'Display name',
    width: 200,
    editType: 'text',
    initialValue: '',
    schema: yup.string().required('Display name is required'),
  },
  {
    field: 'building',
    label: 'Building',
    width: 200,
    editType: 'special',
    initialValue: '',
    tooltip:
      'The building this care level is associated with. If omitted, the care level will be available in all buildings in the organization.',
  },
  {
    field: 'price',
    label: 'Price',
    width: 120,
    editType: 'text',
    initialValue: '',
    schema: yup
      .number()
      .typeError('Price must be a number')
      .required('Price is required')
      .integer('Price must be a whole number'),
    tooltip: 'The price of the care level in $/month.',
  },
  {
    field: 'description',
    label: 'Description',
    width: 300,
    editType: 'text',
    initialValue: '',
  },
];
