import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getAllUnits } from '../data-access/getAllUnits';

// TODO: Get only single unit
export const useUnitForRoom = (roomId: string) => {
  const { data: units } = useQuery({
    queryKey: ['getAllUnits'],
    queryFn: () => getAllUnits(),
  });

  const unit = useMemo(() => {
    if (!units) return undefined;
    const [, org, building, floorNr, unitName] = roomId.split('-');
    const unitDomainId = `${org}-${building}-${floorNr}-${unitName}`;
    return units?.find((u) => `${u.domainId}` === unitDomainId);
  }, [roomId, units]);

  return unit;
};
