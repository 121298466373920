import { Button, type ButtonProps } from 'tamagui';

import { Icon } from '../../../components/Icon';
import { useActiveAlertPlaybackStore } from '../hooks/useActiveAlertPlaybackStore';

export const ActiveAlertPlaybackButton = (
  props: Omit<ButtonProps, 'onPress'>,
) => {
  const toggleActive = useActiveAlertPlaybackStore(
    (state) => state.toggleActive,
  );

  return (
    <Button {...props} onPress={toggleActive} height='$3' width='$3'>
      <Button.Icon>
        <Icon.History size={24} color='black' />
      </Button.Icon>
    </Button>
  );
};
