import { useEffect, useState } from 'react';

import { useUserOrg } from '../../../hooks/useUserOrg';
import { keysToCamelCase } from '../../../utility/helpers/helpers';
import { unsubscribeToAudibleMessages } from '../../../utility/mqtt';
import {
  registerMessageHandler,
  subscribeToTopic,
} from '../../../utility/mqtt/mqttClient';
import { generateAudibleMessagesStatusTopic } from '../../../utility/mqtt/topicHelpers';

import type { AudibleMessageResponse } from '../../../../types';

export const useAudibleMessagesStatus = () => {
  const [playingAudibleMessages, setPlayingAudibleMessages] = useState<
    Record<string, boolean>
  >({});

  const org = useUserOrg();

  useEffect(() => {
    if (org) {
      try {
        const topic = generateAudibleMessagesStatusTopic(org);

        registerMessageHandler(topic, {
          id: `audible-messages-status-${org}`,
          call: (type, messageBaseId, payload) => {
            if (type === 'sound_resp') {
              const { status } = keysToCamelCase<AudibleMessageResponse>(
                JSON.parse(new TextDecoder().decode(payload)),
              );

              setPlayingAudibleMessages((prevState) => ({
                ...prevState,
                [messageBaseId]: status === 'begin_ok',
              }));
            }
          },
        });

        subscribeToTopic(topic);
      } catch (error) {
        console.error('Error while subscribing to audible messages', error);
      }

      return () => {
        unsubscribeToAudibleMessages(org).catch((error) => {
          console.error('Error while unsubscribing to audible messages', error);
        });
      };
    }

    return () => true;
  }, [org]);

  return { playingAudibleMessages };
};
