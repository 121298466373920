import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getUnits } from '../../data-access/getUnits';
import { ControlledAutocomplete } from '../ControlledAutocomplete';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  org?: string;
}

const SelectBuildingFloorUnit = ({ control, org }: Props) => {
  const { isLoading: unitsLoading, data: units } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const items = useMemo(
    () =>
      units && org
        ? (units || [])
            .filter((unit) => unit.domainId.includes(`${org}-`))
            .map((unit) => unit.domainId.replace(`${org}-`, ''))
            .sort()
        : [],
    [units, org],
  );

  return (
    <ControlledAutocomplete
      id='buildingFloorUnit'
      label='Building-Floor-Unit'
      items={items}
      loading={unitsLoading}
      control={control}
    />
  );
};

export default SelectBuildingFloorUnit;
