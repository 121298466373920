import type { WasabiImage } from '@inspiren-monorepo/shared-react/universal';

import { Api } from '../../../utility/client';

import type { ViewMode } from '../../../../types';

export interface LiveImagesData {
  images: WasabiImage[];
  roomId: string;
}

interface Params {
  roomId: string;
  duration?: number;
  startTime?: string;
  viewMode?: ViewMode;
}

export const getRoomImages = async ({
  roomId,
  duration = 60,
  startTime,
  viewMode = 'monitor',
}: Params): Promise<LiveImagesData> => {
  try {
    const res = await Api.get<LiveImagesData>(`/v1/images/${roomId}`, {
      params: {
        duration,
        start: startTime,
        clear: viewMode === 'telesitter',
      },
    });

    return res?.data;
  } catch (error) {
    console.error('error requesting room live image');
    throw error;
  }
};
