interface Params {
  org: string;
  unit: string;
  room: string;
  start: Date;
  end: Date;
}

export const generateEventReviewUrl = ({
  org,
  unit,
  room,
  start,
  end,
}: Params) => {
  const url = new URL('/event-review', window.location.origin);
  url.searchParams.append('org', org);
  url.searchParams.append('unit', unit);
  url.searchParams.append('room', room);
  url.searchParams.append('start', start.toISOString());
  url.searchParams.append('end', end.toISOString());
  return url.toString();
};
