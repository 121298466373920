import { Toolbar } from '@mui/material';
import { Helmet } from 'react-helmet';

import { TableauAnalytics } from '../components/Analytics/components/TableauAnalytics';
import { PageContainer } from '../components/shared/PageContainer';

const Analytics = () => (
  <>
    <Helmet>
      <script
        type='module'
        src='https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js'
      />
    </Helmet>
    <Toolbar />
    <PageContainer sx={{ flexDirection: 'column' }}>
      <TableauAnalytics />
    </PageContainer>
  </>
);

export default Analytics;
