import { useCallback } from 'react';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';

import { putRoom, postRoom } from '../../../../../utility';
import { makeRoomId, makeUnit } from '../../../../../utility/helpers/id';
import { updateRoomsQueryData } from '../../../../../utility/helpers/updateRoomsQueryData';

import type {
  AdminToolRoom,
  RoomsTableQueryResponse,
} from '../../../data-access/getRoomsByOrg';
import type { OnSubmitFormModal } from '../../../modals/FormModalBase';
import type { RoomFieldTypes } from '../types/RoomFieldTypes';

const useRoomsUpsert = () => {
  const queryClient = useQueryClient();

  const handleEditSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async ({
      id: roomNumber,
      organization,
      buildingFloorUnit,
      fallRiskLevel,
      careLevel,
      displayName,
      designation,
      hide,
      deleted,
    }) => {
      const data = {
        mainId: makeRoomId(organization, buildingFloorUnit, roomNumber)!,
        fallRiskLevel: fallRiskLevel === 'high' ? 'medium' : fallRiskLevel,
        careLevelId: careLevel || undefined,
        name: displayName,
        hide,
        deleted,
        designation,
      };

      await putRoom(data);

      updateRoomsQueryData(queryClient, organization, data);
    },
    [],
  );

  const handleAddSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async ({
      id,
      organization,
      buildingFloorUnit,
      fallRiskLevel,
      careLevel,
      displayName,
      designation,
      hide,
      deleted = false,
    }) => {
      const data = {
        id,
        unit: makeUnit(organization, buildingFloorUnit)!,
        fallRiskLevel: fallRiskLevel === 'high' ? 'medium' : fallRiskLevel,
        careLevelId: careLevel || undefined,
        name: displayName || undefined,
        hide,
        deleted,
        designation,
      };

      await postRoom(data);

      queryClient.setQueryData(
        ['rooms', organization, true],
        (oldData: InfiniteData<RoomsTableQueryResponse>) => {
          if (!oldData) {
            // Handle the case where oldData is undefined
            // For example, you might want to return an empty array
            return {
              pages: [],
              pageParams: [],
            };
          }

          const room: AdminToolRoom = {
            mainId: makeRoomId(organization, buildingFloorUnit, id)!,
            fallRiskLevel,
            careLevelId: careLevel || undefined,
            name: displayName || id,
            hide,
            deleted,
            unit: makeUnit(organization, buildingFloorUnit)!,
            designation,
          };

          const firstPage = { ...oldData.pages[0] };
          firstPage.rooms = [room, ...firstPage.rooms];

          return {
            ...oldData,
            pages: [firstPage, ...oldData.pages.slice(1, oldData.pages.length)],
          };
        },
      );
    },
    [],
  );

  return {
    handleEditSubmit,
    handleAddSubmit,
  };
};

export default useRoomsUpsert;
