import type * as yup from 'yup';

const applyRequirementConstraint = (
  schema: yup.Schema,
  shouldBeRequired: boolean,
  requiredMessage?: string,
): yup.Schema =>
  shouldBeRequired ? schema.required(requiredMessage) : schema.notRequired();

export default { applyRequirementConstraint };
