import { Alert, Grow } from '@mui/material';
import { Toaster, resolveValue } from 'react-hot-toast';

const AlertSnackbar = () => (
  <Toaster position='top-center'>
    {(toast) => {
      const severity = toast.type === 'error' ? 'error' : 'success';
      return (
        <Grow in timeout={400}>
          <Alert severity={severity} sx={{ boxShadow: 6, fontWeight: 500 }}>
            {resolveValue(toast.message, toast)}
          </Alert>
        </Grow>
      );
    }}
  </Toaster>
);

export default AlertSnackbar;
