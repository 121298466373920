import type React from 'react';
import { Box, Tooltip } from '@mui/material';

interface Props {
  children: React.ReactNode;
  tooltipText?: string;
}

const DisabledTimeLink = ({ children, tooltipText = '' }: Props) => (
  <Tooltip title={tooltipText}>
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          fontWeight: 500,
          color: 'grey.700',
          userSelect: 'none',
          textAlign: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  </Tooltip>
);

export default DisabledTimeLink;
