import { useMemo } from 'react';
import { Box } from '@mui/material';
import { format } from 'date-fns';

import EventsTable from './EventsTable';
import NotificationsTable from './NotificationsTable';

import { useCurrentUser } from '../../../HOC/CurrentUserContextProvider';
import { useEventReviewStore } from '../store/EventReviewStore';

const DetailsPanel = () => {
  const { startDate, endDate } = useEventReviewStore();
  const { user } = useCurrentUser();

  const showDate = useMemo(
    () =>
      (startDate && format(startDate, 'MM/dd/yyyy')) !==
      (endDate && format(endDate, 'MM/dd/yyyy')),
    [startDate, endDate],
  );

  const showRowMenu = useMemo(
    () => user?.ability?.can('view', 'virtual-care.event-review.spotlight'),
    [user],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          minHeight: '50%',
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        data-testid='eventreview-eventstable'
      >
        <EventsTable showDate={showDate} showRowMenu={showRowMenu || false} />
      </Box>
      <Box
        sx={{ minHeight: '50%' }}
        data-testid='eventreview-notificationstable'
      >
        <NotificationsTable
          showDate={showDate}
          showRowMenu={showRowMenu || false}
        />
      </Box>
    </Box>
  );
};

export default DetailsPanel;
