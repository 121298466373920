import { PhoneNumberVerificationStage } from '../types/PhoneNumberVerificationStage';

export const getInitialVerificationStage = (
  initialPhoneNumber?: string | null,
  initialIsPhoneNumberVerified?: boolean,
): PhoneNumberVerificationStage => {
  if (!initialPhoneNumber) {
    return PhoneNumberVerificationStage.CHANGE;
  }

  if (!initialIsPhoneNumberVerified) {
    return PhoneNumberVerificationStage.NOT_VERIFIED;
  }

  return PhoneNumberVerificationStage.VERIFIED;
};
