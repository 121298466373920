import type { AlertType } from '@inspiren-monorepo/shared-types';
import {
  BATHROOM_OCCUPIED_ALERT,
  EARLY_WARNING_ALERT,
  FALL_ALERT,
  HIDDEN_ALERT,
  MOVING_IN_BED_ALERT,
  OUT_OF_BED_ALERT,
  OUT_OF_CHAIR_ALERT,
  URGENT_ALERT,
} from '@inspiren-monorepo/shared-types';

export function formatAlertType(
  type: AlertType | null | undefined | '',
  bathroomAlertThreshold?: number | null,
): string {
  switch (type) {
    case FALL_ALERT:
      return 'Lying on Floor';
    case OUT_OF_BED_ALERT:
      return 'Leaving Bed';
    case OUT_OF_CHAIR_ALERT:
      return 'Out of Chair';
    case MOVING_IN_BED_ALERT:
      return 'Motion in Bed';
    case HIDDEN_ALERT:
      return 'Hidden';
    case URGENT_ALERT:
      return 'Urgent';
    case EARLY_WARNING_ALERT:
      return 'Early Warning';
    case BATHROOM_OCCUPIED_ALERT:
      return bathroomAlertThreshold
        ? `${bathroomAlertThreshold} min Bathroom Check`
        : 'Bathroom Check';
    default:
      return '';
  }
}
