import { useQuery } from '@tanstack/react-query';

import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { getUnits } from '../data-access/getUnits';

import type { UseQueryOptions } from '@tanstack/react-query';

type Props = Omit<
  UseQueryOptions<AdminTypes.UnitDto[], Error, AdminTypes.UnitDto[], string[]>,
  'queryKey' | 'queryFn'
>;

export const useUnits = (props: Props = {}) =>
  useQuery({
    ...props,
    queryKey: ['units'],
    queryFn: getUnits,
  });
