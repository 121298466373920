import { differenceInSeconds } from 'date-fns';

/**
 * @param start date
 * @param end date
 * @returns number of seconds between the two dates
 */
export function getSecondsBetweenDates(start: Date, end: Date) {
  return differenceInSeconds(end, start);
}
