import { Api } from '../../../utility/client';

import type { User } from '../../../../types';

export interface LastKey {
  mainId: string;
  secondaryId: string;
  org: string;
  email: string;
}

interface Response {
  users: User[];
  lastKey: LastKey | undefined;
}

export const getOrgUsers = async (lastKey?: LastKey): Promise<Response> => {
  try {
    const res = await Api.get<Response>('/v1/users/telesitting/allUsers', {
      params: {
        lastKey,
      },
    });

    return res?.data;
  } catch (error) {
    console.error('error requesting all units');
    throw error;
  }
};
