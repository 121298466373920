import { Api } from '../../../../../utility/client';

export type UserAssignment = {
  roomId: string;
  roomName: string;
  unitId: string;
  unitName: string;
};

export async function getUserAssignments(userId: string) {
  const response = await Api.get<UserAssignment[]>(
    `/admin/v1/users/${userId}/assignments`,
  );

  return response.data;
}
