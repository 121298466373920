import { isAxiosError } from 'axios';

import {
  isUpstreamError,
  isUpstreamErrorMeta,
} from '@inspiren-monorepo/shared-types';

export const getAxiosErrorMessage = (err: unknown): string | null => {
  if (!isAxiosError(err)) {
    return null;
  }

  const responseData = err.response?.data;

  if (!responseData) {
    return null;
  }

  if (typeof responseData === 'string') {
    return responseData;
  }

  if (!isUpstreamError(responseData)) {
    return null;
  }

  return isUpstreamErrorMeta(responseData.error)
    ? responseData.error.message
    : responseData.error;
};
