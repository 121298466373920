import { useMemo } from 'react';
import { LinearProgress, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import type { GridColDef } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';

import { useSplitPane } from '../hooks/useSplitPane';

interface DataGridBaseProps {
  noRowsLabel: string;
  // TODO: Define row type as generic type
  rows: any[];
  columns: GridColDef[];
  loading: boolean;
  error?: boolean;
  getRowClassName?: (params: any) => string;
  backgroundColor: string;
}

export const DataGridBase = ({
  noRowsLabel,
  rows,
  columns,
  loading,
  error = false,
  getRowClassName,
  backgroundColor,
}: DataGridBaseProps) => {
  const splitPane = useSplitPane();

  const StyledDataGrid = useMemo(
    () =>
      styled(DataGrid)(() => ({
        '& .row-active--true': {
          backgroundColor,
        },
        maxHeight: 'calc(100% - 50px)',

        // TODO: This code is broken. We should remove it becuase
        // even if we fix it, it would only work on webkit browsers (Safari)
        // and almost none of our users use Safari

        // Make the scrollbar always visible if there is overflow
        // (only works on webkit browsers)
        // macOS auto-hides scrollbars when not scrolling
        '::-webkit-scrollbar': {
          WebkitAppearance: 'none',
          width: '13px',
          height: '13px',
        },
        '::-webkit-scrollbar-thumb': {
          borderRadius: '12px',
          border: '3px solid white',
          backgroundColor: grey[400],
          transition: 'all 0.5s',
        },
        '::-webkit-scrollbar-thumb:hover': {
          border: '2px solid white',
          backgroundColor: grey[500],
        },
        // Offset so rows aren't hidden by header on autoscroll
        scrollPaddingTop: '50px',
        border: 'none',
      })),
    [],
  );

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      loading={loading}
      error={error || undefined}
      getRowId={(row) => row.id}
      localeText={{ noRowsLabel }}
      getRowClassName={getRowClassName}
      hideFooter
      hideFooterPagination
      hideFooterSelectedRowCount
      components={{ LoadingOverlay: LinearProgress }}
      autoHeight={!splitPane}
    />
  );
};
