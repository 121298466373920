import type {
  GridColDef,
  GridPreProcessEditCellProps,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { isEmpty } from 'lodash';
import * as yup from 'yup';

import { buildRenderEditCell } from './buildRenderEditCell';

import type {
  Importable,
  ImportableDataField,
  OnImportCellEditValidation,
} from '../types/importable';

const getError = <TFieldTypes>(
  editCellProps: GridPreProcessEditCellProps,
  field: keyof TFieldTypes,
  rows: Importable<TFieldTypes>[],
  validators?: OnImportCellEditValidation<TFieldTypes>[],
) => {
  if (!validators?.length) {
    return false;
  }

  const {
    id,
    otherFieldsProps,
    props: { value },
  } = editCellProps;

  const fields: TFieldTypes = Object.entries(otherFieldsProps || {}).reduce(
    (acc, [key, prop]) => ({
      ...acc,
      [key]: prop?.value,
    }),
    { [field]: value } as TFieldTypes,
  );

  for (const validator of validators) {
    const error = validator(id, fields, field, rows);

    if (error) {
      return error;
    }
  }

  return false;
};

const getErrorMessage = (error: unknown): string[] | string => {
  if (error instanceof yup.ValidationError && !isEmpty(error.errors)) {
    return error.errors;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Unknown error';
};

export const buildGridColumn = <TFieldTypes extends GridValidRowModel>(
  dataField: ImportableDataField<TFieldTypes>,
  rows: Importable<TFieldTypes>[],
): GridColDef<Importable<TFieldTypes>> => {
  const {
    field,
    label,
    width,
    flex,
    valueFormatter,
    cellClassName,
    schema,
    addSchema,
    editSchema,
    importSchema,
    editType,
    importType,
    options,
    renderImportCell,
    onImportCellEditValidators,
    disabledOnImport,
    importLabel,
  } = dataField;

  return {
    field,
    headerName: importLabel || label,
    width: width as number,
    flex: flex ?? 1,
    valueFormatter,
    cellClassName,
    type: editType,
    editable: true,
    renderEditCell: buildRenderEditCell(
      importType ?? editType!,
      renderImportCell,
      disabledOnImport,
    ),
    preProcessEditCellProps: (
      params: GridPreProcessEditCellProps<any, Importable<TFieldTypes>>,
    ) => {
      const validationSchema =
        importSchema ??
        (params.row.$meta.alreadyExists ? editSchema : addSchema) ??
        schema;

      let error: string[] | string | false = getError(
        params,
        field,
        rows,
        onImportCellEditValidators,
      );

      if (!error && validationSchema) {
        try {
          // some schemas relate on other fields, so we need to build the validation for whole object
          const fullSchema = yup.object({
            [field]: validationSchema,
          });

          const item = {
            ...Object.fromEntries(
              Object.entries(params.otherFieldsProps ?? {}).map(
                ([key, { value }]) => [key, value],
              ),
            ),
            [field]: params.props.value,
          };

          fullSchema.validateSync(item, { abortEarly: false });
        } catch (validationError) {
          error = getErrorMessage(validationError);
        }
      }

      return {
        value: params.props.value,
        error,
      };
    },
    valueOptions: options,
  };
};
