import { useCallback, useMemo } from 'react';

import { useTrainingVideos } from '../../../hooks/useTrainingVideo';
import { ControlledAutocomplete } from '../../../modals/ControlledAutocomplete';

import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  id: string;
  label: string;
}

const SelectTrainingVideo = ({ control, id, label }: Props) => {
  const { data, isLoading } = useTrainingVideos();

  const urls = useMemo(
    () => data?.videos.map(({ url }) => url) || [],
    [data?.videos],
  );

  const getOptionLabel = useCallback(
    (option: string) =>
      data?.videos.find(({ url }) => url === option)?.path || '',
    [data?.videos],
  );

  return (
    <ControlledAutocomplete
      loading={isLoading}
      control={control}
      id={id}
      label={label}
      items={urls}
      getOptionLabel={getOptionLabel}
    />
  );
};

export default SelectTrainingVideo;
