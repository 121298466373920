import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { getOrgAuthSettings } from '../data-access/getOrgAuthSettings';

export function useOrgAuthSettings(domain: string) {
  const { data, isLoading, error } = useQuery({
    queryKey: ['org-auth-settings', domain],

    queryFn: async () => {
      if (isNil(domain)) return {};

      return getOrgAuthSettings(domain);
    },
  });

  return useMemo(
    () => ({
      authSettings: data,
      loading: isLoading,
      error,
    }),
    [data, isLoading, error],
  );
}
