import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import type { RoleMap } from '../../../types';

export const createRoleMap = (roles?: AdminTypes.Role[]): RoleMap => {
  if (!roles?.length) {
    return {};
  }

  return Object.fromEntries(
    roles
      .filter((e) => !!e.id)
      .map((cur) => [
        cur.id,
        {
          id: cur.id,
          templateId: cur.templateId,
          displayName: cur.displayName,
        },
      ]),
  );
};
