import { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { isAxiosError } from 'axios';
import { useConfirm } from 'material-ui-confirm';
import toast from 'react-hot-toast';

import { sendAmpEvent } from '../../../../../utility/amplitude';
import { updateDisabledStatus } from '../data-access/updateDisabledStatus';

interface Props {
  roomId: string;
  augiDisabled: boolean;
}

const DisableAugiButton = ({ roomId, augiDisabled }: Props) => {
  const confirm = useConfirm();

  const [updating, setUpdating] = useState(false);

  const handleUpdateDisabledStatus = useCallback(async () => {
    try {
      await confirm({
        title: 'Caution',
        description: augiDisabled
          ? 'Are you sure you want to re-enable this AUGi?'
          : 'Disabling AUGi will stop Live View and all patient notifications. Are you sure you want to continue?',
        confirmationText: augiDisabled ? 'Re-enable AUGi' : 'Disable AUGi',
        confirmationButtonProps: {
          color: augiDisabled ? 'primary' : 'disableAugi',
        },
      });

      setUpdating(true);

      const action = augiDisabled ? 'enable' : 'disable';

      await updateDisabledStatus(roomId, action);

      sendAmpEvent('Disable AUGi', { roomId, action });
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(`Could not ${augiDisabled ? 're-enable' : 'disable'} AUGi`);
      }
    } finally {
      setUpdating(false);
    }
  }, [augiDisabled]);

  return (
    <LoadingButton
      loading={updating}
      size='medium'
      variant='contained'
      color={augiDisabled ? 'primary' : 'disableAugi'}
      onClick={handleUpdateDisabledStatus}
      fullWidth
    >
      {augiDisabled ? 'Re-enable AUGi' : 'Disable AUGi'}
    </LoadingButton>
  );
};

export default DisableAugiButton;
