import { Stack } from '@mui/material';
import * as yup from 'yup';

import {
  isValidFallRiskLevel,
  isValidRoomDesignation,
  ROOM_DESIGNATION_OPTIONS,
} from '@inspiren-monorepo/shared-types';

import FallRiskPill from '../../../../shared/FallRiskPill';
import InvalidValue from '../../../InvalidValue';
import { RoomFallRiskHistoryModalButton } from '../../../components/RoomFallRiskHistoryModalButton';
import ImportOrgSelect from '../../ImportTable/components/ImportOrgSelect';
import ImportUnitSelect from '../../ImportTable/components/ImportUnitSelect';
import importUniqueRoomValidator from '../validators/importUniqueRoomValidator';

import type { ImportableDataFields } from '../../ImportTable/types/importable';
import type { TableView } from '../RoomsTable';
import type { RoomFieldTypes } from '../types/RoomFieldTypes';

const getRoomsTableFields = (
  filteredOrgs?: string[],
  tableView: TableView = 'Default View',
): ImportableDataFields<RoomFieldTypes> => [
  {
    field: 'id',
    label: 'ID',
    width: 100,
    editType: 'text',
    editable: false,
    initialValue: '',
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\da-z]+$/i,
        'ID can only include letter and number characters',
      ),
    disabledOnImport: (params) => Boolean(params.row.$meta.alreadyExists),
    onImportCellEditValidators: [
      importUniqueRoomValidator('Room already exists'),
    ],
  },
  {
    field: 'displayName',
    label: 'Display name',
    width: 160,
    editType: 'special',
    initialValue: '',
    schema: yup.string().notRequired(),
  },
  {
    field: 'organization',
    label: 'Organization',
    width: 'hidden',
    hideOnAdd: true,
    hideOnEdit: true,
    importType: 'select',
    renderImportCell: (params) => (
      <ImportOrgSelect {...params} filteredOrgs={filteredOrgs} />
    ),
    disabledOnImport: (params) => Boolean(params.row.$meta.alreadyExists),
  },

  {
    field: 'buildingFloorUnit',
    label: 'Building-Floor-Unit',
    width: 190,
    editType: 'special',
    initialValue: '',
    schema: yup.string().required('Building, floor, and unit are required'),
    importType: 'select',
    renderImportCell: (params) => <ImportUnitSelect {...params} />,
    disabledOnImport: (params) => Boolean(params.row.$meta.alreadyExists),
  },
  {
    field: 'fallRiskLevel',
    label: 'Fall Risk Level',
    width: tableView === 'Site Config View' ? 'hidden' : 160,
    editType: 'special',
    initialValue: 'high',
    schema: yup.string().required('Fall risk level is required'),
    renderCell: ({ id, value }) => (
      <Stack
        sx={{ width: '100%' }}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        {isValidFallRiskLevel(value) ? (
          <FallRiskPill fallRisk={value} size='small' />
        ) : (
          <InvalidValue value={value} />
        )}
        <RoomFallRiskHistoryModalButton roomId={id as string} />
      </Stack>
    ),
    importType: 'select',
    options: ['high', 'low', 'off'],
  },
  {
    field: 'careLevel',
    label: 'Care Level',
    width: tableView === 'Site Config View' ? 'hidden' : 150,
    editType: 'special',
  },
  {
    field: 'designation',
    label: 'Designation',
    width: tableView === 'Site Config View' ? 'hidden' : 150,
    editType: 'special',
    renderCell: ({ value }) => {
      if (isValidRoomDesignation(value)) {
        return ROOM_DESIGNATION_OPTIONS[value].label;
      }
    },
  },
  {
    field: 'baseID',
    label: 'Basestation ID',
    width: tableView !== 'Site Config View' ? 'hidden' : 150,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'hide',
    label: 'Hidden',
    width: tableView === 'Site Config View' ? 'hidden' : 100,
    editType: 'boolean',
    initialValue: false,
    tooltip: 'Hidden rooms will not be visible in mobile or web applications',
    flex: 0,
  },
  {
    field: 'deleted',
    label: 'Archived',
    width: tableView === 'Site Config View' ? 'hidden' : 100,
    editType: 'boolean',
    initialValue: false,
    tooltip:
      'Archived rooms will not be visible in any application - mobile, web, or annotation',
    flex: 0,
  },
  {
    field: 'never_event_period',
    label: 'Never Event Period',
    width: tableView !== 'Site Config View' ? 'hidden' : 150,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'room_state_llm_period',
    label: 'LLM Room State Period',
    width: tableView !== 'Site Config View' ? 'hidden' : 200,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'respect_llm_room_state',
    label: 'LLM Room State Enabled',
    width: tableView !== 'Site Config View' ? 'hidden' : 200,
    editType: 'boolean',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'llm_night',
    label: 'LLM Room State Enabled At Night',
    width: tableView !== 'Site Config View' ? 'hidden' : 225,
    editType: 'boolean',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'pose_model_path',
    label: 'Pose Model Version',
    width: tableView !== 'Site Config View' ? 'hidden' : 400,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'model_path',
    label: 'OD Model Version',
    width: tableView !== 'Site Config View' ? 'hidden' : 300,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'nightModeEnabled',
    label: 'Night Mode Enabled',
    width: tableView !== 'Site Config View' ? 'hidden' : 200,
    editType: 'boolean',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'skeletons_at_night',
    label: 'Enable Skeletons At Night',
    width: tableView !== 'Site Config View' ? 'hidden' : 200,
    editType: 'boolean',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'clear_data_site',
    label: 'Clear Data Enabled',
    width: tableView !== 'Site Config View' ? 'hidden' : 200,
    editType: 'boolean',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'clear_upload_every_n_seconds',
    label: 'Clear Data Upload',
    width: tableView !== 'Site Config View' ? 'hidden' : 200,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'kernel',
    label: 'Kernel',
    width: tableView !== 'Site Config View' ? 'hidden' : 200,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
  {
    field: 'sigma',
    label: 'Sigma',
    width: tableView !== 'Site Config View' ? 'hidden' : 200,
    editType: 'text',
    editable: false,
    hideOnAdd: true,
  },
];

export default getRoomsTableFields;
