import { useEffect, useState } from 'react';
import { Image } from 'expo-image';
import { useAsyncEffect } from 'ahooks';
import { pick } from 'lodash';
import { useShallow } from 'zustand/react/shallow';

import { useActiveAlertPlaybackStore } from './useActiveAlertPlaybackStore';
import { type RoomImagesQueryFunction, useRoomImages } from './useRoomImages';

import type { QueryKey } from '@tanstack/react-query';

interface Params {
  roomImagesQueryKey: QueryKey;
  roomImagesQueryFn: RoomImagesQueryFunction;
  roomId: string;
  startTime?: Date;
  durationInSeconds: number;
}

export const usePreloadedRoomImages = ({
  roomImagesQueryKey,
  roomImagesQueryFn,
  roomId,
  startTime,
  durationInSeconds,
}: Params) => {
  const { addImage, setImagesLoading, isActive } = useActiveAlertPlaybackStore(
    useShallow((state) =>
      pick(state, ['addImage', 'setImagesLoading', 'isActive']),
    ),
  );

  const {
    data: imagesData,
    isLoading,
    dataUpdatedAt,
  } = useRoomImages({
    queryKey: roomImagesQueryKey,
    queryFn: roomImagesQueryFn,
    roomId,
    startTime: startTime?.toISOString(),
    duration: durationInSeconds,
    enabled: isActive,
  });

  const imagesToLoad = imagesData?.images ?? [];

  const [isPrefetching, setIsPrefetching] = useState(false);

  useAsyncEffect(async () => {
    setIsPrefetching(true);

    const promises = imagesToLoad.map((image) =>
      Image.prefetch(image.url).then((success) => {
        if (success) addImage(image);
      }),
    );

    await Promise.allSettled(promises);
    setIsPrefetching(false);
  }, [dataUpdatedAt]);

  useEffect(() => {
    setImagesLoading(isLoading || isPrefetching);
  }, [isLoading, isPrefetching]);
};
