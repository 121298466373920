import type { SelectChangeEvent } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material';
import { isNil } from 'lodash';
import { Controller } from 'react-hook-form';

import type { DataFieldOption } from '../types/DataFields';
import type { Control } from 'react-hook-form';

export type Item = string | DataFieldOption | { groupLabel: string };

function isGroupLabel(item: Item): item is { groupLabel: string } {
  return !isNil((item as { groupLabel: string }).groupLabel);
}

type Props = {
  id: string;
  label: string;
  items: Item[];
  control: Control;
  disabled?: boolean;
  required?: boolean;
  onChange?: (event: SelectChangeEvent) => void;
  defaultValue?: string;
};

const ControlledDropdown = ({
  id,
  label,
  items,
  control,
  disabled = false,
  required = false,
  onChange,
  defaultValue = '',
}: Props) => {
  const labelId = `${id}-label`;

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { name, value, onChange: onControllerChange },
        fieldState: { error },
      }) => {
        const handleChange = (event: SelectChangeEvent) => {
          onControllerChange(event);
          if (onChange) onChange(event);
        };

        return (
          <FormControl margin='dense' fullWidth>
            <InputLabel id={labelId} error={Boolean(error)}>
              {label}
            </InputLabel>
            <Select
              labelId={labelId}
              id={name}
              value={value}
              label={label}
              onChange={handleChange}
              disabled={disabled}
              required={required}
              error={Boolean(error)}
              defaultValue={defaultValue}
            >
              {items.map((item) => {
                if (isGroupLabel(item)) {
                  return (
                    <ListSubheader key={item.groupLabel}>
                      {item.groupLabel}
                    </ListSubheader>
                  );
                }

                if (typeof item === 'string') {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                }

                return (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText error={Boolean(error)}>
              {error?.message}
            </FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default ControlledDropdown;
