import type { MutableRefObject } from 'react';
import { useMemo } from 'react';
import { Slider, lighten } from '@mui/material';
import { format } from 'date-fns';

import type { WasabiImage } from '@inspiren-monorepo/shared-react/universal';
import type { MarkData } from '@inspiren-monorepo/shared-types';

import SliderMark from './SliderMark';

type Props = {
  disabled: boolean;
  marks: MarkData[] | null;
  maxPosition: number | null;
  railRef: MutableRefObject<HTMLSpanElement | null>;
  spacePerMark: number | null;
  timestamp: Date | null;
  images: WasabiImage[] | null;
  imagesPosition: number;
  onImagePositionChange: (newValue: number | 'inc' | 'dec') => void;
};

const ProgressBar = ({
  disabled,
  marks,
  maxPosition,
  railRef,
  spacePerMark,
  timestamp,
  images,
  imagesPosition,
  onImagePositionChange,
}: Props) => {
  const timeString = timestamp
    ? format(timestamp, 'MM/dd/yyyy h:mm:ss aa')
    : '';

  const bufferPercent = useMemo(() => {
    const bufferPosition = images?.findIndex((img) => !img.url) ?? 0;

    return maxPosition && bufferPosition >= 0
      ? `${(bufferPosition / maxPosition) * 100}%`
      : '100%';
  }, [images, maxPosition]);

  return (
    <Slider
      data-testid='eventreview-imageplayer-progressbar'
      min={0}
      max={maxPosition || 1}
      value={imagesPosition}
      marks={marks || []}
      onChange={(_e, newValue) => onImagePositionChange(+newValue)}
      valueLabelDisplay={timestamp ? 'on' : 'off'}
      valueLabelFormat={() => timeString}
      getAriaValueText={() => timeString}
      disabled={disabled}
      track={bufferPercent === '0%' || disabled ? false : 'normal'}
      components={{
        Mark: SliderMark,
        MarkLabel: () => null,
      }}
      componentsProps={{
        track: {
          style: {
            width: bufferPercent,
            transition: 'width 2s cubic-bezier(0.18, 0.84, 0, 0.96)',
          },
        },
        rail: { ref: railRef },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mark: { spacePerMark } as any,
      }}
      sx={{
        '& .MuiSlider-rail': {
          color: 'grey.400',
        },
        '& .MuiSlider-track': {
          color: (theme) => lighten(theme.palette.primary.light, 0.5),
        },
        '& .MuiSlider-valueLabel': {
          color: '#000',
          backgroundColor: '#fff',
          boxShadow: (theme) => theme.shadows[4],
        },
      }}
    />
  );
};

export default ProgressBar;
