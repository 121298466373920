import { Api } from '../../../utility/client';

export async function getTableauViewPreviewImage(
  workbookId: string,
  viewId: string,
) {
  const res = await Api.get(
    `/telesitting/v1/tableau/views/${workbookId}/${viewId}/preview`,
  );

  return res.data;
}
