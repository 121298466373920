import { useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { Api } from '../utility/client';

import type { User } from '../../types';

export const useUser = (email: string | undefined) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['user', email],

    queryFn: async () => {
      if (isNil(email)) return null;

      const url = `/v1/users/${encodeURIComponent(email)}`;
      const res = await Api.get<User>(url);

      return res?.data;
    },
  });

  return { user: data, loading: isLoading, error };
};
