import { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';

import DownloadModal from './DownloadModal';

import { sendAmpEvent } from '../../../utility/amplitude';

interface Props {
  disableVideoExport: boolean;
}

const ImageMenu = ({ disableVideoExport = false }: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  return (
    <>
      <DownloadModal
        open={downloadModalOpen}
        onClose={() => setDownloadModalOpen(false)}
      />

      <Tooltip
        title={
          disableVideoExport
            ? 'Time frame must be 1 hour or less to export video'
            : 'Export Video'
        }
      >
        <IconButton
          onClick={(e) => setMenuAnchorEl(e.currentTarget)}
          id='image-menu-button'
          aria-controls={menuOpen ? 'images-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={menuOpen ? 'true' : undefined}
          sx={{
            position: 'absolute',
            right: 2,
            top: 6,
            height: 32,
            width: 32,
            color: '#fff',
          }}
          size='large'
        >
          <MoreVert fontSize='medium' color='inherit' />
        </IconButton>
      </Tooltip>

      <Menu
        id='images-menu'
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'image-menu-button',
        }}
      >
        <MenuItem
          disabled={disableVideoExport}
          onClick={() => {
            setDownloadModalOpen(true);
            setMenuAnchorEl(null);
            sendAmpEvent('MP4 Downloaded');
          }}
        >
          <ListItemIcon>
            <DownloadIcon fontSize='small' />
          </ListItemIcon>

          <ListItemText>Export as MP4</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ImageMenu;
