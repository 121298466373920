import { Box, Typography } from '@mui/material';

interface Props {
  displayName?: string;
  id?: string;
}

const OrgHeader = ({ displayName = '', id = '' }: Props) => {
  if (!displayName && !id) return null;

  const text = displayName && id ? `${displayName} (${id})` : displayName || id;

  return (
    <Typography mb={2}>
      Organization:{' '}
      <Box fontWeight={600} component='span'>
        {text}
      </Box>
    </Typography>
  );
};

export default OrgHeader;
