import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const putRole = async (role: AdminTypes.EditRoleDto) => {
  const res = await Api.put(`/admin/v1/roles/${role.id}`, {
    ...role,
  });

  return res.data;
};
