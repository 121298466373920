import { skipToken, useQuery } from '@tanstack/react-query';

import { getRoomFallRiskChanges } from '../data-access/getRoomFallRiskChanges';

export const useRoomFallRiskChanges = (roomId: string) => {
  const { isLoading, data, error } = useQuery({
    queryKey: ['fall-risk-changes', roomId],
    queryFn: roomId ? () => getRoomFallRiskChanges(roomId) : skipToken,
  });

  return { loading: isLoading, events: data || [], error };
};
