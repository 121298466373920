import { Tooltip, Typography } from '@mui/material';

interface Props {
  value?: string;
}

const InvalidValue = ({ value }: Props) => (
  <Tooltip title='Invalid value'>
    <Typography variant='body2' fontWeight={800} color='red'>
      {value}
    </Typography>
  </Tooltip>
);

export default InvalidValue;
