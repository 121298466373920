import { skipToken, useQuery } from '@tanstack/react-query';

import { getBasestationHistory } from '../data-access/getBasestationHistory';

interface Params {
  basestationId?: string;
  enabled?: boolean;
}

export const useBasestationHistory = ({
  basestationId,
  enabled = true,
}: Params) =>
  useQuery({
    queryKey: ['basestation', basestationId, 'history'],
    queryFn:
      basestationId && enabled
        ? async () => getBasestationHistory(basestationId)
        : skipToken,
  });
