import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getUnits } from '../../../data-access/getUnits';

interface Props {
  unitId: string;
}

const UnitDisplayName = ({ unitId }: Props) => {
  // TODO: How to prevent from getting all units?
  const { data, isError } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  if (isError) {
    return (
      <Typography variant='body2' color='error'>
        Error loading unit
      </Typography>
    );
  }

  if (!data) return null;

  const unitObj = data.find((unit) => `Room-${unit.domainId}` === unitId);

  if (!unitObj) return null;

  return (
    <Typography variant='body2'>
      {unitObj?.address}{' '}
      {unitObj?.displayName || unitObj?.domainId.split('-').pop()}
    </Typography>
  );
};

export default UnitDisplayName;
