import { useQuery } from '@tanstack/react-query';

import { getRoomsSiteConfigs } from '../../../data-access/getRoomsSiteConfigs';

export const useRoomsSiteConfigs = (structureId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['roomsSiteConfigs', structureId],
    queryFn: () => getRoomsSiteConfigs(structureId),
    enabled: !!structureId,
  });

  return { siteConfigs: data, siteConfigsIsLoading: isLoading };
};
