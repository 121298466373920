import React from 'react';
import { Link } from 'react-router-dom';

import type { LinkProps } from 'react-router-dom';

// Wires up react-router `Link` component to MUI `Link` and `Button` components

const LinkBehavior = React.forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  Omit<LinkProps, 'to'> & { href: LinkProps['to'] }
>(({ href, ...props }, ref) => <Link ref={ref} to={href} {...props} />);

export default LinkBehavior;
