import { useQuery } from '@tanstack/react-query';

import { getOrgs } from '../../data-access/getOrgs';
import ControlledDropdown from '../ControlledDropdown';

import type { Control } from 'react-hook-form';

type Props = {
  control: Control;
  type: 'add' | 'edit';
};

const SelectOrg = ({ control, type }: Props) => {
  const { isLoading: orgsLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  return (
    <ControlledDropdown
      id='orgId'
      label='Organization'
      items={(orgs || []).map((org) => org.id).sort()}
      control={control}
      disabled={type === 'edit' || orgsLoading}
    />
  );
};

export default SelectOrg;
