export const colors = {
  regionChair: {
    stroke: 'rgba(255, 255, 0)',
    fill: 'rgba(255, 255, 0, .25)',
  },
  regionBed: {
    stroke: 'rgba(0, 0, 255)',
    fill: 'rgba(0, 0, 255, .25)',
  },
  regionBedEdge: {
    stroke: 'rgba(128, 0, 128)',
    fill: 'rgba(128,0,128, 0.25)',
  },
  regionExit: {
    stroke: 'rgba(203, 39, 51)',
    fill: 'rgba(203, 39, 51, 0.25)',
  },
  regionIgnore: {
    stroke: 'rgba(46, 51, 55)',
    fill: 'rgba(255,255,255, 0.25)',
  },
};
