import type { Beacon } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export async function getBeacons(org: string | undefined) {
  const res = await Api.get<Beacon[]>('/admin/v1/beacons', {
    params: {
      org: org || 'unassigned',
    },
  });

  return res.data;
}
