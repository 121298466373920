import { isUsernameOrg } from './isUsernameOrg';

export const getUsernameFromEmail = (
  email?: string,
  org?: string,
): string | null => {
  if (!email || !org || !isUsernameOrg(org)) return null;

  // Split email at @ and +
  const emailParts = email.split(/[+@]/);

  if (
    emailParts.length === 3 &&
    emailParts[2] === 'inspiren.com' &&
    emailParts[0] === org?.toLowerCase()
  ) {
    return emailParts[1];
  }

  return null;
};
