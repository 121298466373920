import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { keyBy, sortBy } from 'lodash';

import { getBuildings } from '../../data-access/getBuildings';
import { ControlledAutocomplete } from '../ControlledAutocomplete';

import type { Control } from 'react-hook-form';

type Props = {
  control: Control;
  type: 'add' | 'edit';
  tooltip?: string;
};

const SelectBuilding = ({ control, type, tooltip }: Props) => {
  const { isLoading: buildingsLoading, data: buildings } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  const buildingById = useMemo(() => keyBy(buildings, 'id'), [buildings]);

  return (
    <ControlledAutocomplete
      id='buildingId'
      label='Building'
      items={sortBy(buildings || [], ['displayName', 'domainId']).map(
        ({ id }) => id,
      )}
      getOptionLabel={(id) =>
        buildingById[id]?.displayName || buildingById[id]?.domainId
      }
      control={control}
      disabled={type === 'edit' || buildingsLoading}
      tooltip={tooltip}
    />
  );
};

export default SelectBuilding;
