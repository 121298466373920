import { Box, Tooltip, Typography, Zoom } from '@mui/material';
import type { SliderMarkSlotProps } from '@mui/base';

// import { MarkData } from '../../../../types';
// import theme from '../../../styles/theme';
// import { sendAmpEvent } from '../../../utility/amplitude';

export interface SliderMarkProps extends SliderMarkSlotProps {
  spacePerMark: number | null;
}

const SliderMark = ({
  ownerState,
  spacePerMark,
  ...props
}: SliderMarkProps) => {
  const markIndex = props['data-index'];
  const marks = ownerState.marks as any[];
  const mark = marks[markIndex];

  //   const palette: { [key in MarkData['type']]: string } = {
  //     'staff-event': theme.palette.staffEvent.main,
  //     notification: theme.palette.notificationEvent.main,
  //     userEvent: theme.palette.userEvent.main,
  //     offlineEvent: theme.palette.userEvent.main,
  //     hybrid: theme.palette.hybridEvent.main,
  //   };

  const getStyle = () => {
    if (mark.span && spacePerMark) {
      const spanWidth = mark.span * spacePerMark;
      const exactWidth = spanWidth > 5 ? spanWidth : 5;
      const displayWidth = exactWidth + 4;

      return {
        width: `${displayWidth}px`,
        borderRadius: '2px',
        ml: '-1px',
        '&:hover': {
          width: `${displayWidth + 4}px`,
          height: '16px',
          borderRadius: '3px',
          ml: '-3px',
          opacity: 0.8,
          transition: 'all 0.2s',
        },
      };
    }

    return {
      width: '3px',
      borderRadius: '1px',
      ml: '-0.5px',
      '&:hover': {
        width: '6px',
        height: '20px',
        borderRadius: '2px',
        ml: '-2px',
        opacity: 0.8,
        transition: 'all 0.2s',
      },
    };
  };

  return (
    <Tooltip
      title={
        Array.isArray(mark.label) ? (
          <>
            {mark.label.map((m: any, idx: any) => (
              <Typography
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                // fontSize={theme.typography.pxToRem(11)}
                // fontWeight={theme.typography.fontWeightMedium}
                textAlign='center'
              >
                {m}
              </Typography>
            ))}
          </>
        ) : (
          mark.label
        )
      }
      enterDelay={0}
      arrow
      TransitionComponent={Zoom}
      //   onOpen={() => sendAmpEvent('User Viewed Tooltip', { param: mark.label })}
    >
      <Box
        {...props}
        component='span'
        sx={(theme) => ({
          position: 'absolute',
          height: '10px',
          background: theme.palette.primary.main,
          opacity: 0.7,
          top: '50%',
          transform: 'translate(-1px, -50%)',
          ...getStyle(),
        })}
      />
    </Tooltip>
  );
};

export default SliderMark;
