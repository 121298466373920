import { queryOptions, skipToken, useQuery } from '@tanstack/react-query';

import { getCareLevels } from '../../../data-access/getCareLevels';

export const getCareLevelsQueryOptions = (org?: string, building?: string) =>
  queryOptions({
    queryKey: ['careLevels', org, building],
    queryFn: org ? async () => getCareLevels(org, building) : skipToken,
  });

export const useCareLevels = (org?: string, building?: string) =>
  useQuery(getCareLevelsQueryOptions(org, building));
