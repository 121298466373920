import { useMemo, useRef } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { ImageNotSupported as NoImageIcon } from '@mui/icons-material';

import { RoomViewStatus } from './RoomImage/RoomViewStatus';
import useFormattedTimeDifference from './hooks/useFormattedTimeDifference';

import { useZoom } from '../../utility/hooks';

interface Props {
  onClick?: () => void;
  src?: string;
  width?: number | string;
  height?: number | string;
  enableZoom?: boolean;
  sx?: SxProps<Theme>;
  privacy?: boolean;
  privEndsAt?: string;
  disabled?: boolean;
  loading?: boolean;
  offline?: boolean;
  testID?: string;
  keepAlive?: string;
  noImagesAvailable?: boolean;
}

const RoomImage = ({
  onClick,
  src,
  width,
  height,
  enableZoom = false,
  sx = {},
  privacy = false,
  privEndsAt,
  disabled = false,
  loading = false,
  offline = false,
  testID,
  keepAlive,
  noImagesAvailable = false,
}: Props) => {
  const theme = useTheme();

  const zoomHTMLElement = useRef<null | HTMLImageElement>(null);
  useZoom(enableZoom, zoomHTMLElement);

  const formattedOfflineTime = useFormattedTimeDifference(
    keepAlive || '',
    offline,
    true,
  );

  const formattedPrivTime = useFormattedTimeDifference(
    privEndsAt || '',
    privacy,
    false,
  );

  const content = useMemo(() => {
    if (disabled) {
      return (
        <RoomViewStatus
          backgroundColor={theme.palette.disableAugi.main}
          color={theme.palette.disableAugi.contrastText}
        >
          <Typography fontWeight='500'>AUGi Disabled</Typography>
          {offline && formattedOfflineTime && (
            <Typography variant='body2'>{`Offline for ${formattedOfflineTime}`}</Typography>
          )}
        </RoomViewStatus>
      );
    }

    if (offline) {
      return (
        <RoomViewStatus>
          <NoImageIcon fontSize='large' />
          <Typography fontWeight='500'>Offline</Typography>
          {formattedOfflineTime && (
            <Typography variant='body2'>{`for ${formattedOfflineTime}`}</Typography>
          )}
        </RoomViewStatus>
      );
    }

    if (privacy) {
      return (
        <RoomViewStatus
          background='linear-gradient(180deg, rgba(71,109,195,1) 0%, rgba(172,193,253,1) 100%)'
          color='white'
        >
          <Typography fontWeight='500'>Virtual Curtain</Typography>
          <Typography variant='body2'>{formattedPrivTime}</Typography>
        </RoomViewStatus>
      );
    }

    if (loading) {
      return <Skeleton variant='rectangular' width={width} height={height} />;
    }

    if (noImagesAvailable) {
      return (
        <RoomViewStatus>
          <NoImageIcon fontSize='large' />
          <Typography fontWeight='500'>No images available</Typography>
        </RoomViewStatus>
      );
    }

    if (src) {
      return (
        <img
          src={src}
          ref={zoomHTMLElement}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          width={width}
          height={height}
          alt=''
        />
      );
    }

    return (
      <RoomViewStatus>
        <NoImageIcon fontSize='large' />
      </RoomViewStatus>
    );
  }, [
    disabled,
    offline,
    privacy,
    loading,
    noImagesAvailable,
    src,
    width,
    height,
    formattedPrivTime,
    formattedOfflineTime,
    theme,
  ]);

  return (
    <Box
      sx={{ ...sx, width, height }}
      onClick={onClick}
      data-testid={testID}
      data-test-component='RoomImage'
    >
      {content}
    </Box>
  );
};

export default RoomImage;
