import { useEffect } from 'react';
import { pick } from 'lodash';
import { useInterval } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { useActiveAlertPlaybackStore } from './useActiveAlertPlaybackStore';

const PLAYBACK_SPEED_MS = 1000;

export const usePlayback = (imageCount: number) => {
  const {
    progress,
    incrementProgress,
    resetProgress,
    isPlaying,
    togglePlaying,
  } = useActiveAlertPlaybackStore(
    useShallow((state) =>
      pick(state, [
        'progress',
        'incrementProgress',
        'resetProgress',
        'isPlaying',
        'togglePlaying',
      ]),
    ),
  );

  const atEnd = progress >= imageCount - 1;

  useInterval(
    () => (atEnd ? resetProgress() : incrementProgress()),
    isPlaying ? PLAYBACK_SPEED_MS : null,
  );

  useEffect(() => {
    if (isPlaying && atEnd) togglePlaying();
  }, [atEnd]);
};
