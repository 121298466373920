export const FALL_ALERT = 'fall';
export const OUT_OF_BED_ALERT = 'out_of_bed';
export const OUT_OF_CHAIR_ALERT = 'out_of_chair';
export const MOVING_IN_BED_ALERT = 'moving_in_bed';
export const HIDDEN_ALERT = 'hidden';
export const URGENT_ALERT = 'urgent';
export const EARLY_WARNING_ALERT = 'early_warning';
export const BATHROOM_OCCUPIED_ALERT = 'bathroom_occupied';

export const ALL_ALERTS = [
  FALL_ALERT,
  OUT_OF_BED_ALERT,
  OUT_OF_CHAIR_ALERT,
  MOVING_IN_BED_ALERT,
  HIDDEN_ALERT,
  URGENT_ALERT,
  EARLY_WARNING_ALERT,
  BATHROOM_OCCUPIED_ALERT,
] as const;

export type AlertType = (typeof ALL_ALERTS)[number];
