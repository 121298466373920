import { type QueryKey, useQuery } from '@tanstack/react-query';

import type { WasabiImage } from '@inspiren-monorepo/shared-react/universal';

interface RoomImagesQueryParams {
  roomId: string;
  duration?: number;
  startTime?: string;
  enabled?: boolean;
}

interface RoomImagesQueryResponse {
  images: WasabiImage[];
  roomId: string;
}

export type RoomImagesQueryFunction = (
  params: RoomImagesQueryParams,
) => Promise<RoomImagesQueryResponse>;

interface Params extends RoomImagesQueryParams {
  queryKey: QueryKey;
  queryFn: RoomImagesQueryFunction;
}

export const useRoomImages = ({
  queryKey,
  queryFn,
  roomId,
  duration,
  startTime,
  enabled,
}: Params) =>
  useQuery({
    queryKey,
    queryFn: async () => queryFn({ roomId, duration, startTime }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    gcTime: 5 * 1000,
    enabled,
  });
