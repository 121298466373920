export enum UserEventType {
  ADTAdmit = 'ADTAdmit',
  ADTDischarge = 'ADTDischarge',
  AlertLiveViewClosed = 'AlertLiveViewClosed',
  AlertLiveViewOpened = 'AlertLiveViewOpened',
  AudibleMessageFailed = 'AudibleMessageFailed',
  AudibleMessageRequested = 'AudibleMessageRequested',
  AudibleMessageSucceeded = 'AudibleMessageSucceeded',
  AugiDisabled = 'AugiDisabled',
  AugiEnabled = 'AugiEnabled',
  ExpandedLiveViewClosed = 'ExpandedLiveViewClosed',
  ExpandedLiveViewOpened = 'ExpandedLiveViewOpened',
  FallRiskAPI = 'FallRiskAPI',
  FallRiskChanged = 'FallRiskChanged',
  FallRiskReset = 'FallRiskReset',
  LiveViewClosed = 'LiveViewClosed',
  LiveViewOpened = 'LiveViewOpened',
  PrivacyModeOff = 'PrivacyModeOff',
  PrivacyModeOn = 'PrivacyModeOn',
  UserLogin = 'UserLogin',
}
