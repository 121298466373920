import { Api } from '../../../utility/client';

import type { Room } from '../../../../types';

interface RoomWithAssignment extends Room {
  assigned?: boolean;
}

export const getEligibleRoomsForUser = async (
  userId: string,
): Promise<RoomWithAssignment[]> => {
  try {
    const res = await Api.get<Room[]>(`telesitting/v1/rooms/manage/${userId}`);
    return res && res?.data;
  } catch (error) {
    console.error("error requesting user's potential rooms");
    throw error;
  }
};
