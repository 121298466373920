import { Box, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Props = {
  backPath: string;
  prefix: string;
  value?: string | number;
  actions?: JSX.Element;
  'data-testid'?: string;
};

export const ShowPageHeader = ({
  backPath,
  prefix,
  value,
  actions,
  'data-testid': dataTestId,
}: Props) => (
  <Stack
    spacing={1}
    direction='row'
    alignItems='center'
    sx={{ paddingBottom: 2, width: '100%' }}
    data-testid={dataTestId}
  >
    <IconButton href={backPath}>
      <ArrowBackIcon />
    </IconButton>

    <Typography variant='h1' sx={{ flex: 1 }}>
      <Typography variant='h1' sx={{ fontWeight: 300 }} component='span'>
        {prefix}:
      </Typography>{' '}
      {value}
    </Typography>

    {actions && <Box>{actions}</Box>}
  </Stack>
);
