import { useCallback } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  alpha,
  buttonClasses,
} from '@mui/material';
import {
  ReportGmailerrorred as ErrorIcon,
  WarningAmber as WarningIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import toast from 'react-hot-toast';

import { sendAmpEvent } from '../../../../../utility/amplitude';
import { sendEarlyWarningAlert } from '../../../data-access/sendEarlyWarningAlert';
import { sendUrgentAlert } from '../../../data-access/sendUrgentAlert';

interface Props {
  roomId: string;
  size: 'small' | 'large';
  onMenuClose?: () => void;
}

const SendAlertMenu = ({ roomId, size, onMenuClose }: Props) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'alert-menu',
  });

  const queryClient = useQueryClient();

  const urgentMutation = useMutation({
    mutationFn: sendUrgentAlert,
    onSuccess: () => {
      toast.success('Urgent alert sent');
      sendAmpEvent('Send Urgent Alert', { roomId });

      queryClient.invalidateQueries({
        queryKey: ['customUnits'],
      });
    },
    onError: () => {
      toast.error('Failed to send urgent alert');
    },
  });

  const earlyWarningMutation = useMutation({
    mutationFn: sendEarlyWarningAlert,
    onSuccess: () => {
      toast.success('Early Warning alert sent');
      sendAmpEvent('Send Early Warning Alert', { roomId });

      queryClient.invalidateQueries({
        queryKey: ['customUnits'],
      });
    },
    onError: () => {
      toast.error('Failed to send Early Warning alert');
    },
  });

  const handleSendAlert = useCallback(
    (type: 'Urgent' | 'Early Warning') => {
      popupState.close();

      if (type === 'Urgent') {
        urgentMutation.mutate(roomId);
      } else {
        earlyWarningMutation.mutate(roomId);
      }
    },
    [roomId, popupState, urgentMutation, earlyWarningMutation],
  );

  return (
    <>
      <LoadingButton
        {...bindTrigger(popupState)}
        size={size === 'large' ? 'medium' : 'small'}
        variant='contained'
        color='secondary'
        loading={urgentMutation.isPending || earlyWarningMutation.isPending}
        sx={{
          height: 'fit-content',
          mt: 'auto',
          [`&.${buttonClasses.disabled}`]: {
            backgroundColor: (theme) =>
              alpha(theme.palette.secondary.light, 0.8),
          },
        }}
      >
        Send Alert
      </LoadingButton>
      <Menu
        {...bindMenu(popupState)}
        onClose={() => {
          bindMenu(popupState).onClose();
          onMenuClose?.();
        }}
      >
        <MenuItem onClick={() => handleSendAlert('Urgent')}>
          <ListItemIcon>
            <ErrorIcon color='urgentAlert' />
          </ListItemIcon>
          <ListItemText>Urgent alert</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleSendAlert('Early Warning')}>
          <ListItemIcon>
            <WarningIcon color='earlyWarningAlert' />
          </ListItemIcon>
          <ListItemText>Early Warning alert</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SendAlertMenu;
