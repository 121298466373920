import { useState } from 'react';
import { Button, Stack } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import {
  isBefore,
  isSameDay,
  isToday,
  roundToNearestMinutes,
  sub,
  subDays,
  subMilliseconds,
} from 'date-fns';
import { format as formatTZ } from 'date-fns-tz';
import { isNil } from 'lodash';
import ReactDatePicker from 'react-datepicker';

import DatePickerDayCell from './DatePickerDayCell';
import DatePickerInput from './DatePickerInput';
import { useUpdateSelection } from './hooks/useUpdateSelection';
import { useEventReviewStore } from './store/EventReviewStore';

import { Can } from '../Can/Can';

import type { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// Style overrides are in styles/global.ts

const DatePicker = () => {
  const { selectedUnit, startDate, endDate } = useEventReviewStore();

  const { updateStartDate, updateEndDate, updateStartAndEndDate } =
    useUpdateSelection();

  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);

  const now = new Date();

  // These are being used only for their time values, the date doesn't matter
  const midnight = new Date(new Date().setHours(0, 0, 0, 0));
  const justBeforeMidnight = subMilliseconds(midnight, 1);

  const timeWindowInDays =
    selectedUnit && selectedUnit.imageExp && selectedUnit.eventExp
      ? Math.max(selectedUnit.imageExp, selectedUnit.eventExp)
      : null;

  const windowStart = !isNil(timeWindowInDays)
    ? subDays(now, timeWindowInDays)
    : null;

  // If first day in window, min start time is current time, otherwise it's midnight
  const minStartTime =
    startDate && windowStart && isSameDay(startDate, windowStart)
      ? now
      : midnight;

  // If today, max start time is current time, otherwise it's just before midnight
  const maxStartTime =
    startDate && isToday(startDate) ? now : justBeforeMidnight;

  // If start is selected, and current end date and start date
  // are the same, min end time will be start time
  const minEndTime =
    startDate && endDate && isSameDay(startDate, endDate)
      ? startDate
      : midnight;

  // If today, max end time is current time, otherwise it's just before midnight
  const maxEndTime = endDate && isToday(endDate) ? now : justBeforeMidnight;

  const timezone = formatTZ(new Date(), 'zzz');

  const reselectWithinWindow = (date: Date) => {
    if (windowStart && isBefore(date, windowStart)) {
      const newStart = roundToNearestMinutes(windowStart, {
        nearestTo: 15,
        roundingMethod: 'ceil',
      });

      updateStartDate(newStart);
    }
  };

  const selectPrevDays = (days: number) => {
    const start = sub(now, { days, minutes: -1 });
    updateStartAndEndDate(start, now);
  };

  const commonProps: Partial<ReactDatePickerProps> = {
    showTimeSelect: true,
    dateFormat: 'MM/dd/yyyy h:mm aa',
    maxDate: now,
    timeIntervals: 15,
    showPopperArrow: false,
    disabledKeyboardNavigation: true,
    wrapperClassName: 'datepicker-wrapper',
    calendarClassName: 'datepicker-calendar',
    popperClassName: 'datepicker-popper',
  };

  return (
    <Stack
      direction='row'
      flexWrap='wrap'
      alignItems='center'
      justifyContent='center'
      gap={2}
    >
      <ReactDatePicker
        open={isStartOpen}
        selected={startDate}
        onChange={(newDate) => updateStartDate(newDate)}
        onSelect={reselectWithinWindow}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={windowStart}
        minTime={minStartTime}
        maxTime={maxStartTime}
        onClickOutside={() => setIsStartOpen(false)}
        renderDayContents={(dayOfMonth, date) => (
          <DatePickerDayCell
            cellDayOfMonth={dayOfMonth}
            cellDate={date}
            timeWindowInDays={timeWindowInDays}
          />
        )}
        customInput={
          <DatePickerInput
            label={`Start time (${timezone})`}
            filled={Boolean(startDate)}
            open={isStartOpen}
            onClear={() => updateStartDate(null)}
            onOpenCalendar={() => setIsStartOpen(true)}
            onCloseCalendar={() => setIsStartOpen(false)}
            onFocusCapture={() => setIsStartOpen(true)}
          />
        }
        {...commonProps}
      />
      <NavigateNext
        color='disabled'
        sx={{ mx: -2, display: { xs: 'none', sm: 'block' } }}
      />
      <ReactDatePicker
        open={isEndOpen}
        selected={endDate}
        onChange={(newDate) => updateEndDate(newDate)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        minTime={minEndTime}
        maxTime={maxEndTime}
        onClickOutside={() => setIsEndOpen(false)}
        customInput={
          <DatePickerInput
            label={`End time (${timezone})`}
            filled={Boolean(endDate)}
            open={isEndOpen}
            onClear={() => updateEndDate(null)}
            onOpenCalendar={() => setIsEndOpen(true)}
            onCloseCalendar={() => setIsEndOpen(false)}
            onFocusCapture={() => setIsEndOpen(true)}
          />
        }
        {...commonProps}
      />
      {(isNil(timeWindowInDays) || timeWindowInDays >= 1) && (
        <Can
          permission={{
            action: 'view',
            subject: 'virtual-care.event-review.time-shortcuts',
          }}
        >
          <>
            <Button variant='contained' onClick={() => selectPrevDays(1)}>
              Last 24 Hours
            </Button>
            {(isNil(timeWindowInDays) || timeWindowInDays >= 2) && (
              <Button variant='contained' onClick={() => selectPrevDays(2)}>
                Last 48 Hours
              </Button>
            )}
          </>
        </Can>
      )}
    </Stack>
  );
};

export default DatePicker;
