import { auth } from 'aws-iot-device-sdk-v2/dist/browser';

import { getMqttCreds } from '..';

const REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
const AWS_REGION = 'us-east-1';

class CredentialsProvider extends auth.CredentialsProvider {
  private credentials = {
    aws_region: AWS_REGION,
    aws_access_id: '',
    aws_secret_key: '',
    aws_sts_token: '',
  };

  private endpoint: string;

  private refreshIntervalId: NodeJS.Timeout | null = null;

  async fetchCredentials() {
    const data = await getMqttCreds();

    if (data?.Credentials) {
      this.credentials.aws_access_id = data.Credentials.AccessKeyId;
      this.credentials.aws_secret_key = data.Credentials.SecretAccessKey;
      this.credentials.aws_sts_token = data.Credentials.SessionToken;
    }

    this.endpoint = data.endpoint;
  }

  override getCredentials() {
    return this.credentials;
  }

  getEndpoint() {
    return this.endpoint;
  }

  async startPeriodicCredentialRefresh() {
    if (this.refreshIntervalId) return;

    this.refreshIntervalId = setInterval(async () => {
      await this.fetchCredentials();
    }, REFRESH_INTERVAL);
  }

  stopPeriodicCredentialRefresh() {
    if (this.refreshIntervalId) clearInterval(this.refreshIntervalId);
    this.refreshIntervalId = null;

    this.credentials.aws_access_id = '';
    this.credentials.aws_secret_key = '';
    this.credentials.aws_sts_token = '';
  }
}

export const credentialsProvider = new CredentialsProvider();
