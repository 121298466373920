import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import type { TelesittingTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

interface Props {
  units?: TelesittingTypes.UnitDto[];
  unitId: string;
}

export const BuildingUnitDisplayHeader = ({ units, unitId }: Props) => {
  const displayUnit = useMemo(
    () =>
      units?.find(
        (unit: TelesittingTypes.UnitDto) => `Room-${unit.domainId}` === unitId,
      ),
    [units, unitId],
  );

  const displayName = useMemo(
    () =>
      [displayUnit?.buildingDisplayName, displayUnit?.displayName]
        .filter(Boolean)
        .join(' - '),
    [displayUnit],
  );

  if (!displayName) {
    return null;
  }

  return (
    <Box
      sx={{ p: 1, marginTop: 2 }}
      data-test-unitid={unitId}
      data-test-component='BuildingUnitDisplayHeader'
    >
      <Typography variant='h6' color='primary'>
        {displayName}
      </Typography>
    </Box>
  );
};
