import type { Permissions } from '@inspiren-monorepo/feature-permissions';
import type { AdminTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

export const transformPermissionsToFields = (
  rolesData: AdminTypes.Role[],
  templateMap: Map<AdminTypes.RoleTemplate['id'], AdminTypes.RoleTemplate>,
) => {
  const updatedRoles = rolesData?.map((role: AdminTypes.Role) => {
    const updatedRole = {
      ...role,
      ...role.permissions.reduce((acc, permission: Permissions) => {
        const permissionField = `${permission.subject}.${permission.action}`;
        return {
          ...acc,
          [permissionField]: true,
        };
      }, {}),
    };

    return updatedRole;
  });

  const roles = updatedRoles?.map((role: AdminTypes.Role) => {
    const updatedRole = {
      ...role,
      templateName: templateMap.get(role.templateId)?.displayName,
      maxAssign: role.maxAssign?.toString() || '',
    };

    return updatedRole;
  });

  return roles;
};
