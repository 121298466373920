import { Api } from '../../../utility/client';

interface Scenario {
  id: string;
  name: string;
}

export async function getVirtualScenarios() {
  return Api.get<Scenario[]>('/v1/vbase/scenarios').then((res) => res.data);
}
