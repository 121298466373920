import { Button, Link, Stack } from '@mui/material';
import { Upload } from '@mui/icons-material';

interface Props {
  itemName: 'basestations' | 'rooms' | 'users';
  urlSuffix?: string;
  disabled?: boolean;
}

const BulkImportLink = ({ itemName, urlSuffix, disabled }: Props) => {
  const href = urlSuffix
    ? `/admin/${itemName}/import/${urlSuffix}`
    : `/admin/${itemName}/import`;

  return (
    <Stack direction='row' alignItems='center'>
      <Button
        disabled={disabled}
        component={Link}
        variant='contained'
        size='small'
        href={href}
        startIcon={<Upload />}
      >
        Bulk Import
      </Button>
    </Stack>
  );
};

export default BulkImportLink;
