import React from 'react';
import type { OutlinedTextFieldProps } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ArrowDropDown, ArrowDropUp, Clear } from '@mui/icons-material';

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  label: string;
  filled: boolean;
  open: boolean;
  onClear: () => void;
  onCloseCalendar: () => void;
  onOpenCalendar: () => void;
}

const DatePickerInput = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      filled,
      open,
      disabled,
      onClear,
      onCloseCalendar,
      onOpenCalendar,
      ...props
    },
    ref,
  ) => (
    <TextField
      ref={ref}
      variant='outlined'
      size='small'
      sx={{
        width: 240,
        '& .MuiOutlinedInput-root': {
          p: 0,
        },
        '&:hover': {
          '& .clearIcon': {
            visibility: !open && filled ? 'visible' : 'hidden',
          },
        },
      }}
      label={label}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end' sx={{ mr: 1 }}>
            <IconButton
              onClick={onClear}
              size='small'
              sx={{
                fontSize: '20px',
                mr: -0.5,
                visibility: open && filled ? 'visible' : 'hidden',
              }}
              className='clearIcon'
              disabled={disabled}
            >
              <Clear fontSize='inherit' />
            </IconButton>
            <IconButton
              size='small'
              disabled={disabled}
              onClick={() => {
                if (open) onCloseCalendar();
                if (!open) onOpenCalendar();
              }}
            >
              {open ? (
                <ArrowDropUp sx={{ m: -0.25 }} />
              ) : (
                <ArrowDropDown sx={{ m: -0.25 }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  ),
);

export default DatePickerInput;
