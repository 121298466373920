import { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ImageNotSupported } from '@mui/icons-material';

import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { prefetchImage } from '../../../../helpers';

type Props = {
  src: string | null;
  onClick?: () => void;
  selectedRoom: EventReviewTypes.Room | null;
  startDate: Date | null;
  endDate: Date | null;
  imagesLoading: boolean;
  lastImagesLoading: boolean;
  message: string | null;
};

const Image = ({
  src,
  onClick,
  selectedRoom,
  imagesLoading,
  lastImagesLoading,
  message,
  startDate,
  endDate,
}: Props) => {
  const [loadError, setLoadError] = useState(false);

  const [lastSuccessfulImg, setLastSuccessfulImg] = useState<string | null>(
    null,
  );

  // if we change the selection, we want to reset the image
  useEffect(() => {
    setLastSuccessfulImg(null);
  }, [selectedRoom, startDate, endDate]);

  useEffect(() => {
    if (src) prefetchImage(src, () => setLastSuccessfulImg(src));
  }, [src]);

  useEffect(() => {
    setLoadError(false);
  }, [src]);

  if (!src || loadError || message)
    return (
      <Box
        sx={{
          bgcolor: 'grey.200',
          color: 'grey.500',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        {!src && (imagesLoading || lastImagesLoading || loadError) && (
          <CircularProgress size={60} color='inherit' />
        )}

        {message && (
          <>
            <ImageNotSupported fontSize='large' />
            {message && (
              <Typography variant='body2' mt={1} sx={{ userSelect: 'none' }}>
                {message}
              </Typography>
            )}
          </>
        )}
      </Box>
    );
  if (!lastSuccessfulImg)
    return (
      <Box
        sx={{
          bgcolor: 'grey.200',
          color: 'grey.500',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
        }}
      >
        <CircularProgress size={60} color='inherit' />
      </Box>
    );
  return (
    <img
      src={lastSuccessfulImg || src}
      alt=''
      width='100%'
      height='100%'
      onError={() => setLoadError(true)}
      onClick={onClick}
      style={{ cursor: 'pointer', backgroundColor: '#fff' }}
    />
  );
};

export default Image;
