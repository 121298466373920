import { useMemo } from 'react';
import {
  Box,
  InputLabel,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { isEmpty } from 'lodash';

type Props = {
  label: string;
  value: string | JSX.Element | number | null | undefined;
  loading?: boolean;
  tooltip?: string;
  'data-testid'?: string;
};

export const ShowPageField = ({
  label,
  value,
  loading,
  tooltip,
  'data-testid': dataTestId,
}: Props) => {
  const content = useMemo(() => {
    if (loading) {
      return <Skeleton width={200} />;
    }

    if (isEmpty(value)) {
      return (
        <Typography
          sx={{ color: (theme) => theme.palette.grey[500], fontWeight: 400 }}
        >
          No value
        </Typography>
      );
    }

    return value;
  }, [loading, value]);

  return (
    <Stack gap={2} direction='row' alignItems='center' data-testid={dataTestId}>
      <InputLabel
        sx={{
          width: '250px',
          alignItems: 'center',
          display: 'flex',
          gap: 0.5,
        }}
      >
        {label}{' '}
        {tooltip ? (
          <Tooltip title={tooltip}>
            <InfoIcon fontSize='inherit' />
          </Tooltip>
        ) : (
          ''
        )}
      </InputLabel>
      <Box
        sx={(theme) => ({
          borderRadius: `${theme.shape.borderRadius}px`,
          backgroundColor: theme.palette.grey[100],
          display: 'flex',
          flex: 1,
          width: '100%',
          paddingX: 2,
          paddingY: 1,
        })}
      >
        {content}
      </Box>
    </Stack>
  );
};
