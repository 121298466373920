import React, { useCallback } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';

import { RoomFallRiskHistoryModal } from './RoomFallRiskHistoryModal';

interface Props {
  roomId: string;
}

export const RoomFallRiskHistoryModalButton = ({ roomId }: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Tooltip title='Fall Risk Change History' placement='top'>
        <IconButton onClick={handleOpen}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <RoomFallRiskHistoryModal
          open={open}
          onClose={handleClose}
          roomId={roomId}
        />
      )}
    </>
  );
};
