import {
  blueGrey,
  green,
  grey,
  purple,
  red,
  yellow,
} from '@mui/material/colors';

export const palette = {
  primary: '#3b6ec9',
  secondary: grey[800],
  highFallRisk: '#f79630',
  mediumFallRisk: '#f79630',
  nightLowFallRisk: '#4E4E88',
  lowFallRisk: '#e5d96d',
  offFallRisk: grey[800],
  outOfBedAlert: '#ffa51f',
  outOfChairAlert: '#a561ff',
  fallAlert: '#f6461f',
  hiddenAlert: grey[600],
  movingInBedAlert: '#ffe600',
  urgentAlert: red[600],
  earlyWarningAlert: yellow[700],
  bathroomOccupiedAlert: '#007AC1',
  disableAugi: '#d43257',
  staffEvent: green[500],
  notificationEvent: purple[500],
  hybridEvent: blueGrey[500],
  bathroomEvent: '#8ed3fc',
  userEvent: '#3352F5',
  validationError: '#ff4343',
} as const satisfies Record<string, string>;

export type CustomPalette = typeof palette;
