import type React from 'react';
import type { StyleProp, ViewStyle } from 'react-native';
import { TouchableOpacity } from 'react-native';
import AntDesign from '@expo/vector-icons/AntDesign';
import Octicons from '@expo/vector-icons/Octicons';
import { mapValues } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IconData = { Component: React.ComponentType<any>; name: string };
type IconMap = Record<string, IconData>;

const iconMap = {
  History: { Component: Octicons, name: 'history' },
  Pause: { Component: AntDesign, name: 'pausecircle' },
  Play: { Component: AntDesign, name: 'playcircleo' },
} as const satisfies IconMap;

export interface IconProps {
  size?: number;
  color?: string;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  testID?: string;
}

export const Icon = mapValues(
  iconMap,
  ({ Component, name }) =>
    ({ size = 22, color = 'black', style, onPress, testID }: IconProps) => {
      const IconComponent = (
        <Component
          name={name as never}
          size={size}
          color={color}
          style={style}
        />
      );

      return onPress ? (
        <TouchableOpacity onPress={onPress} hitSlop={12} testID={testID}>
          {IconComponent}
        </TouchableOpacity>
      ) : (
        IconComponent
      );
    },
);
