import { Api } from '../../../../../utility/client';

interface Response {
  mobilePhone: string;
  mobilePhoneVerified: boolean;
}

export const updatePhoneNumber = async (phoneNumber: string | null) => {
  const res = await Api.put<Response>('/v1/user/mobilePhone', {
    mobilePhone: phoneNumber,
  });

  return res.data;
};
