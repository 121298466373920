import { Button, DialogActions, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useNotVerifiedStage } from '@inspiren-monorepo/shared-react/universal';

import ControlledVerificationCodeInput from '../ControlledVerificationCodeInput';
import PhoneNumberError from '../PhoneNumberError';
import PhoneNumberInput from '../PhoneNumberInput';
import ResendVerificationCode from '../ResendVerificationCode';
import { confirmPhoneNumber } from '../data-access/confirmPhoneNumber';

interface Props {
  confirmedPhoneNumber?: string;
  goBack: () => void;
  onConfirmed: () => void;
}

const NotVerifiedStage = ({
  confirmedPhoneNumber,
  goBack,
  onConfirmed,
}: Props) => {
  const {
    onConfirmationCodeChanged,
    control,
    isPending,
    isError,
    confirmApiError,
    onConfirmPress,
  } = useNotVerifiedStage({
    onConfirmed,
    confirmPhoneNumberMutationFn: confirmPhoneNumber,
  });

  return (
    <>
      <DialogContent>
        <PhoneNumberInput value={confirmedPhoneNumber} disabled />
        <ControlledVerificationCodeInput
          control={control}
          onComplete={onConfirmationCodeChanged}
        />
        {isError && <PhoneNumberError error={confirmApiError} />}
        <ResendVerificationCode />
      </DialogContent>
      <DialogActions>
        <Button onClick={goBack} disabled={isPending}>
          Back
        </Button>
        <LoadingButton
          onClick={onConfirmPress}
          disabled={isPending}
          loading={isPending}
          variant='contained'
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default NotVerifiedStage;
