export const USERNAME_ORGS = [
  'AEGIS',
  'ANTHEM',
  'AVANTI',
  'BRIGHTON',
  'CAREONE',
  'CHARTER',
  'COUNTRYMEADOWS',
  'HERITAGE',
  'MANORLAKE',
  'MAPLEWOOD',
  'MERRILL',
  'MIDWEST',
  'MOMENTUM',
  'PEGASUS',
  'PROVISION',
  'SENIORLIFESTYLE',
  'SODALIS',
  'TANNER',
  'THRIVE',
  'WELLPOINTE',
];

export const isUsernameOrg = (org?: string) => {
  if (!org) return false;
  return USERNAME_ORGS.includes(org);
};
