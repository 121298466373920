import { Stack, Typography } from '@mui/material';

interface Props {
  baseId?: string;
  roomName?: string;
  awsAccountId?: string;
  app: 'virtual-care' | 'hilq';
}

export const RoomInfo = ({ baseId, roomName, awsAccountId, app }: Props) => (
  <Stack>
    {app === 'hilq' && awsAccountId && (
      <Typography sx={{ fontSize: 18, color: '#fff' }}>
        {' '}
        {awsAccountId}
      </Typography>
    )}
    <Typography sx={{ fontSize: app === 'hilq' ? 18 : 28, color: '#fff' }}>
      {app === 'hilq' ? baseId : roomName}
    </Typography>
  </Stack>
);
