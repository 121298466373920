import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { Controller } from 'react-hook-form';

import type { CompoundSubjectAndAction } from '@inspiren-monorepo/feature-permissions';

import { allPermissions } from '../constants/permissions';

import type { Control } from 'react-hook-form';

type Props = {
  id: CompoundSubjectAndAction;
  label: string;
  control: Control;
  tooltip?: string;
  disabled?: boolean;
  setValue?: any;
};

const RoleControlledCheckbox = ({
  id,
  label,
  control,
  tooltip,
  disabled = false,
  setValue,
}: Props) => (
  <Controller
    name={id}
    control={control}
    render={({ field: { name, value } }) => (
      <Tooltip
        title={tooltip}
        placement='left'
        disableHoverListener={!tooltip}
        disableInteractive={!tooltip}
        disableFocusListener={!tooltip}
      >
        <FormControlLabel
          control={<Switch />}
          defaultChecked={false}
          id={name}
          label={label}
          checked={value || false}
          onChange={(e: any) => {
            if (setValue && !e.target.checked) {
              const permissions = allPermissions.filter((permission) => {
                const subjectWithAction = name.split('.');
                subjectWithAction.pop();
                const subject = subjectWithAction.join('.');
                return permission.id.includes(subject);
              });

              permissions.forEach((permission) => {
                setValue(permission.id, e.target.checked);
              });
            }

            if (setValue && e.target.checked) {
              setValue(name, e.target.checked);
            }
          }}
          sx={{ display: 'block', my: 1 }}
          disabled={disabled}
        />
      </Tooltip>
    )}
  />
);

export default RoleControlledCheckbox;
