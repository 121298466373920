import { Alert, AlertTitle } from '@mui/material';

const TableError = ({ error }: { error?: string }) => (
  <Alert severity='error'>
    <AlertTitle>Error</AlertTitle>
    An error occured while loading the table{error ? `: ${error}` : '.'}
  </Alert>
);

export default TableError;
