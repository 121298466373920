import { UserEventType } from '@inspiren-monorepo/shared-types';

export type RoomActivityType =
  | UserEventType.PrivacyModeOn
  | UserEventType.AugiDisabled
  | UserEventType.AugiEnabled
  | UserEventType.LiveViewOpened
  | UserEventType.ExpandedLiveViewOpened
  | UserEventType.AlertLiveViewOpened
  | 'Staff Event';

export const formatRoomActivityTypeText = (type: RoomActivityType): string => {
  switch (type) {
    case UserEventType.PrivacyModeOn:
      return 'Virtual Curtain Enabled';
    case UserEventType.AugiDisabled:
      return 'Augi Disabled';
    case UserEventType.AugiEnabled:
      return 'Augi Enabled';
    case UserEventType.LiveViewOpened:
      return 'Live View';
    case UserEventType.ExpandedLiveViewOpened:
      return 'Expanded Live View';
    case UserEventType.AlertLiveViewOpened:
      return 'Alert Live View';
    case 'Staff Event':
      return 'Staff Visit';
    default:
      return 'Unknown Event';
  }
};
