import { useWatch } from 'react-hook-form';

import ControlledTextField from '../ControlledTextField';

import type { Control } from 'react-hook-form';

type Props = {
  field: string;
  label: string;
  control: Control;
};

const DisplayName = ({ field, label, control }: Props) => {
  const id = useWatch({
    name: 'id',
    control,
  });

  return (
    <ControlledTextField
      id={field}
      label={label}
      control={control}
      placeholder={id}
    />
  );
};

export default DisplayName;
