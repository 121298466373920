import { useQuery } from '@tanstack/react-query';

import { getVirtualBsActions } from '../data-access/getVirtualBsActions';

export const useVirtualBsActions = (
  baseId: string,
  isDemoSession?: boolean,
) => {
  const { data: actions, isLoading } = useQuery({
    queryKey: ['virtualBsActions', baseId],
    queryFn: () => getVirtualBsActions(baseId),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: isDemoSession,
  });

  return { actions: actions ?? [], isLoading };
};
