interface Props {
  assigned: string;
  unit: string;
  building: string;
}

export const RenderEscalationValues = ({ assigned, unit, building }: Props) => (
  <>
    <div>{assigned}-</div>
    <div>{unit}-</div>
    <div>{building}</div>
  </>
);
