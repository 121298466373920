import { useQuery } from '@tanstack/react-query';

import { getTrainingVideos } from '../data-access/getTrainingVideos';

interface Params {
  enabled?: boolean;
}

export const useTrainingVideos = ({ enabled }: Params = {}) =>
  useQuery({
    queryKey: ['trainingVideos'],
    queryFn: getTrainingVideos,
    enabled,
  });
