import { useMemo } from 'react';
import { compact, isEmpty } from 'lodash';
import { useWatch } from 'react-hook-form';

import type { Beacon } from '@inspiren-monorepo/virtual-care/api-contracts';

import { useAdminUsers } from '../../../hooks/useAdminUsers';
import { ControlledAutocomplete } from '../../../modals/ControlledAutocomplete';

import type { Control } from 'react-hook-form';

interface Props {
  id: string;
  label: string;
  control: Control;
  beacons?: Beacon[];
}

export const SelectUser = ({ id, label, control, beacons }: Props) => {
  const orgValue = useWatch({ name: 'org', control });
  const { isFetching, usersById, userIds } = useAdminUsers(orgValue);

  const userIdsWithBeacons = useMemo(
    () => compact((beacons || []).map((beacon) => beacon.assignedTo)),
    [beacons],
  );

  return (
    <ControlledAutocomplete
      id={id}
      label={label}
      items={userIds}
      control={control}
      getOptionLabel={(userId) => {
        if (isEmpty(userId)) return '';
        const user = usersById[userId];
        return user
          ? `${
              userIdsWithBeacons.includes(user.mainId) ? '\uD83D\uDD17 ' : ''
            } ${user.firstName} ${user.lastName} (${user.email})`
          : '';
      }}
      loading={isFetching}
    />
  );
};
