import { useQuery } from '@tanstack/react-query';

import { getOrgRolesMap } from '../data-access/getOrgRolesMap';

export const useOrganizationRolesMap = (orgId?: string) =>
  useQuery({
    queryKey: ['org', orgId, 'roles-map'],
    queryFn: () => getOrgRolesMap(orgId as string),
    enabled: orgId !== undefined,
  });
