import { AbilityBuilder, createMongoAbility } from '@casl/ability';

import type { AbilityTuple } from './types/AbilityTuple';
import type { Permissions } from './types/Permissions';
import type { MongoQuery, PureAbility } from '@casl/ability';

export type Ability = PureAbility<AbilityTuple, MongoQuery>;

export const buildRole = (permissions: Permissions[]): Ability => {
  const { can, build } = new AbilityBuilder<Ability>(createMongoAbility);

  permissions.forEach((permission) => {
    can(permission.action, permission.subject);
  });

  return build();
};
