import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { capitalize } from 'lodash';

import { volumeOptions } from './RoomAudibleMessageModalForm';

import type { AudibleMessage } from '../../../../../types';

interface Props {
  roomName: string;
  audibleMessage: AudibleMessage;
  volume: number;
  lang: string;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
}

export const RoomAudibleMessageModalConfirm = ({
  roomName,
  audibleMessage,
  volume,
  lang,
  onCancel,
  onSubmit,
}: Props) => {
  const theme = useTheme();
  const [sending, setSending] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    setSending(true);
    return onSubmit().then(() => setSending(false));
  }, [onSubmit]);

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      sx={{ flex: 1, px: 2 }}
    >
      <Typography sx={{ mb: 2, pr: 3 }}>
        Send audible message to <b>Room {roomName}</b>
      </Typography>
      <Box component='dl' sx={{ m: 0 }}>
        <Typography
          variant='subtitle2'
          component='dt'
          sx={{ color: theme.palette.grey[500] }}
        >
          Message
        </Typography>
        <Typography
          sx={{
            mb: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          component='dd'
        >
          {audibleMessage?.title}
        </Typography>
        <Typography
          variant='subtitle2'
          component='dt'
          sx={{ color: theme.palette.grey[500] }}
        >
          Language
        </Typography>
        <Typography sx={{ mb: 1 }} component='dd'>
          {capitalize(lang)}
        </Typography>
        <Typography
          variant='subtitle2'
          component='dt'
          sx={{ color: theme.palette.grey[500] }}
        >
          Volume
        </Typography>
        <Typography component='dd'>
          {volumeOptions.find((option) => volume === option.value)?.label}
        </Typography>
      </Box>
      <Stack direction='row' justifyContent='space-between' sx={{ mt: 2 }}>
        <Button variant='text' onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={handleSubmit}
          disabled={sending}
          startIcon={sending && <CircularProgress size={16} />}
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};
