import { useMemo } from 'react';
import type { GridRowProps } from '@mui/x-data-grid';
import { GridRow } from '@mui/x-data-grid';

import ErrorTooltip from './ErrorTooltip';

import type { Importable } from '../types/importable';

type Props<TFieldTypes> = GridRowProps & {
  row: Importable<TFieldTypes>;
};

const ImportTableRow = <TFieldTypes,>(props: Props<TFieldTypes>) => {
  const {
    row: {
      $meta: { validationError },
      ...fields
    },
    editRowsState,
    rowId,
  } = props;

  const isDirty = useMemo(() => {
    if (!editRowsState[rowId]) {
      return false;
    }

    return Object.entries(editRowsState[rowId]).some(
      ([field, { value }]) => fields[field] !== value,
    );
  }, [fields, editRowsState[rowId]]);

  return (
    <ErrorTooltip
      title={(!isDirty && validationError) || ''}
      placement='left'
      arrow
      sx={{
        maxWidth: '250px',
      }}
    >
      <div className={!isDirty && validationError ? 'import-row-error' : ''}>
        <GridRow {...props} />
      </div>
    </ErrorTooltip>
  );
};

export default ImportTableRow;
