import type { OfflineEvent } from '@inspiren-monorepo/virtual-care/api-contracts';

import { Api } from '../../../utility/client';

export const getOfflineEvents = async (
  roomId: string,
  start: string,
  end: string,
) => {
  const url = `/eventReview/v1/offline/${roomId}/${start}/${end}`;
  const res = await Api.get<OfflineEvent[]>(url);
  return res && res.data;
};
