import { useCallback, useMemo, useState } from 'react';

import {
  PhoneNumberFormat,
  formatPhoneNumberSafe,
} from '@inspiren-monorepo/util-formatters';

import { comparePhoneNumberSafe } from '../helpers/comparePhoneNumberSafe';
import { getInitialVerificationStage } from '../helpers/getInitialVerificationStage';
import { PhoneNumberVerificationStage } from '../types/PhoneNumberVerificationStage';

interface Params {
  initialPhoneNumber?: string | null;
  initialIsPhoneNumberVerified?: boolean;
}

export const usePhoneNumberVerification = ({
  initialPhoneNumber,
  initialIsPhoneNumberVerified,
}: Params) => {
  const [confirmedPhoneNumber, setConfirmedMobilePhone] = useState<
    string | null | undefined
  >(initialPhoneNumber);

  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(
    initialIsPhoneNumberVerified,
  );

  const [stage, setStage] = useState<PhoneNumberVerificationStage>(
    getInitialVerificationStage(
      initialPhoneNumber,
      initialIsPhoneNumberVerified,
    ),
  );

  const onConfirmedPhoneNumberChange = useCallback(
    (value: string | null) => {
      let isAlreadyVerified = isPhoneNumberVerified;

      if (
        !value ||
        !confirmedPhoneNumber ||
        !comparePhoneNumberSafe(value, confirmedPhoneNumber)
      ) {
        setConfirmedMobilePhone(
          value ? formatPhoneNumberSafe(value, PhoneNumberFormat.E164) : null,
        );

        setIsPhoneNumberVerified(false);
        isAlreadyVerified = false;
      }

      if (value && !isAlreadyVerified) {
        setStage(PhoneNumberVerificationStage.NOT_VERIFIED);
      }
    },
    [confirmedPhoneNumber, isPhoneNumberVerified],
  );

  const formattedConfirmedPhoneNumber = useMemo(
    () =>
      confirmedPhoneNumber
        ? formatPhoneNumberSafe(
            confirmedPhoneNumber,
            PhoneNumberFormat.US_NATIONAL_OTHER_INTERNATIONAL,
          )
        : '',
    [confirmedPhoneNumber],
  );

  return {
    phoneNumber: formattedConfirmedPhoneNumber,
    onConfirmedPhoneNumberChange,
    stage,
    setStage,
    setIsPhoneNumberVerified,
  };
};
