import type { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

interface Props {
  backgroundColor?: string;
  background?: string;
  color?: string;
}

export const RoomViewStatus = ({
  backgroundColor = 'grey.400',
  background,
  color = 'grey.600',
  children,
}: PropsWithChildren<Props>) => (
  <Box
    sx={{
      backgroundColor,
      background,
      color,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 0.5,
      userSelect: 'none',
      height: '100%',
    }}
  >
    {children}
  </Box>
);
