import { useState } from 'react';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { Check, KeyboardArrowDown } from '@mui/icons-material';

import type { ViewMode } from '../../../../types';

export type ViewOptions = { label: string; periscopeMode: ViewMode }[];

interface Props {
  periscopeMode: ViewMode;
  viewOptions: ViewOptions;
  onViewModeChange: (mode: ViewMode) => void;
}

const ViewMenu = ({ periscopeMode, viewOptions, onViewModeChange }: Props) => {
  const [modeAnchorEl, setModeAnchorEl] = useState<null | HTMLElement>(null);
  const modeOpen = Boolean(modeAnchorEl);

  return (
    <Box>
      <Button
        onClick={(e) => setModeAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDown />}
        variant='contained'
        id='mode-button'
        aria-controls={modeOpen ? 'mode-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={modeOpen ? 'true' : undefined}
      >
        {
          viewOptions.find(({ periscopeMode: mode }) => periscopeMode === mode)
            ?.label
        }
      </Button>
      <Menu
        id='mode-menu'
        anchorEl={modeAnchorEl}
        open={modeOpen}
        onClose={() => setModeAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'mode-button',
        }}
      >
        {viewOptions.map(({ label, periscopeMode: mode }) => (
          <MenuItem
            key={mode}
            selected={periscopeMode === mode}
            onClick={() => onViewModeChange(mode)}
          >
            {periscopeMode === mode && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            <ListItemText inset={periscopeMode !== mode}>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ViewMenu;
